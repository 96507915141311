import './styles.scss';
import { FC, useRef, useState } from 'react';
import { isEmpty, isNil } from 'lodash';
import Avatar from '../../../../components/UI/Avatar';
import { useAuth } from '../../../../contexts/auth';
import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css';
import { Button, Box } from '@mui/material';

interface InjectedProps {
  onSubmit: (content: string, id?: string) => void;
  onCancel?: () => void;
  className?: string;
  defaultValue?: string;
  readOnly?: boolean;
  mode?: 'edit' | 'create';
}
const QUILL_MODULES = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
    ['clean'],
  ],
};

const QUILL_FORMATS = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'indent',
  'link',
];

const CommentInput: FC<InjectedProps> = ({
  mode = 'create',
  readOnly,
  defaultValue,
  onSubmit,
  onCancel: onCancelCb,
  className,
}) => {
  const { user } = useAuth();
  const ref = useRef<ReactQuill | null>(null);
  const [isFocused, setFocused] = useState<boolean>(false);
  const content = useRef<string>('');
  const [value, setValue] = useState<any>(defaultValue);

  const onFocusOut = () => {
    ref.current?.blur();
    setFocused(false);
  };
  
  const onFocusIn = () => {
    ref.current?.focus();
    setFocused(true);
  };

  const onValueChanged = (value: any) => {
    setValue(value);
    content.current = value;
  };

  const onCancel = () => {
    ref.current?.blur();
    setValue('');
    setFocused(false);
    content.current = '';
    onCancelCb && onCancelCb();
  };

  const handleSubmit = () => {
    if (isEmpty(content)) return;
    onSubmit(content.current);

    onCancel();
  };

  const isCreateMode = mode === 'create';
  const isEditMode = mode === 'edit';
  const isButtonVisible = !isNil(value) && value !== '<p><br></p>' && value !== defaultValue;
  
  return (
    <div
      className={`comment-input flex items-start flex-row ${className} ${
        isEditMode ? 'pt-0' : ''
      }`}
    >
      {isCreateMode && (
        <Avatar
          className="mr-3"
          size={37}
          title={`${user?.fldFirst} ${user?.fldLast}`}
        />
      )}

      <div className="w-full">
        <ReactQuill
          theme="snow"
          readOnly={readOnly}
          placeholder="Write a comment..."
          value={value || ''}
          modules={QUILL_MODULES}
          formats={QUILL_FORMATS}
          ref={ref}
          onChange={onValueChanged}
          className="ql-editor-rounded"
          /* onBlur and onFocus disables the functionality of copy/paste into the text editor
          onBlur={onFocusOut}
          onFocus={onFocusIn}
          */
        />
        {isButtonVisible && (
          <div>
            <Box className="mt-4">
              <Button
                onClick={handleSubmit}
                variant="contained"
                className="w-32 mr-2"
              >
                Save
              </Button>
              <Button onClick={onCancel} variant="text" className="w-32">
                Cancel
              </Button>
            </Box>
          </div>
        )}
        {/* <HtmlEditor
          readOnly={readOnly}
          defaultValue={defaultValue}
          onValueChange={onValueChanged}
          ref={ref}
          onFocusOut={onFocusOut}
          onFocusIn={onFocusIn}
          placeholder="Write a comment..."
        >
          <Toolbar multiline={false}>
            <Item formatName="bold" />
            <Item formatName="italic" />
            <Item formatName="strike" />
            <Item formatName="underline" />
            <Item formatName="link" />
            <Item formatName="image" />

            <Item widget="dxButton" location="after" options={cancelOptions} />
            <Item widget="dxButton" location="after" options={submitOptions} />
          </Toolbar>
        </HtmlEditor> */}
        {/* <div className={`dx-toolbar-items-container ${isFocused ? `block` : 'hidden' }`} id={toolbarContainerId} /> */}
      </div>
    </div>
  );
};

export default CommentInput;
