import { ApolloError, gql, useMutation } from '@apollo/client';
import { Attachment, RecordType } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_DOCUMENT_RELATED_ITEMS } from './useGetDocumentRelatedItems';

export const ADD_DOCUMENT_RELATED_ITEMS = gql`
  mutation AddDocumentRelatedItems($input: AttachmentInput!) {
    addAttachment(input: $input) {
      attachment {
        documentationId
        id
        recordId
        recordType
      }
    }
  }
`;

interface DocumentRelatedItemsMutationResponse {
  responseData?: Attachment[];
  responseMessage: string;
}

interface AddDocumentRelatedItemsResult {
  addDocumentRelatedItems: (payload: { documentationId: string; recordIds: string[]; recordType: RecordType }) => Promise<DocumentRelatedItemsMutationResponse>;
  addDocumentRelatedItemsLoading: boolean;
}

export const useAddDocumentRelatedItems = (): AddDocumentRelatedItemsResult => {
  const [addDocumentRelatedItem, { loading }] = useMutation(ADD_DOCUMENT_RELATED_ITEMS, {
    onError: (error: ApolloError) => {
      logger('AddDocumentRelatedItems').error('Error adding document related items -->', error.message);
    }
  });

  const addDocumentRelatedItems = async (payload: { documentationId: string; recordIds: string[]; recordType: RecordType }): Promise<DocumentRelatedItemsMutationResponse> => {
    const { documentationId, recordIds, recordType } = payload;

    const responses = await Promise.all(
      recordIds.map(async (recordId) => {
        const response = await addDocumentRelatedItem({
          variables: {
            input: { documentationId, recordId, recordType }
          },
          refetchQueries: [{
            query: GET_DOCUMENT_RELATED_ITEMS,
            variables: { documentationId }
          }],
        });

        const responseData = response.data?.addAttachment?.attachment;
        if (responseData) {
          logger('AddDocumentRelatedItems').info('Document related item added successfully', responseData);
          return {
            success: true,
            data: responseData,
          };
        } else {
          return { success: false };
        }
      })
    );

    const successfulData = responses.filter(response => response.success).map(response => response.data as Attachment);
    const allSuccess = successfulData.length === recordIds.length;

    const responseMessage = allSuccess
      ? `All document related items added successfully!`
      : `Some document related items failed to add!`;

    return {
      responseData: successfulData,
      responseMessage,
    };
  };

  return {
    addDocumentRelatedItems,
    addDocumentRelatedItemsLoading: loading,
  };
};
