import { FC, useState } from "react";
import { isNil } from "lodash";
import { Alert, AlertColor, Button, IconButton, Snackbar, Tooltip } from "@mui/material";
import { DeleteTwoTone } from '@mui/icons-material';
import { Attachment, AttachmentInput, AttachmentType, ProcedureDocumentInput, RecordType } from "src/generated/dotnet.graphql";
import WarningDialog from "src/components/UI/WarningDialog";
import AttachmentPopup from "src/modules/Attachments/component/AttachmentPopup";
import { defaultUploadedFile, IFile } from "src/modules/Attachments";
import { useGetProcedureDocument } from "src/hooks/attachments/useGetProcedureDocument";
import { useUpsertProcedureDocumentResult } from "src/hooks/attachments/useUpsertProcedureDocument";
import { useDeleteProcedureDocument } from "src/hooks/attachments/useDeleteProcedureDocument";
import { DOWNLOAD_URL, SEVERITY } from 'src/consts';

interface Props {
  initialValue: any;
  recordType: RecordType;
}

const ProcedureDocument: FC<Props> = ({
  initialValue,
  recordType,
}) => {
  const { procedureDocumentData, procedureDocumentLoading } = useGetProcedureDocument(initialValue.PKey);
  const { upsertProcedureDocument, upsertProcedureDocumentLoading } = useUpsertProcedureDocumentResult();
  const { removeProcedureDocument } = useDeleteProcedureDocument();

  const [selectedDocument, setSelectedDocument] = useState<Attachment>();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<IFile>(defaultUploadedFile);
  const [isDeleting, setIsDeleting] = useState(false);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();

  const onFormCancel = () => {
      setIsVisible(false);
  };

  const onAddClick = () => {
    setSelectedDocument(undefined);
    setIsVisible(true);
  }

  const onDeleteClick = () => {
    setIsDeleting(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleting(false);
  };

  const handleDeleteOk = async () => {
    const { responseData, responseMessage } = await removeProcedureDocument(initialValue.PKey);

    if (responseData) {
      setIsDeleting(false);
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  }

  const handleUpsert = async (data: AttachmentInput) => {
    const payload: ProcedureDocumentInput = {
      scheduleId: initialValue.PKey,
      documentationId: data?.documentationId,
      docTitle: data?.docTitle,
      altPath: data?.altPath,
      description: data?.description,
      fileName: data?.fileName || uploadedFile.key,
      libType: data?.libType || null,
      notes: data?.notes,
      page: data?.page,
      revision: data?.revision,
      revisionDate: data?.revisionDate,
      showInCentral: data?.showInCentral,
      categoryId: initialValue.fldSRHKey || null,
      recordType,
    }

    const isCreate = isNil(payload.documentationId)
    const { responseData, responseMessage} = await upsertProcedureDocument(payload, isCreate);

    setSelectedDocument(undefined);
    setIsVisible(false);
    setUploadedFile(defaultUploadedFile);

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  }

  // TODO - add edit feature when adding more inputs in Attachment popup
  // const editDocument = () => {
  //   setSelectedDocument(attachmentData);
  //     setIsVisible(true);
  // }

  return (
    <div>
      {procedureDocumentData ? 
        <div>
          <Button 
            target="_blank" 
            href={`${DOWNLOAD_URL}/${procedureDocumentData?.fileName}`} 
            disabled={procedureDocumentLoading}
          >
            View attached procedure
          </Button>
          {/* TODO - add edit feature when adding more inputs in Attachment popup */}
          {/* <Tooltip title="Edit Procedure Document">
            <IconButton
              size="small"
              color="primary"
              onClick={editDocument}
            >
              <Edit fontSize="small" />
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Remove Procedure Document">
            <IconButton
              size="small"
              color="error"
              onClick={onDeleteClick}
            >
              <DeleteTwoTone fontSize="small" />
            </IconButton>
          </Tooltip>                     
        </div>
        : 
        <div>
          <Button onClick={onAddClick}>
            Add a procedure document
          </Button>
        </div>
      }

      <AttachmentPopup
        visible={isVisible}
        document={selectedDocument}
        attachmentType={AttachmentType.Document}
        onCancel={onFormCancel}
        onSubmit={handleUpsert}
        setUploadedFile={setUploadedFile}
        uploadedFile={uploadedFile}
        saveLoading={upsertProcedureDocumentLoading}
        disableCheckbox={true}
      />

      <WarningDialog
        visible={isDeleting}
        title="Delete Procedure Document"
        content="Are you sure you want to delete the procedure document and all its assosciated references?"
        okText="Yes"
        color="error"
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>

    </div>
  )
}

export default ProcedureDocument
