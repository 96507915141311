import './styles.css';
import { FC, useRef, useState, useCallback, MutableRefObject } from 'react';
import { Alert, Snackbar, IconButton } from '@mui/material';
import { Edit,DeleteTwoTone, MoveUpTwoTone } from '@mui/icons-material';
import { TypeComputedProps, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import { isNil, size } from 'lodash';
import { TblSchedChkDocument } from 'src/rxdb/collections/SchedChk/schema';
import { TblSchedChkDescDocument } from 'src/rxdb/collections/SchedChkDesc/schema';
import { getDatabase } from 'src/rxdb';
import WarningDialog from 'src/components/UI/WarningDialog';
import { ICheckListType, IMoveItemType } from './CheckListTab';
import { getPlainTextFromHtml } from 'src/utils';
import { getEquipment } from 'src/pages/WorkIssuesPage/utils';

type Severity = 'error' | 'success' | 'info' | 'warning' | undefined;

type CheckListGridProps = {
  data?: any;
  onUploaded?: any;
  onSelectedFilesChanged?: any;
  relatedKey: string | null;
  onEdit: (data: ICheckListType) => void;
  onMove: (data: IMoveItemType) => void;
  onDelete: (data: ICheckListType) => void;
  disabled?: boolean;
  reload?: boolean;
  disableEdit?: boolean;
};

const moveXBeforeY = (data: any, startIndex: number, endIndex: number) => {
  const items = data.splice(startIndex, 1);
  data.splice(endIndex, 0, items[0])
  return data;
}

export const transformCheckListItem = (
  item: TblSchedChkDocument,
  description?: TblSchedChkDescDocument | null,
  equipment?: string | null,
): ICheckListType & { original: TblSchedChkDocument } => {
  return {
    PKey: item.PKey,
    FKey: item?.FKey,
    EqKey: item?.EqKey,
    fldLocHierarchy: item?.fldLocHierarchy,
    fldTitle: item.fldTitle || null,
    DescKey: item.DescKey || null,
    fldDescriptions: description && description.fldDescriptions,
    fldIndex:item?.fldIndex,
    equipment: equipment,
    original: item,
  };
};

const CheckListGrid: FC<CheckListGridProps> = ({
  relatedKey,
  onEdit,
  onMove,
  onDelete,
  disableEdit= false,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<Severity>(undefined);
  const highlightedRowId = useRef<string>('-1');
  const gridRef: any = useRef<HTMLDivElement>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState<any>();
  const [dataSource, setDataSource] = useState<any>([]);

  const handleDeleteCheckItem = (data: any) => {
    setDeleteSelected(data);
    setIsDeleting(true);
  };

  const handleDeleteOk = () =>{
    onDelete && onDelete(deleteSelected);
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
    setDeleteSelected(undefined);
  }

  const loadData = async ({
    skip,
    limit,
    filterValue,
  }: {
    sortInfo?: TypeSortInfo;
    skip?: number;
    limit?: number;
    filterValue?: any;
  }): Promise<{ data: any[]; count: number }> => {
    const db = await getDatabase();

    let selector = {
      FKey: isNil(relatedKey) ? null : relatedKey,
      Completed: {
        $eq: false,
      },
      deletedAt: {
        $eq: null
      },
    };

    const items = await db.tblschedchk
      .find({
        selector,
        skip,
        limit,
      })
      .exec();

    const data = await Promise.all<ICheckListType>(
      items.map(async (item: any) => {
        // Push subscription to entry list, so we can unsubscribe
        // subscriptions.current.set('cross', referenceSubscription);
        const description = await db.tblschedchkdesc
          .find({
            selector: {
              PKey: item.DescKey,
            },
          })
          .exec();

        // send equipment UniqueName for EquipmentDropdown default value
        const equipment = await getEquipment(item);
          if (isNil(description)) {
            return transformCheckListItem(item, null, equipment?.UniqueName);
          }
  
          return transformCheckListItem(item, description[0], equipment?.UniqueName);
      })
    );

    data.sort((itemA: any, itemB: any) => {
      if (itemA.fldIndex > itemB.fldIndex) {
          return 1;
      } else {
          return -1;
      }
    })

    setDataSource(data);
    const length = size(items);
    return { data, count: length };
  };

  const handleNewGridEvent = async (ev: any, ref: MutableRefObject<TypeComputedProps | null> ) => {
    if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
      highlightedRowId.current = ev.documentId;
      ref.current?.reload();
    }
    if (ev.operation==='DELETE') {
      highlightedRowId.current = '-1';
      ref.current?.reload();
    }
  }
  const initGrid = async (ref: MutableRefObject<TypeComputedProps | null>) => {
    const db = await getDatabase();
    db.tblschedchk.$.subscribe((ev:any) => handleNewGridEvent(ev, ref));
    db.tblschedchkdesc.$.subscribe((ev:any) => handleNewGridEvent(ev, ref));
  };

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    initGrid(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== '-1') {
      gridRef?.current?.scrollToId(highlightedRowId.current);
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setLoading(status);
  };

  const dataSourceRaw = useCallback(loadData, []);

  const columns = [
    {
      name: "fldTitle",
      header: "Inspection Title",
      flex: 1,
    },
    {
      name: "fldDescriptions",
      header: "Description",
      flex: 1,
      render: ({ data }: any) => {
        const plainTextDescription = getPlainTextFromHtml(data.fldDescriptions);
        return (<span>{plainTextDescription}</span>);
      },
    },
    {
      id: "icons",
      default: 0.4,
      render: ({ data }: any) =>{
      return (
        <div className = "flex justify-center">
          <IconButton
            onClick={() => onEdit(data)}
            size="small"
            aria-label="Edit item"
            className="mx-2"
            disabled={disableEdit}
          >
            <Edit fontSize="inherit" />
          </IconButton>

          <IconButton
            onClick={() => onMove(data)}
            size="small"
            aria-label="Move item"
            className="mx-2"
            disabled={disableEdit}
          >
            <MoveUpTwoTone fontSize="inherit" />
          </IconButton>

          <IconButton
            onClick={() => handleDeleteCheckItem(data)}
            size="small"
            color="error"
            aria-label="Delete item"
            className="mx-2"
            disabled={disableEdit}
          >
            <DeleteTwoTone fontSize="inherit" />
          </IconButton>
        </div>
      )
      },
    },
  ];

  const renderRowReorderProxy = ({ data }: any) => {
    return <div style={{ paddingLeft: 30 }}>Index: {data.fldIndex} - Name: {data.fldTitle}</div>
  }

  const handleRowReorder = useCallback(async ({ data, dragRowIndex, insertRowIndex }) => {
    let newData = moveXBeforeY([...dataSource], dragRowIndex, insertRowIndex);

    const start = Math.min(dragRowIndex, insertRowIndex);
    const end = Math.max(dragRowIndex, insertRowIndex);

    for (let i = start; i <= end; i++) {
        await newData[i].original.atomicPatch({ fldIndex: i + 1 });
    }

    setDataSource(newData);
  }, [dataSource]);

  return (
    <>
      <div data-testid="data-grid" className="flex flex-col flex-grow attachment-grid">
        <LicensedReactDataGrid
          onLoadingChange={onLoadingChange}
          defaultLimit={100}
          livePagination
          onReady={onReady}
          rowHeight={40}
          loading={loading}
          idProperty="PKey"
          showHoverRows={false}
          columns={columns}
          dataSource={dataSourceRaw}
          renderRowReorderProxy={renderRowReorderProxy}
          onRowReorder={handleRowReorder}
        />
      </div>
      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
      <Snackbar
        open={alert}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setAlert(false);
        }}
      >
        <Alert severity={alertSeverity}>{alertMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default CheckListGrid;
