import { gql, useQuery } from "@apollo/client";
import { SparesUsed } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_SPARES_USED = gql`
  query GetSparesUsed($logKey: String!) {
    sparesUsed(logKey: $logKey) {
      id
      inventoryId
      equipmentId
      logEntryId
      multipleLocationId
      amount
      inventory {
        id
        productName
        manufacturer
        partNumber
        multipleLocations {
          locationId
        }
      }
    }
  }
`

interface SparesUsedResult {
  sparesUsed: SparesUsed[];
  sparesUsedLoading: boolean;
  sparesUsedCount: number;
}

export const useGetSparesUsed = (recordId: string): SparesUsedResult => {
  const { data, loading, error } = useQuery(GET_SPARES_USED, {
    variables: { logKey: recordId },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetSparesUsed').error(`Error fetching spares for record: ${recordId} -->`, error.message);
  }

  return {
    sparesUsed: data?.sparesUsed || [],
    sparesUsedLoading: loading,
    sparesUsedCount: data?.sparesUsed?.length 
  };
};

