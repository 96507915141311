import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PhotoIcon from '@mui/icons-material/Photo';
import CommentIcon from '@mui/icons-material/Comment';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import FactCheckIcon from '@mui/icons-material/FactCheck'; // based on verifiedReq
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'; //based on smsReport

interface GridRowIconProps {
  hasAttachments?: boolean;
  hasPhotos?: boolean;
  hasComments?: boolean;
  hasSpares?: boolean;
  isCritical?: boolean;
  isReport?: boolean;
  requiresVerification?: boolean;
}

const GridRowIcon: FC<GridRowIconProps> = ({ 
  hasAttachments, 
  hasPhotos, 
  hasComments, 
  hasSpares, 
  isCritical, 
  isReport,
  requiresVerification
}) => {
  return (
    <div className="flex">
      { hasAttachments && <AttachFileIcon color='info'/> }
      { hasPhotos && <PhotoIcon color='info'/>}
      { hasComments && <CommentIcon color='success'/>}
      { hasSpares && <SettingsSuggestIcon color='action'/>}
      { isCritical && <AddIcon color='error'/> } 
      { isReport && <DocumentScannerIcon color='info'/> } 
      { requiresVerification && <FactCheckIcon color='warning'/> } 
    </div>
  );
};

export default GridRowIcon;
