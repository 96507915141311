import { FC, isValidElement, cloneElement } from 'react';
import { Drawer, DrawerProps, styled, useMediaQuery, useTheme } from '@mui/material';
import withFormProgressTracking, { WithFormProgressTrackingProps } from 'src/hoc/withFormProgressTracking';

interface InjectedProps extends Partial<DrawerProps>, WithFormProgressTrackingProps {
  onClose: () => void;
  visible: boolean;
}

export interface InjectedDrawerProps {
  setFormIsDirty: (dirty: boolean) => void;
}

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '50%',
  },
}));

const PageDrawer: FC<InjectedProps> = ({
  children, visible, onClose, setFormIsDirty, showPrompt, ...rest
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const handleDrawerClose = async () => {
    // In case we have callback provided
    // make sure we don't close this drawer right away
    showPrompt(onClose);
  }

  const childrenWithProps = isValidElement(children) ? cloneElement<any>(children, { setFormIsDirty }) : children;

  return (
    <StyledDrawer
      {...rest}
      sx={{
        ...(isLargeScreen
          ? {
            '& .MuiDrawer-paper': {
              width: '50%',
            },
          }
          : {
            '& .MuiDrawer-paper': {
              width: '100%',
            },
          }),
          zIndex: 1100
      }}
      anchor="right"
      onClose={handleDrawerClose}
      open={visible}
    >
      {childrenWithProps}
    </StyledDrawer>
  );
};

export default withFormProgressTracking(PageDrawer);
