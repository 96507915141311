import { FC, useEffect, useState } from 'react';
import { useForm, UseFormWatch } from 'react-hook-form';
import { isArray, isEmpty, isNil, isNull, isString, last, size } from 'lodash';
import Button from '@mui/material/Button';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Portal, Snackbar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { getDatabase } from 'src/rxdb';
import { CHAR_LIMIT, CHECKLIST } from 'src/consts';
import Input from 'src/components/UI/Forms/Input';
import { ICheckListType } from './CheckListTab';
import EquipmentDropdownOld from 'src/components/Dropdowns/EquipmentDropdown/indexOld';
import LocationDropdownOld from 'src/components/Dropdowns/LocationDropdown/indexOld';
import RichTextEditor from 'src/components/UI/Forms/RichTextEditor';
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';
import { handleCharLimitWarning } from 'src/utils';

interface InjectedProps {
  initialValue?: ICheckListType;
  visible: boolean;
  onCancel?: () => void;
  onSubmit: (payload: ICheckListType) => void;
  saveLoading: boolean;
  watch: UseFormWatch<any>;
  disableCheckbox?: boolean;
  isEdit: boolean;
}

const filedefaultValue = {
  PKey: '',
  fldTitle: '',
  EqKey: '',
  fldLocHierarchy: '',
  fldDescriptions: '',
  fldIndex: null,
  Equipment: ''
};

const CheckListEditPopup: FC<InjectedProps> = ({
  initialValue,
  watch,
  visible,
  onCancel,
  onSubmit,
  saveLoading,
  isEdit,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    register,
    setValue,
    formState,
  } = useForm<any>({
    mode: 'onBlur',
    shouldFocusError: true,
  });

  const { ref, ...inputProps } = register('fldTitle', { required: 'Field required' });
  const [alert, setAlert] = useState(false);
  const { TBLSCHEDCHK } = CHAR_LIMIT;
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const setInitialValue = async () => {
    if (isNil(initialValue)) return reset(filedefaultValue);

    const payload = {
      PKey: initialValue.PKey,
      fldTitle: initialValue.fldTitle,
      EqKey: initialValue.EqKey,
      fldLocHierarchy: initialValue.fldLocHierarchy,
      fldDescriptions: initialValue.fldDescriptions,
      DescKey: initialValue.DescKey,
      fldIndex: initialValue.fldIndex,
      Completed: initialValue.Completed,
      Equipment: initialValue.equipment,
    };

    updateFormData(payload);
  };

  useEffect(() => {
    setInitialValue();
  }, [initialValue]);

  const updateFormData = (payload: any) => {
    if (!isNil(payload)) {
      reset(payload);
    }
  };

  const onChange = async (name: string, value: any) => {
    // If it's equipment change -> populate location based on it.
    if (name === 'Equipment') {
      const equipment = value as EquipmentDocument;
      setValue('fldLocHierarchy', equipment?.fldLocHierarchy);
      setValue('Equipment', { EqKey: value.EqKey, UniqueName: value.UniqueName }, { shouldDirty: true });
      return;
    }

    let shouldDirty = true;

    if (name === 'fldLocHierarchy') {
      const updatedValue = (isArray(value) ? last(value) : value) || null;
      if (initialValue?.fldLocHierarchy === updatedValue) {
        shouldDirty = false;
      }
    }
    setValue(name, value, { shouldDirty: shouldDirty });
  };

  const onSaveClicked = async (data: any) => {
    if (initialValue && !initialValue.fldTitle) {
      setAlert(true);
      return;
    }

    const {
      PKey,
      fldTitle,
      Equipment,
      fldLocHierarchy,
      fldDescriptions,
      DescKey,
      fldIndex,
      Completed
    } = data
    
    const payload: any = {
      PKey: PKey,
      FKey: initialValue?.FKey,
      fldTitle: fldTitle,
      EqKey: isString(Equipment)? initialValue?.EqKey : Equipment?.EqKey,
      fldLocHierarchy: (isArray(fldLocHierarchy) ? last(fldLocHierarchy) : fldLocHierarchy) || null,
      fldDescriptions: fldDescriptions,
      DescKey: DescKey,
      fldIndex: fldIndex,
      Completed: Completed,
    };

    onSubmit(payload);
    reset(filedefaultValue);
  };

  const onCancelClick = () => {
    reset(filedefaultValue);
    setAlert(false);
    onCancel && onCancel();
  };

  // TD-2165 - Removed validation for check title duplicate
  // const onTitleCheck = async () => {
  //   const inputTitle = await getValues().fldTitle;
  //   if (!isEmpty(inputTitle) && !(inputTitle === initialValue?.fldTitle)) {
  //     const db = await getDatabase();
  //     const items = await db.tblschedchk
  //       .find({
  //         selector: {
  //           fldTitle: inputTitle,
  //           deletedAt: null,
  //         },
  //       })
  //       .exec();

  //     if (size(items) > 0) {
  //       setSnackbar({
  //         open: true,
  //         message: 'Check Item Title error',
  //         type: 'error',
  //       });
  //       return 'Duplicate Title, please change Check Item Title';
  //     } else {
  //       return '';
  //     }
  //   }
  //   return '';
  // };

  // const category = watch('fldSRHKey', null);

  return (
    <div>
      <form>
        <Dialog
          open={visible}
          onClose={onCancelClick}
          fullWidth
          maxWidth="md"
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '16px' }}>
            <span className="ml-2">
              {isEdit
                ? CHECKLIST.EDIT_ITEM
                : CHECKLIST.ADD_ITEM}
            </span>
            {onCancel ? (
              <IconButton
                aria-label="close"
                onClick={onCancelClick}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 14,
                  color: (theme) => theme.palette.grey[400],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent dividers style={{ maxHeight: '60vh' }}>
            {alert && (
              <Alert severity="error" className="mb-5">
                File is empty
              </Alert>
            )}
            <form
              id="editCheckItem-popup"
              className="relative bg-white flex-grow"
              onSubmit={handleSubmit(onSaveClicked)}
            >
              <div className="bg-white h-full flex-grow">
                <div className="h-full">
                  <div className="mb-6">
                    <div className="mb-4">
                      <Input
                        inputProps={{
                          size: 'small',
                          label: 'Title',
                        }}
                        rules={{
                          required: 'Check Item Title required',
                          // TD-2165 - Removed validation for check title duplicate
                          // validate: async () => {
                          //   const str = await onTitleCheck();
                          //   if (!isEmpty(str)) {
                          //     return str;
                          //   }
                          // },
                          maxLength: TBLSCHEDCHK.fldTitle
                        }}
                        warning={(value) => handleCharLimitWarning(value, TBLSCHEDCHK.fldTitle)}
                        control={control}
                        name="fldTitle"
                      />
                    </div>
                    <div className="mb-4">
                    <EquipmentDropdownOld
                      label="Equipment"
                      control={control}
                      defaultValue={initialValue?.EqKey}
                      // category={(isArray(category) ? last(category) : category) || null}
                      category={null} // set category to null, so equipment dropdown options shows all eq regardless category
                      onChange={onChange}
                    />
                    </div>
                      <div className="mb-4">
                      <LocationDropdownOld
                        control={control}
                        label="Location"
                        name="fldLocHierarchy"
                        onChange={onChange}
                      />
                      </div>
                      <div className="mb-4">
                        <RichTextEditor
                          control={control}
                          name="fldDescriptions"
                          onChange={onChange}
                        />
                      </div>
                  </div>
                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions sx={{ m: 0, p: 3 }}>
            <LoadingButton
              onClick={handleSubmit(onSaveClicked)}
              loading={saveLoading}
              className="ml-4 mr-3 w-32"
              variant="contained"
            >
              Save
            </LoadingButton>
            <Button onClick={onCancelClick} className="w-32">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Portal>
          <Snackbar
            open={snackBar.open}
            autoHideDuration={2000}
            onClose={onSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
              {snackBar.message}
            </Alert>
          </Snackbar>
        </Portal>
      </form>
    </div>
  );
};

export default CheckListEditPopup;
