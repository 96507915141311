import './style.css';
import { FC, useCallback, useState, useEffect } from 'react';
import LicensedReactDataGrid from '../../../UI/LicensedReactDataGrid';
import { isEmpty, isNil } from 'lodash';
import { TypeFilterValue, TypeSingleSortInfo, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { Equipment, EquipmentSchedule, Inventory, Schedule, SortEnumType } from 'src/generated/dotnet.graphql';
import { INVENTORY } from 'src/consts';
import { DROPDOWN_OPTIONS } from './DocumentRelatedItemAddDialog';
import { useGetLazyEquipment } from 'src/hooks/equipment/useGetLazyEquipment';
import { useGetLazyInventory } from 'src/hooks/inventory/useGetLazyInventory';
import { useGetLazyEquipmentSchedules } from 'src/hooks/equipmentSchedules/useGetLazyEquipmentSchedules';
import { useGetLazyEventSchedules } from 'src/hooks/eventSchedules/useGetLazyEventSchedules';
import { useGetLazyDrillSchedules } from 'src/hooks/drillSchedules/useGetLazyDrillSchedules';
import { useGetLazyInspectionSchedules } from 'src/hooks/inspectionSchedules/useGetLazyInspectionSchedules';

type QueryStateKey = 'Equipment' | 'InventoryGeneral' | 'InventoryBeverages' | 'InventoryUniform' | 'InventoryDryCold' | 'InventoryMedical' | 'ScheduleEquipment' | 'ScheduleEvent' | 'ScheduleDrill' | 'ScheduleInspections';

enum QueryFieldKey {
  SORT_VALUE = 'sortValue',
  FILTER_VALUE = 'filterValue',
  SKIP = 'skip',
  LIMIT = 'limit',
}

type QueryState = {
  skip: number;
  limit: number;
  filterValue: {};
  sortValue: any;
};

const filter = [
  {
    name: 'uniqueName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'productName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'partNumber',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'subject',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'equipment.uniqueName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const getSelectorByFilterName = async (
  name: string,
  value: any,
) => {

  switch (name) { 
    case 'equipment.uniqueName': {
      return { equipmentName: value };
    }
    case 'uniqueName':
    case 'productName':
    case 'subject':
    case 'manufacturer':
    case 'partNumber': {
      return { [name]: value };
    }
    default:
      return {};
  }
};

type DocumentRelatedItemAddGridProps = {
  onSelect: (recordIds: string[]) => void;
  selectedOptionType: string;
  excludedIds: string[];
};

const DocumentRelatedItemAddGrid: FC<DocumentRelatedItemAddGridProps> = ({ onSelect, selectedOptionType, excludedIds }) => {

  const initialState: Record<QueryStateKey, QueryState> = {
    Equipment: {
      skip: 0,
      limit: 100,
      filterValue: { excludeIds: excludedIds },
      sortValue: { uniqueName: SortEnumType.Asc },
    },
    InventoryGeneral: {
      skip: 0,
      limit: 100,
      filterValue: { dataType: INVENTORY.GENERAL.dataType, excludeIds: excludedIds },
      sortValue: { productName: SortEnumType.Asc },
    },
    InventoryBeverages: {
      skip: 0,
      limit: 100,
      filterValue: { dataType: INVENTORY.BEVERAGES.dataType, excludeIds: excludedIds },
      sortValue: { productName: SortEnumType.Asc },
    },
    InventoryUniform: {
      skip: 0,
      limit: 100,
      filterValue: { dataType: INVENTORY.UNIFORM.dataType, excludeIds: excludedIds },
      sortValue: { productName: SortEnumType.Asc },
    },
    InventoryDryCold: {
      skip: 0,
      limit: 100,
      filterValue: { dataType: INVENTORY.DRY_STORES.dataType, excludeIds: excludedIds },
      sortValue: { productName: SortEnumType.Asc },
    },
    InventoryMedical: {
      skip: 0,
      limit: 100,
      filterValue: { dataType: INVENTORY.MEDICAL.dataType, excludeIds: excludedIds },
      sortValue: { productName: SortEnumType.Asc },
    },
    ScheduleEquipment: {
      skip: 0,
      limit: 100,
      filterValue: { excludeIds: excludedIds },
      sortValue: { equipment: { uniqueName: SortEnumType.Asc } },
    },
    ScheduleEvent: {
      skip: 0,
      limit: 100,
      filterValue: { excludeIds: excludedIds },
      sortValue: { subject: SortEnumType.Asc },
    },
    ScheduleDrill: {
      skip: 0,
      limit: 100,
      filterValue: { excludeIds: excludedIds },
      sortValue: { subject: SortEnumType.Asc },
    },
    ScheduleInspections: {
      skip: 0,
      limit: 100,
      filterValue: { excludeIds: excludedIds },
      sortValue: { subject: SortEnumType.Asc },
    },
  }

  const [columns, setColumns] = useState<any>([]);
  const [queryStates, setQueryStates] = useState<Record<QueryStateKey, QueryState>>(initialState);
  const { skip, limit, filterValue, sortValue } = queryStates[selectedOptionType as QueryStateKey] || { skip: 0, limit: 100, filterValue: {}, sortValue: {} };

  const { data: equipment, totalCount: totalCountEquipment, loading: loadingEquipment, loadData: loadEquipment } = useGetLazyEquipment();
  const { data: inventory, totalCount: totalCountInventory, loading: loadingInventory, loadData: loadInventory } = useGetLazyInventory();
  const { data: equipmentSchedules, totalCount: totalCountEquipmentSchedules, loading: loadingEquipmentSchedules, loadData: loadEquipmentSchedules } = useGetLazyEquipmentSchedules();
  const { data: eventSchedules, totalCount: totalCountEventSchedules, loading: loadingEventSchedules, loadData: loadEventSchedules } = useGetLazyEventSchedules();
  const { data: drillSchedules, totalCount: totalCountDrillSchedules, loading: loadingDrillSchedules, loadData: loadDrillSchedules } = useGetLazyDrillSchedules();
  const { data: inspectionSchedules, totalCount: totalCountInspectionSchedules, loading: loadingInspectionSchedules, loadData: loadInspectionSchedules } = useGetLazyInspectionSchedules();
  const loading = loadingEquipment || loadingInventory || loadingEquipmentSchedules || loadingEventSchedules || loadingDrillSchedules || loadingInspectionSchedules;

  useEffect(() => {
    initColumns();
  }, [selectedOptionType]); 

  useEffect(() => {
    loadData();
  }, [selectedOptionType, queryStates]);

  const loadData = () => {
    switch (selectedOptionType) {
      case DROPDOWN_OPTIONS.EQUIPMENT.type:
        loadEquipment(skip, limit, filterValue, sortValue);
        break;
      case DROPDOWN_OPTIONS.INV_GENERAL.type:
      case DROPDOWN_OPTIONS.INV_BEVERAGES.type:
      case DROPDOWN_OPTIONS.INV_UNIFORM.type:
      case DROPDOWN_OPTIONS.INV_DRY_COLD.type:
      case DROPDOWN_OPTIONS.INV_MEDICAL.type:
        loadInventory(skip, limit, filterValue, sortValue);
        break;
      case DROPDOWN_OPTIONS.SCHED_EQUIPMENT.type:
        loadEquipmentSchedules(skip, limit, filterValue, sortValue);
        break;
      case DROPDOWN_OPTIONS.SCHED_EVENT.type:
        loadEventSchedules(skip, limit, filterValue, sortValue);
        break;
      case DROPDOWN_OPTIONS.SCHED_DRILL.type:
        loadDrillSchedules(skip, limit, filterValue, sortValue);
        break;
      case DROPDOWN_OPTIONS.SCHED_INSPECTION.type:
        loadInspectionSchedules(skip, limit, filterValue, sortValue);
        break;
    }
  };

  const dataSource = async () => {
    let data: Equipment[] | Inventory[] | EquipmentSchedule[] | Schedule[];
    let totalCount: number;
  
    switch (selectedOptionType) {
      case DROPDOWN_OPTIONS.EQUIPMENT.type:
        data = equipment;
        totalCount = totalCountEquipment;
        break;
      case DROPDOWN_OPTIONS.INV_GENERAL.type:
      case DROPDOWN_OPTIONS.INV_BEVERAGES.type:
      case DROPDOWN_OPTIONS.INV_UNIFORM.type:
      case DROPDOWN_OPTIONS.INV_DRY_COLD.type:
      case DROPDOWN_OPTIONS.INV_MEDICAL.type:
        data = inventory;
        totalCount = totalCountInventory;
        break;
      case DROPDOWN_OPTIONS.SCHED_EQUIPMENT.type:
        data = equipmentSchedules;
        totalCount = totalCountEquipmentSchedules;
        break;
      case DROPDOWN_OPTIONS.SCHED_EVENT.type:
        data = eventSchedules;
        totalCount = totalCountEventSchedules;
        break;
      case DROPDOWN_OPTIONS.SCHED_DRILL.type:
        data = drillSchedules;
        totalCount = totalCountDrillSchedules;
        break;
      case DROPDOWN_OPTIONS.SCHED_INSPECTION.type:
        data = inspectionSchedules;
        totalCount = totalCountInspectionSchedules;
        break;
      default:
        data = equipmentSchedules;
        totalCount = totalCountEquipmentSchedules;
        break;
    }
    return { data, count: totalCount };
  };
  
  const initColumns = () => {
    switch (selectedOptionType) {
      case DROPDOWN_OPTIONS.EQUIPMENT.type: {
        const column = [
          {
            name: 'uniqueName',
            header: 'Equipment Name',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      case DROPDOWN_OPTIONS.INV_GENERAL.type: {
        const column = [
          {
            name: 'productName',
            header: 'Name',
            flex: 1,
          },
          {
            name: 'manufacturer',
            header: 'Manufacturer',
            flex: 1,
          },
          {
            name: 'partNumber',
            header: 'Part Number',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      case DROPDOWN_OPTIONS.INV_BEVERAGES.type: {
        const column = [
          {
            name: 'productName',
            header: 'Name',
            flex: 1,
          },
          {
            name: 'manufacturer',
            header: 'Maker/Bottler',
            flex: 1,
          },
          {
            name: 'partNumber',
            header: 'Varietal',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      case DROPDOWN_OPTIONS.INV_UNIFORM.type: {
        const column = [
          {
            name: 'productName',
            header: 'Name',
            flex: 1,
          },
          {
            name: 'manufacturer',
            header: 'Manufacturer',
            flex: 1,
          },
          {
            name: 'partNumber',
            header: 'Style',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      case DROPDOWN_OPTIONS.INV_DRY_COLD.type: {
        const column = [
          {
            name: 'productName',
            header: 'Name',
            flex: 1,
          },
          {
            name: 'manufacturer',
            header: 'Manufacturer',
            flex: 1,
          },
          {
            name: 'partNumber',
            header: 'Product Number',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      case DROPDOWN_OPTIONS.INV_MEDICAL.type: {
        const column = [
          {
            name: 'productName',
            header: 'Name',
            flex: 1,
          },
          {
            name: 'manufacturer',
            header: 'Manufacturer',
            flex: 1,
          },
          {
            name: 'partNumber',
            header: 'Item Number',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      case DROPDOWN_OPTIONS.SCHED_EQUIPMENT.type: {
        const column = [
          {
            name: 'equipment.uniqueName',
            header: 'Equipment Name',
            flex: 1,
            render: ({ data }: any) => <span>{data?.equipment?.uniqueName}</span>,
          },
          {
            name: 'subject',
            header: 'Subject',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      case DROPDOWN_OPTIONS.SCHED_EVENT.type:
      case DROPDOWN_OPTIONS.SCHED_DRILL.type:
      case DROPDOWN_OPTIONS.SCHED_INSPECTION.type: {
        const column = [
          {
            name: 'subject',
            header: 'Subject',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      default:
        return;
    }
  };

  const updateQueryState = (option: QueryStateKey, key: string, value: any) => {
    setQueryStates((prevState) => {
      const currentState = prevState[option] || {};
  
      const updatedState = {
        ...prevState,
        [option]: {
          ...initialState[option],
          ...currentState,
          [key]: key === 'filterValue' ? { ...initialState[option].filterValue, ...value } : value, // Merge with default filter values
        },
      };
  
      // Check if the new state is different enough to warrant an update
      if (JSON.stringify(currentState) !== JSON.stringify(updatedState[option])) {
        return updatedState;
      }
  
      // Return the previous state if there is no change
      return prevState;
    });
  };
  
  const onSortInfoChange = (value: TypeSortInfo) => {
    const sortInfo = value as TypeSingleSortInfo
    if (isNil(sortInfo)) return;

    let sortPayload;
    const sortDirection = sortInfo.dir === 1 ? SortEnumType.Asc : SortEnumType.Desc;
    const [field, subField] = sortInfo.name.split('.');

    if (subField) {
        // Handle nested objects
        sortPayload = [{
            [field]: {
                [subField]: sortDirection,
            },
        }];
    } else {
        // Handle non-nested objects
        sortPayload = [{
            [sortInfo.name]: sortDirection,
        }];
    }

    updateQueryState(selectedOptionType as QueryStateKey, QueryFieldKey.SORT_VALUE, sortPayload)
  }

  const onFilterValueChange = async (filterValue: TypeFilterValue) => {
    if (isNil(filterValue)) return;

    const selectors = await Promise.all(
      filterValue.map(async (v: { value: any; name: any; operator: any }) => {
        if (isEmpty(v.value)) return null;
  
        const selector = await getSelectorByFilterName(v.name, v.value);
        return selector;
      })
    );
  
    // Filter out null values
    const validSelectors = selectors.filter((selector) => selector !== null);

    // Combine the valid selectors into a single `where` object using `and`
    const filterPayload = validSelectors.length > 1
      ? { and: validSelectors }
      : validSelectors[0] || {};
      
    updateQueryState(selectedOptionType as QueryStateKey, QueryFieldKey.FILTER_VALUE, filterPayload)
  };

  const onSelectionChange = useCallback(({ selected: selectedMap }) => {
    const recordIds = Object.keys(selectedMap);
    onSelect(recordIds);
  }, []);

  const handleSkipChange = (newSkip: number) => {
    updateQueryState(selectedOptionType as QueryStateKey, QueryFieldKey.SKIP, newSkip);
  };
  
  const handleLimitChange = (newLimit: number) => {
    updateQueryState(selectedOptionType as QueryStateKey, QueryFieldKey.LIMIT, newLimit);
  };
  
  return (
    <div
      data-testid="data-grid"
      className="flex flex-col flex-grow p-4 related-add-grid"
    >
        <LicensedReactDataGrid
          idProperty={"id"}
          skip={skip}
          onSkipChange={handleSkipChange}
          limit={limit}
          onLimitChange={handleLimitChange}
          pagination="remote"
          checkboxColumn
          checkboxOnlyRowSelect
          rowHeight={40}
          loading={loading}
          defaultFilterValue={filter}
          onFilterValueChange={onFilterValueChange}
          onSortInfoChange={onSortInfoChange}
          allowUnsort={false}
          onSelectionChange={onSelectionChange}
          columns={columns}
          dataSource={dataSource}
          showColumnMenuTool={false}
          enableColumnFilterContextMenu={false}
        />
    </div>
  );
};

export default DocumentRelatedItemAddGrid;
