import { gql, useQuery } from "@apollo/client";
import { Attachment } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_PROCEDURE_DOCUMENT = gql`
  query GetProcedureDocument($scheduleId: String!) {
    procedureDocument(scheduleId: $scheduleId) {
      altPath
      attachmentType
      categoryId
      deletedAt
      description
      docTitle
      documentationId
      fileExtension
      fileName
      id
      libType
      notes
      page
      recordId
      recordName
      recordType
      revision
      revisionDate
      showInCentral
    }
  }
`

interface ProcedureDocumentResult {
  procedureDocumentData: Attachment;
  procedureDocumentLoading: boolean;
}

export const useGetProcedureDocument = (scheduleId: string): ProcedureDocumentResult => {

  const { data, loading, error } = useQuery(GET_PROCEDURE_DOCUMENT, {
    variables: { scheduleId },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetProcedureDocument').error(`Error fetching Procedure Document's for schedule: ${scheduleId} -->`, error.message);
  }

  return {
    procedureDocumentData: data?.procedureDocument,
    procedureDocumentLoading: loading,
  };
};

