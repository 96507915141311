import { FC } from 'react';
import { Control } from 'react-hook-form';
import { useAuth } from 'src/contexts/auth';
import { useGetLocations } from 'src/hooks/locations/useGetLocations';
import TreeSelect from 'src/components/UI/Forms/TreeSelect';
import { RecordType } from 'src/generated/dotnet.graphql';

type LocationProps = {
  label: string;
  control: Control;
  name: string;
  onChange: (field: string, value: any) => void;
  equipment?: any;
  disabled?: boolean;
  defaultValue?: string | null;
  allDepts?: boolean;
  recordType?: RecordType;
  dropdownHeight?: number;
};

const LocationDropdown: FC<LocationProps> = ({
  control,
  name,
  label,
  onChange,
  equipment,
  disabled,
  defaultValue,
  recordType,
  allDepts = false,
  dropdownHeight,
}) => {
  const { user } = useAuth();
  const { data } = useGetLocations();

  const handleFilter = (item: any) => {
    switch (recordType) {
      case RecordType.WorkIssue:
        return getTaskCondition(item);
      case RecordType.LogEntry:
        return getLogEntryCondition(item);
      case RecordType.Equipment:
        return getEquipmentCondition(item);
      case RecordType.EventSchedule:
        return getEventSchedCondition(item);
      case RecordType.DrillSchedule:
        return getDrillSchedCondition(item);
      case RecordType.InventoryGeneral:
        return getGenInvCondition(item);
      case RecordType.InventoryBeverages:
        return getNoteTwoCondition(item, 'BEVERAGES');
      case RecordType.InventoryUniform:
        return getNoteTwoCondition(item, 'UNIFORMS');
      case RecordType.InventoryDryAndColdStore:
        return getNoteTwoCondition(item, 'STORES');
      case RecordType.InventoryMedical:
        return getNoteTwoCondition(item, 'MEDICAL');
      case RecordType.InspectionSchedule:
        return getInspectionsCondition(item);
      case RecordType.DocumentationDrawings:
        return getTaskCondition(item);
      case RecordType.DocumentationPhotos:
        return getTaskCondition(item);
      case RecordType.DocumentationIsps:
        return getISPSCondition(item);
      case RecordType.DocumentationSms:
        return getSMSCondition(item);
      default:
        return true;
    }
  };

  const getSMSCondition = (item: any) => {
    if (item.tags && item.tags.includes('SMSDOCS')) {
      return true;
    }
    return false;
  };

  const getISPSCondition = (item: any) => {
    if (item.tags && item.tags.includes('ISPSDOCS')) {
      return true;
    }
    return false;
  };

  const getTaskCondition = (item: any) => {
    if (allDepts) {
      if (!item.tags || item.tags.includes('WORKISSUES')) {
        return true;
      }
      return false;
    }
    if ((!item.tags || item.tags.includes('WORKISSUES')) && (!item.depts || item.depts.includes((user?.Department || '').toUpperCase()))) {
      return true;
    }
    //Adding below condition to handle if user do not have permission to see applied category but have permission to see task then it will enable that category only for the task
    if (item.id === defaultValue){
      return true
    }
    return false;
  };

  const getLogEntryCondition = (item: any) => {
    if (allDepts) {
      if (!item.tags || item.tags.includes('LOGENTRY')) {
        return true;
      }
      return false;
    }
    if ((!item.tags || item.tags.includes('LOGENTRY')) && (!item.depts || item.depts.includes((user?.Department || '').toUpperCase()))) {
      return true;
    }
    return false;
  };

  const getEquipmentCondition = (item: any) => {
    if (allDepts) {
      if (!item.tags || item.tags.includes('EQUIPMENT')) {
        return true;
      }
      return false;
    }
    if ((!item.tags || item.tags.includes('EQUIPMENT')) && (!item.depts || item.depts.includes((user?.Department || '').toUpperCase()))) {
      return true;
    }
    return false;
  };

  const getEventSchedCondition = (item: any) => {
    if (allDepts) {
      if (!item.tags || item.tags.includes('EVENT_SCHEDULING')) {
        return true;
      }
      return false;
    }
    if ((!item.tags || item.tags.includes('EVENT_SCHEDULING')) && (!item.depts || item.depts.includes((user?.Department || '').toUpperCase()))) {
      return true;
    }
    return false;
  };

  const getDrillSchedCondition = (item: any) => {
    if (allDepts) {
      if (!item.tags || item.tags.includes('DRILL_SCHEDULING')) {
        return true;
      }
      return false;
    }
    if ((!item.tags || item.tags.includes('DRILL_SCHEDULING')) && (!item.depts || item.depts.includes((user?.Department || '').toUpperCase()))) {
      return true;
    }
    return false;
  };

  const getGenInvCondition = (item: any) => {
    if (allDepts) {
      if (!item.tags || item.tags.includes('INVENTORY')) {
        return true;
      }
      return false;
    }
    if ((!item.tags || item.tags.includes('INVENTORY')) && (!item.depts || item.depts.includes((user?.Department || '').toUpperCase()))) {
      return true;
    }
    return false;
  };

  const getInspectionsCondition = (item: any) => {
    if (allDepts) {
      if (!item.tags || item.tags.includes('INSPECTIONS')) {
        return true;
      }
      return false;
    }
    if ((!item.tags || item.tags.includes('INSPECTIONS')) && (!item.depts || item.depts.includes((user?.Department || '').toUpperCase()))) {
      return true;
    }
    return false;
  };

  const getNoteTwoCondition = (item: any, field: string) => {
    if (item.tags && item.tags.includes(field)) {
      return true;
    }
    return false;
  };

  const locations = data.filter(handleFilter).map((elem: any) => ({
    id: elem.id,
    parentId: elem.parentId,
    location: elem.location,
    expanded: false,
  }));

  return (
    <div data-testid="data-grid">
      <TreeSelect
        disabled={disabled}
        keyExpr="id"
        parentIdExpr="parentId"
        displayExpr="location"
        label="Location"
        onChange={onChange}
        options={locations}
        control={control}
        name={name}
        valueExpr="id"
        dropdownHeight={dropdownHeight}
      />
    </div>
  );
};

export default LocationDropdown;
