import { ApolloError, gql, useMutation } from '@apollo/client';
import { Attachment, ProcedureDocumentInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_PROCEDURE_DOCUMENT } from './useGetProcedureDocument';

export const UPSERT_PROCEDURE_DOCUMENT = gql`
  mutation UpsertProcedureDocument($input: ProcedureDocumentInput!) {
    upsertProcedureDocument(input: $input) {
      attachment {
        altPath
        attachmentType
        categoryId
        deletedAt
        description
        docTitle
        documentationId
        fileExtension
        fileName
        id
        libType
        notes
        page
        recordId
        recordName
        recordType
        revision
        revisionDate
        showInCentral
      }
    }
  }
`;

export interface ProcedureDocumentMutationResponse {
  responseData?: Attachment;
  responseMessage: string;
}

interface UpsertProcedureDocumentResult {
  upsertProcedureDocument: (input: ProcedureDocumentInput, isCreate: boolean) => Promise<ProcedureDocumentMutationResponse>;
  upsertProcedureDocumentLoading: boolean;
  upsertProcedureDocumentError?: ApolloError;
}

export const useUpsertProcedureDocumentResult = (): UpsertProcedureDocumentResult => {
  const [AddOrUpdateProcedureDocumentResult, { loading, error }] = useMutation(UPSERT_PROCEDURE_DOCUMENT, {
    onError: (error: ApolloError) => {
      logger('UpsertProcedureDocumentResult').error("Error upserting procedure document -->", error.message);
    }
  });

  const upsertProcedureDocument = async (input: ProcedureDocumentInput, isCreate: boolean): Promise<ProcedureDocumentMutationResponse> => {
    const response = await AddOrUpdateProcedureDocumentResult({ 
      variables: { input },
      refetchQueries: [{
        query: GET_PROCEDURE_DOCUMENT,
        variables: { scheduleId: input.scheduleId }
      }],
    });

    const responseData = response.data?.upsertProcedureDocument?.attachment;

    if (responseData) {
      logger('UpsertProcedureDocumentResult').info(`Procedure document ${isCreate ? 'added' : 'updated'} successfully`, response.data);
      return {
        responseData,
        responseMessage: `Procedure document ${isCreate ? 'added' : 'updated'} successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to ${isCreate ? 'added' : 'updated'} procedure document!`,
      };
    }
  };

  return { 
    upsertProcedureDocument, 
    upsertProcedureDocumentLoading: loading, 
    upsertProcedureDocumentError: error,
  };
};
