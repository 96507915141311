import { FC, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { isNil } from 'lodash';
import { IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Grid, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { ATTACHMENTS, LIBRARY } from '../../../../consts';
import { DocumentationLibType, DocumentRevision } from 'src/generated/dotnet.graphql';
import DocRevFileUploadForm from './DocRevFileUploadForm';

interface InjectedProps {
  visible: boolean;
  initialValue?: DocumentRevision;
  onCancel?: () => void;
  onSubmit: (payload: DocumentRevision) => void;
  setUploadedFile: any;
  uploadedFile: any;
  libraryType: DocumentationLibType;
}

const filedefaultValue = {
  revision: 0,
  page: 0,
  notes: '',
  revisionDate: new Date(),
  approvedByPm: new Date(),
  fileName: '',
};

const DocumentRevisionDialog: FC<InjectedProps> = ({
  initialValue,
  visible,
  setUploadedFile,
  uploadedFile,
  libraryType,
  onCancel,
  onSubmit,
}) => {
  const { control, handleSubmit, reset } = useForm<any>({ mode: 'onBlur', shouldFocusError: true });
  const [alert, setAlert] = useState(false);

  const setInitialValue = async () => {
    // Dont do anything special
    if (isNil(initialValue)) return reset(filedefaultValue);

    const payload = {
      id: initialValue.id,
      documentationId: initialValue.documentationId,
      revision: initialValue.revision,
      page: initialValue.page,
      notes: initialValue.notes,
      revisionDate: initialValue.revisionDate,
      approvedByPm: initialValue.approvedByPm,
      fileName: initialValue.fileName,
    };

    updateFormData(payload);
  };

  useEffect(() => {
    setInitialValue();
  }, [initialValue]);

  const updateFormData = (payload: any) => {
    if (!isNil(payload)) {
      reset(payload);
    }
  };

  const onSaveClicked = (data: DocumentRevision) => {
    if (!uploadedFile.url && initialValue && !initialValue.fileName) {
      setAlert(true);
      return;
    }

    const payload: any = {
      id: data.id,
      documentationId: data.documentationId,
      fileName: data.fileName,
      page: data.page,
      notes: data.notes,
      revision: data.revision,
      revisionDate: data.revisionDate,
      approvedByPm: data.approvedByPm,
    };
    onSubmit(payload);
  };

  const onCancelClick = () => {
    reset(filedefaultValue);
    setAlert(false);
    onCancel && onCancel();
  };

  return (
    <div>
      <form>
        <Dialog
          open={visible}
          onClose={onCancelClick}
          fullWidth
          maxWidth="md"
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '16px' }}>
            <span className="ml-2">
              {libraryType === LIBRARY.PHOTOS.TYPE ? ATTACHMENTS.ATTACH_PHOTOS : 'Revision Information'}
            </span>
            {onCancel ? (
              <IconButton
                aria-label="close"
                onClick={onCancelClick}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 14,
                  color: (theme) => theme.palette.grey[400],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent dividers style={{ maxHeight: '60vh' }}>
            {alert && (
              <Alert severity="error" className="mb-5">
                File is empty
              </Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={6} sm={2}>
                <Controller
                  name="revision"
                  control={control}
                  defaultValue={0}
                  rules={{ required: 'Revision number required' }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      required
                      type="number"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      label="Revision #"
                      className="mb-8"
                      autoComplete='off'
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <Controller
                  name="page"
                  control={control}
                  defaultValue={0}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      type="number"
                      value={value}
                      onChange={onChange}
                      className="mb-8"
                      label="Page #"
                      autoComplete='off'
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Controller
                  name="revisionDate"
                  control={control}
                  defaultValue={new Date()}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Revision Date"
                        value={value}
                        onChange={(date) => {
                          if (date instanceof Date && !isNaN(date.getTime())) {
                            onChange(date); // Ensure valid date handling
                          }
                        }}
                        renderInput={(params) => <TextField {...params} autoComplete="off" size="small" />}
                        className="mb-8"
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Controller
                  name="approvedByPm"
                  control={control}
                  defaultValue={new Date()}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Approved Date"
                        value={value}
                        onChange={(date) => {
                          if (date instanceof Date && !isNaN(date.getTime())) {
                            onChange(date); // Ensure valid date handling
                          }
                        }}
                        renderInput={(params) => <TextField {...params} autoComplete="off" size="small" />}
                        className="mb-8"
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
            </Grid>
            <Controller
              name="notes"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  className="mb-8 w-full"
                  label="Revision Notes"
                  autoComplete='off'
                  rows={3}
                  multiline
                  size="small"
                />
              )}
            />

            <DocRevFileUploadForm
              setUploadedFile={setUploadedFile}
              uploadedFile={uploadedFile}
              libraryType={libraryType}
              revision={initialValue}
            />

          </DialogContent>
          <DialogActions sx={{ m: 0, p: 3 }}>
            <Button
              onClick={handleSubmit(onSaveClicked)}
              className="ml-4 mr-3 w-32"
              variant="contained"
            >
              Save
            </Button>
            <Button onClick={onCancelClick} className="w-32">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default DocumentRevisionDialog;
