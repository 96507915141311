import { FC } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { Accordion, AccordionDetails, AccordionSummary, FormGroup } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Checkbox from 'src/components/UI/Forms/Checkbox';
import DocumentRevisions from 'src/components/PageDrawer/Documentation/components/DocumentRevisions';
import RichTextEditor from '../../../UI/Forms/RichTextEditor';
import { useAppState } from 'src/contexts/app-state';
import CategoryDropdown from 'src/components/Dropdowns/CategoryDropdown';
import { LIBRARY } from 'src/consts';
import { DocumentationLibType, RecordType } from 'src/generated/dotnet.graphql';

interface DocumentationSummaryFormProps {
  documentationId: string;
  onChange: (field: string, value: any) => void;
  control: Control;
  readOnly: boolean;
  isCreate: boolean;
  recordType: RecordType;
  libraryType: DocumentationLibType;
  watch: UseFormWatch<any>;
}

const DocumentationSummaryForm: FC<DocumentationSummaryFormProps> = ({
  documentationId,
  onChange,
  control,
  recordType,
  libraryType,
  isCreate,
  readOnly,
}) => {
  const { settingsPersonal } = useAppState();

  return (
    <div className="mt-3">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="general-information"
          id="general-information"
        >
          General Information
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col">
            <div className="mt-3">
              <CategoryDropdown
                name="categoryId"
                label="Category"
                control={control}
                onChange={onChange}
                recordType={recordType}
                rules={{ required: true }}
                allDepts={settingsPersonal?.fldAllDepts > 0}
              />
            </div>

            {(libraryType === LIBRARY.SMS.TYPE || libraryType === LIBRARY.ISPS.TYPE)  &&
              <FormGroup row className="mt-4">
                <div className="ml-5 w-40">
                  <Checkbox
                    control={control}
                    name="isReport"
                    label="This is a form"
                  />
                </div>

                <div className="ml-5 w-60">
                  <Checkbox
                    control={control}
                    name="requiresVerification"
                    label="Requires Verification"
                  />
                </div>
              </FormGroup>
            }
          </div>

          <div className="mt-4">
            <RichTextEditor
              control={control}
              name="description"
              onChange={onChange}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="planning-status"
          id="planning-status"
        >
          Revision History
        </AccordionSummary>
        <AccordionDetails>
          <DocumentRevisions
            libraryType={libraryType}
            documentationId={documentationId}
            isCreate={isCreate}
            readOnly={readOnly}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DocumentationSummaryForm;
