// This group of imports should be in every data detail component
import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
import { v4 as uuid } from 'uuid';
import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import {
  isNil, get, size, last, omit, isArray, isEmpty, isString,
} from 'lodash';

// Third party components go here
// check here for @mui/icon-materials values https://mui.com/components/material-icons/
import { DeleteTwoTone } from '@mui/icons-material';
import { Alert, IconButton, Snackbar } from '@mui/material';

import { useForm } from 'react-hook-form';
import { Subscription } from 'rxjs';
import { LogEntryDocument } from 'src/pages/LogEntryPage/rxdb';
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';
import Tabs from '../../UI/Tabs';
import StickyAppBar from '../../UI/StickyAppBar';
import WarningDialog from 'src/components/UI/WarningDialog';
import { useAppState } from 'src/contexts/app-state';

// images

// Reusable module components go here
import { getDatabase } from '../../../rxdb';

// All module specific components go here
import { LogEntry, TblSchedChk } from '../../../generated/graphql';
import { updateSpares, validateFormOld } from './utilsOld';
import { getPlainTextFromHtml, toJSON } from 'src/utils';
import { TblSparesUsedDocument } from '../../SparesTab/rxdb';
import { useAuth } from 'src/contexts/auth';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import { normalizeDateTime, normalizeDateFormValue } from 'src/helpers';
import { InjectedDrawerProps } from 'src/components/PageDrawer';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import LogEntryEquipmentHoursDialog from './components/LogEntryEquipmentHoursDialog';
import SparesTabOld from 'src/components/SparesTab/indexOld';
import LogEntrySummaryFormOld from './components/LogEntrySummaryFormOld';
import AttachmentTab from 'src/modules/Attachments';
import { AttachmentType, RecordType } from 'src/generated/dotnet.graphql';
import Comments from 'src/modules/Comments';

interface Props extends Partial<InjectedDrawerProps> {
  initialValue: LogEntryDocument;
  onCancel: () => void;
  onSave: (item: LogEntry, isCreated: boolean) => void;
  onDelete: (item: LogEntryDocument) => void;
  onUndo?: () => void;
  editFlag: boolean;
  isCreated?: boolean;
  type?: string;
  isReport?: boolean;
  isLogAndReset?: boolean;
}

const LogEntryDetailFormOld: FC<Props> = ({
  initialValue,
  onCancel,
  onSave,
  onDelete,
  onUndo,
  editFlag = false,
  isCreated = false,
  type,
  setFormIsDirty,
  isReport = false,
  isLogAndReset = false,
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState,
  } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      LogEntry: initialValue.LogEntry || '',
      LogDate: normalizeDateFormValue(initialValue.LogDate),
      Equipment: initialValue?.Equipment || null,
      fldSRHKey: initialValue.fldSRHKey,
      fldLocHierarchy: initialValue.fldLocHierarchy,
      fldWorkList: initialValue?.fldWorkList || null,
      Department: initialValue?.Department || null,
      fldEnteredBy: initialValue?.fldEnteredBy || null,
      fldPerformedBy: isEmpty(initialValue.fldPerformedBy)
      ? []
      : initialValue.fldPerformedBy?.split(', '),
      fldCompany: initialValue?.fldCompany || null,
      fldTime: initialValue?.fldTime || null,
      fldTimeQty: initialValue?.fldTimeQty || null,
      fldCost: initialValue?.fldCost || 0,
      Curr: initialValue?.Curr || null,
      fldIsWarranty: initialValue?.fldIsWarranty || false,
      fldHours: initialValue?.fldHours || null,
      fldHTML: initialValue?.fldHTML || initialValue.LogEntry || '' ,
      EqKey: initialValue.EqKey || null,
      fldCrewID: initialValue?.fldCrewID || null,
      fldRestricted: initialValue?.fldRestricted || false,
      fldSMS: initialValue.fldSMS || false,
    },
  });

  const oldItemState = useRef<LogEntry>(initialValue.toJSON());
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const { user } = useAuth();
  const { settingsPersonal } = useAppState();
  const [oldItemUndo, setOldItemUndo] = useState<LogEntry[]>([]);
  const [item, setItem] = useState<LogEntry>(initialValue.toJSON());
  const [attachmentCount, setAttachmentCount] = useState<number>(0);
  const [spareCount, setSpareCount] = useState<number>(0);
  const [spares, setSpares] = useState<TblSparesUsedDocument[]>([]);
  const [isSparesLoading, setSparesLoading] = useState<boolean>(false);
  const [isCompleteLoading, setCompleteLoading] = useState<boolean>(false);
  const activeSubscriptions = useRef<Subscription[]>([]);
  const formInitialValues = useRef<any>({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [disableEditDepartment, setDisableEditDepartment] = useState(false);
  const [eqWarningDialog, setEqWarningDialog] = useState(false);
  const [saveData, setSaveData] = useState<any>();
  const [eqHours, setEqHours] = useState<number>(0);
  const [logEqHours, setLogEqHours] = useState<number>(0);

  const [documentsCount, setDocumentsCount] = useState<number>();
  const [photosCount, setPhotosCount] = useState<number>();
  const [commentsCount, setCommentsCount] = useState<number>();

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const getDocumentCount = async () => {
    // Count Attachments / Photos / Spares
    const db = await getDatabase();
    setSparesLoading(true);
    // Find and count attachments
    activeSubscriptions.current = [
      db.tblsparesused
        .find({
          selector: {
            LogKey: get(initialValue, 'PKey', ''),
            deletedAt: {$eq: null},
          },
        })
        .$.subscribe((spares) => {
          setSpareCount(size(spares));
          setSpares(spares || []);
          setSparesLoading(false);
        }),
    ];
  };

  const setInitialValues = async () => {
    // const department = await initialValue.populate("Department");
    // const worklist = await initialValue.populate('fldWorkList');
    // const equipment = await initialValue.populate("EqKey");
    const category = await initialValue.populate('fldSRHKey');

    const defaultValues = {
      ...getValues(),
      // fldSRHKey: category?.toJSON() || null, // avoid infinite loop.
      // fldWorkList: worklist?.toJSON() || null,
      // Department: department?.toJSON() || null,
      // Equipment: equipment?.toJSON() || null,
      fldLocHierarchy: initialValue.fldLocHierarchy || null,
    };

    formInitialValues.current = defaultValues;
    reset(defaultValues);
  };

  const setRecordEditPermission = async ()=>{
    if(settingsPersonal.fldAllDepts === 1 && user?.Department != initialValue.Department){
      setDisableEdit(true)
    }
    if(settingsPersonal.fldAllDepts != 2){
      setDisableEditDepartment(true)
    }
  }

  useEffect(()=>{
    if(settingsPersonal){
      setRecordEditPermission()
    }
  },[settingsPersonal])

  useEffect(() => {
    getDocumentCount();
    setInitialValues();

    return () => {
      activeSubscriptions.current?.map((sub) => sub.unsubscribe());
      activeSubscriptions.current = [];
      formInitialValues.current = {};
    };
  }, []);

  const onChange = async (name: string, value: any) => {
    try {
      // If it's equipment change -> populate location based on it.
      if (name === 'Equipment') {
        const equipment = value as EquipmentDocument;
        setValue('fldLocHierarchy', equipment?.fldLocHierarchy);
        setValue(name, equipment.UniqueName, { shouldDirty: true })
        return;
      }
      let shouldDirty = true;

      if (name === 'fldSRHKey') {
        const updatedValue = (isArray(value) ? last(value) : value) || null;
        if (initialValue.fldSRHKey === updatedValue) {
          shouldDirty = false;
        }
      }

      if (name === 'fldLocHierarchy') {
        const updatedValue = (isArray(value) ? last(value) : value) || null;
        if (initialValue.fldLocHierarchy === updatedValue) {
          shouldDirty = false;
        }
      }
      setValue(name, value, { shouldDirty: shouldDirty });
    } catch (error) {
    }
  };

  const handleCancel = () => {
    setOldItemUndo([]);
    onCancel();
  };

  const getEquipmentValue = (item: any) => {
    switch (typeof item) {
      case 'string':
        return item;
      case 'object':
        return item?.UniqueName
      default:
        return null
    }
  }

  const getEquipmentKeyValue = async (item: any) => {
    const db = await getDatabase();
    const eqkey = await db.equipment.find({
      selector:{
        UniqueName: {$eq: item}
      }
    }).exec();
    const key = toJSON(eqkey)[0]?.EqKey;
    return key
  }

  const isCreation = isCreated;

  const onSaveClick = async (data: any)=>{
    const db = await getDatabase();
    if(data.Equipment){
      const dataEqKey = initialValue.EqKey ? initialValue.EqKey:await getEquipmentKeyValue(data.Equipment)
      const equipmentData = await db.equipment.find({
        selector:{
          EqKey: {$eq: dataEqKey }
        }
      }).exec()
      if(equipmentData[0].fldCountHours && isNil(data.fldHours)){
        setEqHours(equipmentData[0].Hours || 0)
        setSaveData(data)
        setEqWarningDialog(true)
      } else {
        handleSave(data)
      }
    } else {
      handleSave(data)
    }
  }

  const onChangeHours = async (value:any)=>{
    if(parseInt(value)>0){
      setLogEqHours(parseInt(value))
      setValue('fldHours',value) // TODO: Does not setting up any field here - investigate before removing
    }
  }

  const onContinueAnyWay = async()=>{
    // TD-1649: Set fldHours from LogEntry table with input from user or if it's 0 then with eqHours (equipment hours)
    if(logEqHours>0) {
      const updatedSaveDataWithInputHours = {...saveData, fldHours: logEqHours} 
      handleSave(updatedSaveDataWithInputHours)
    } else {
      const updatedSaveDataWithEqHours = {...saveData, fldHours: eqHours}
      handleSave(updatedSaveDataWithEqHours)
    }
    setEqWarningDialog(false)
  }

  const onEnterEqHours = async()=>{
    setSaveData(null)
    setEqWarningDialog(false)
  }


  const handleSave = async (data: any) => {
    if (!validateFormOld(data, setSnackbar)) return;

    const db = await getDatabase();

    // Create items before creating Issue.
    const getOrCreate = async (value: any, keyExpr: string) => {
      if (isNil(value)) return null;

      if (value.isCreate) {
        // Create item first and then proceed
        const collection = (db as any)[value.collection];

        // TODO: Hook up tblDefaults
        const result = await collection?.upsert(
          omit(value, ['inputValue', 'isCreate', 'collection']),
        );

        return result[keyExpr];
        // create value collection
      }

      return value[keyExpr];
    };

    const {
      LogEntry,
      LogDate,
      EqKey,
      fldSRHKey,
      fldLocHierarchy,
      fldWorkList,
      Department,
      Equipment,
      fldHours,
      fldEnteredBy,
      fldPerformedBy,
      fldCompany,
      fldTime,
      fldTimeQty,
      fldCost,
      Curr,
      fldIsWarranty,
      fldHTML,
      fldCrewID,
      fldRestricted,
      fldSMS,
    } = data;

    const document = {
      ...item,
      LogEntry: getPlainTextFromHtml(fldHTML),
      LogDate: LogDate ? normalizeDateTime(LogDate) : null,
      EqKey: await getEquipmentKeyValue(Equipment),
      fldSRHKey: (isArray(fldSRHKey) ? last(fldSRHKey) : fldSRHKey) || null,
      fldLocHierarchy:
        (isArray(fldLocHierarchy) ? last(fldLocHierarchy) : fldLocHierarchy)
        || null,
      // fldWorkList: await getOrCreate(fldWorkList, 'PKey'),
      fldWorkList: isString(fldWorkList)
      ? fldWorkList
      : fldWorkList?.fldMember || null ,
      Department: Department ? typeof Department === 'object' ? Department.fldMember : Department : null,
      fldEnteredBy,
      fldPerformedBy: fldPerformedBy?.join(', ') || null,
      Equipment: getEquipmentValue(Equipment),
      fldHours: fldHours ? parseInt(fldHours) : null,
      fldCompany: fldCompany?.DisplayMember || fldCompany || null,
      fldTime: fldTime ? parseInt(fldTime, 10) : 0,
      fldTimeQty,
      fldCost: parseInt(fldCost) || 0,
      fldRestricted,
      fldSMS,
      DayLogType: 1,
      Curr: Curr?.Curr || null,
      fldIsWarranty,
      fldCrewID: fldCrewID || user?.fldCrewID,
      // Department: Department?.PKey || null,
      // Since we are passing empty object from parent we should distinguish create/update actions.
      // In case of Update we have to pass primary key (PKey)
      PKey: initialValue.primary || uuid(), // Set primary key, so we will be able to upsert.
      updatedAt: new Date().toISOString(),
      fldHTML
    } as any;

    try {

      if (isLogAndReset) {
        await updateSpares(spares, db, document);
      }

      setOldItemUndo([]);
      const res = await db.collections.logentry.upsert(document);

      onSave(res, isCreated);
      const dataUpdate: any = getValues();

      dataUpdate.Equipment = getEquipmentValue(Equipment);
      reset(dataUpdate);
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };

  const handleDelete = () => {
    setIsDeleting(true)
  };

  const handleDeleteOk = () =>{
    onDelete(initialValue);
    setIsDeleting(false);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
  }

  const handleUndo = () => {
    const issue = last(oldItemUndo);
    setItem(issue as LogEntry);

    // Remove last step from our store
    setOldItemUndo(oldItemUndo.splice(-1));

    onUndo && onUndo();
  };

  const handleCancelUndo = () => {
    // setItem(initialValue);

    if (isCreation) {
      return onCancel();
    }
    reset(formInitialValues.current);
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateFormOld(getValues(), setSnackbar) && !disableEdit) return;
    if (isEditing && !disableEdit) return; // We will send submit action that will be handled in HandleSave.

    handleCancel();
  };

  // Save

  // Delete - should just place the pkey in tblRecycle then row should
  // be remove from our list, unless tblSchedMaintKey not null OR fldSMSID not null

  if (isNil(item)) return null;

  const hasValuesBeenChanged = formState.isDirty
    && (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);
  const isEditing = hasValuesBeenChanged || isCreation;

  useEffect(() => {
    setFormIsDirty && setFormIsDirty(hasValuesBeenChanged);
  }, [hasValuesBeenChanged]);
  
  const formClass = type === 'Dialog'
    ? 'relative bg-white flex-grow'
    : 'relative bg-white pt-14 md:pt-19 flex-grow';

  let checks: TblSchedChk[] = [];
  if(initialValue.Checks !== undefined && initialValue.Checks !== null) {
    checks = JSON.parse(initialValue.Checks);
    checks.sort((a, b) => { 
      if (a.fldTitle && b.fldTitle) {
       return a.fldTitle.localeCompare(b.fldTitle);
      }
      return 0;
    });
  }
  
  const columns = [
    {
      name: "fldTitle",
      header: "Check Title",
      flex: 1,
    }
  ]

  return (
    <form
      id="logentry-details-form"
      className={`${formClass}`}
      onSubmit={handleSubmit(onSaveClick)}
    >
      <div className="bg-white h-full flex-grow pt-6">
        <div className="px-6 h-full">
          <div className="mb-6">
            <h3 className="text-xl font-bold">Log Entry Form</h3>
          </div>
          {(disableEdit || editFlag) && (
              <RecordEditWarningCard />
            )}
          <div className="mt-3 mb-20">
            <Tabs
              tabs={[
                {
                  label: 'Summary',
                  component: (
                    <LogEntrySummaryFormOld
                      watch={watch}
                      control={control}
                      initialValue={initialValue}
                      onChange={onChange}
                      isNotCompletion
                      disableEdit = {disableEdit}
                    />
                  ),
                },
                ...(initialValue.fldWorkList === 'Check List'
                ? [
                    {
                      label: `Check List (${checks.length})`,
                      component: (
                        <div className="pt-5">
                          <div className="flex justify-end mb-6">
                            <LicensedReactDataGrid
                              showEmptyRows={true}
                              livePagination
                              rowHeight={40}
                              idProperty="PKey"
                              showHoverRows={false}
                              columns={columns}
                              dataSource={checks}
                            />
                          </div>
                        </div>
                      ),
                    },
                  ]
                : []),
                {
                  label: `Spares (${spareCount})`,
                  component: (
                    <SparesTabOld
                      loading={isSparesLoading}
                      logEntry={initialValue}
                      spares={spares}
                      isLogentry={true}
                      isReport={isReport}
                      disableEdit={disableEdit}
                    />
                  ),
                },
                {
                  // label: documentsCount === undefined ? 'Attachments' : `Attachments (${documentsCount})`,
                  label: `Attachments`,
                  disabled: isCreated,
                  component: (
                    <AttachmentTab
                      recordId={initialValue.PKey}
                      recordType={RecordType.LogEntry}
                      recordTypeName={'LogEntry'}
                      attachmentType={AttachmentType.Document}
                      categoryId={initialValue.fldSRHKey}
                      setAttachmentsCount={setDocumentsCount}
                      readOnly={editFlag || disableEdit} 
                    />
                  ),
                },
                {
                  // label: `Photos (${photosCount})`,
                  label: `Photos`,
                  disabled: isCreated,
                  component: (
                    <AttachmentTab
                      recordId={initialValue.PKey}
                      recordType={RecordType.LogEntry}
                      recordTypeName={'LogEntry'}
                      attachmentType={AttachmentType.Photo}
                      categoryId={initialValue.fldSRHKey}
                      setAttachmentsCount={setPhotosCount}
                      readOnly={editFlag || disableEdit} 
                    />
                  ),
                },
                {
                  // label: `Comments (${commentsCount})`,
                  label: `Comments`,
                  disabled: isCreated,
                  component: (
                    <Comments
                      recordId={initialValue.PKey}
                      recordType={RecordType.LogEntry}
                      recordTypeName={'LogEntry'}
                      setCommentsCount={setCommentsCount}
                      readOnly={editFlag || disableEdit}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>

      {type !== 'Dialog' && (
        <StickyAppBar
          cancelText="Cancel"
          okType={isEditing ? 'submit' : 'button'}
          okText={isEditing ? 'Save' : 'Close'}
          onOk={() => handleOk(isEditing)}
          disabled={(editFlag || disableEdit) && isEditing}
          onCancel={isEditing ? () => handleCancelUndo() : undefined}
        >
          {!editFlag && !isNil(item.PKey) && !disableEdit && (
            <IconButton
              onClick={handleDelete}
              color="error"
              aria-label="Delete item"
            >
              <DeleteTwoTone />
            </IconButton>
          )}
        </StickyAppBar>
      )}

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        color="error"
        okText='Yes'
        content="Are you sure you wish to delete record?"
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
      {/* <WarningDialog
        visible={eqWarningDialog}
        title="Empty equipment hours"
        content="This Equipment monitor hours, you have not enter any hours for this equipment. Please selecte option to continue."
        okText='Enter Equipment Hours'
        cancelText='Continue anyway'
        color='warning'
        onOk={onEnterEqHours}
        onCancel={onContinueAnyWay}
      /> */}
      <LogEntryEquipmentHoursDialog
        visible={eqWarningDialog}
        okText='Cancel'
        cancelText='Continue'
        color='warning'
        onOk={onEnterEqHours}
        onCancel={onContinueAnyWay}
        equipmentHours={eqHours}
        onChange={onChangeHours}
        eqHoursCompletedAt={logEqHours}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default LogEntryDetailFormOld;

