import { ApolloError, gql, useMutation } from '@apollo/client';
import { Attachment } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_DOCUMENT_RELATED_ITEMS } from './useGetDocumentRelatedItems';

export const DELETE_DOCUMENT_RELATED_ITEM = gql`
  mutation DeleteDocumentRelatedItem($input: AttachmentCrossReferenceInput!) {
    deleteAttachmentFromRecord(input: $input) {
      attachment {
        documentationId
        id
        recordId
        recordType
      }
    }
  }
`;

interface DocumentRelatedItemMutationResponse {
  responseData?: Attachment;
  responseMessage: string;
}

interface DeleteDocumentRelatedItemResult {
  removeDocumentRelatedItem: (documentationId: string, recordId: string) => Promise<DocumentRelatedItemMutationResponse>;
  removeDocumentRelatedItemLoading: boolean;
}

export const useDeleteDocumentRelatedItem = (): DeleteDocumentRelatedItemResult => {
  const [deleteDocumentRelatedItem, { loading }] = useMutation(DELETE_DOCUMENT_RELATED_ITEM, {
    onError: (error: ApolloError) => {
      logger('DeleteDocumentRelatedItem').error('Error deleting document related item -->', error.message);
    }
  });

  const removeDocumentRelatedItem = async (documentationId: string, recordId: string): Promise<DocumentRelatedItemMutationResponse> => {
    const response = await deleteDocumentRelatedItem({ 
      variables: { 
        input: {
          documentationId,
          recordId
        } 
      },
      refetchQueries: [{
        query: GET_DOCUMENT_RELATED_ITEMS,
        variables: { documentationId }
      }],
    });
    const responseData = response.data?.deleteAttachmentFromRecord?.attachment;
    if (responseData) {
      logger('DeleteDocumentRelatedItem').info('Document related item deleted successfully', response.data);
      return {
        responseData,
        responseMessage: `Document related item removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove document related item!`,
      };
    }
  };

  return { 
    removeDocumentRelatedItem, 
    removeDocumentRelatedItemLoading: loading, 
  };
};
