import { gql } from '@apollo/client';

export const UpdateLogEntryDocumentCountCache = gql`
  fragment UpdateLogEntryDocumentCountCache on LogEntry {
    documentsCount
  }
`;
export const UpdateLogEntryPhotoCountCache = gql`
  fragment UpdateLogEntryPhotoCountCache on LogEntry {
    photosCount
  }
`;
export const UpdateLogEntryCommentsCountCache = gql`
  fragment UpdateLogEntryCommentsCountCache on LogEntry {
    commentsCount
  }
`
export const UpdateDocumentationCommentsCountCache = gql`
  fragment UpdateDocumentationCommentsCountCache on Documentation {
    commentsCount
  }
`
export const UpdateLogEntrySparesUsedCountCache = gql`
  fragment UpdateLogEntrySparesUsedCountCache on LogEntry {
    sparesUsedCount
  }
`