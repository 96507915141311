import { FC, useEffect, useState } from 'react';
import { isNil, isArray, last } from 'lodash';
import { Control, RegisterOptions } from 'react-hook-form';
import Autocomplete from 'src/components/UI/Forms/Autocomplete';
import { useGetEquipmentList } from 'src/hooks/equipment/useGetEquipmentList';
import { Equipment, EquipmentFilterInput } from 'src/generated/dotnet.graphql';

type EquipmentProps = {
  control: Control;
  onChange: (field: string, value: any) => void;
  disabled?: boolean;
  defaultValue?: string | null;
  label?: string;
  category?: string | null;
  rules?: RegisterOptions
};

const EquipmentDropdown: FC<EquipmentProps> = ({
  control,
  onChange,
  disabled,
  defaultValue,
  label,
  category,
  rules
}) => {
  const [equipmentList, setEquipmentList] = useState<Equipment[]>([]);
  const [filterValue, setFilterValue] = useState<EquipmentFilterInput>();

  useEffect(() => {
    const updateFilterValue = () => {
      const updatedCategory = isArray(category) ? last(category) : category;
  
      if (!isNil(updatedCategory) || !isNil(defaultValue)) {
        const selector: EquipmentFilterInput = {
          categoryId: updatedCategory ? updatedCategory : undefined,

        };
        setFilterValue(selector);
      } else {
        setFilterValue(undefined);
      }
    };
  
    updateFilterValue();
  }, [category, defaultValue]);

  const { getEquipmentList, loading } = useGetEquipmentList(filterValue);

  useEffect(() => {
    const fetchEquipment = async () => {
      const queryResult = await getEquipmentList({ 
        variables: { 
          filterInput: filterValue 
        } 
      });
      const data = queryResult?.data?.equipment.items || [];
      setEquipmentList(data);
    }

    fetchEquipment();
  }, [filterValue]);

  return (
    <Autocomplete
      disabled={disabled}
      displayExpr="uniqueName"
      onChange={onChange}
      loading={loading}
      label={label}
      groupBy={(o: any) => o.group}
      options={equipmentList}
      control={control}
      name="equipment"
      keyExpr="id"
      rules={rules}
    />
  );
};

export default EquipmentDropdown;
