import { gql, useQuery } from '@apollo/client';
import { Location } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_LOCATIONS = gql`
  query GetLocations {
    locations {
      id
      depts
      location
      noView
      tags
      parentId
    }
  }
`;

interface LocationsResult {
  data: Location[];
  loading: boolean;
}

export const useGetLocations = (): LocationsResult => {
  const { data, loading, error } = useQuery(GET_LOCATIONS, {
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetLocations').error('Error fetching locations -->', error.message);
  }

  return {
    data: data?.locations || [],
    loading,
  };
};
