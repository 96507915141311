import { gql, useQuery  } from "@apollo/client";
import { Inventory } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_INVENTORY_FOR_EQUIPMENT = gql`
  query GetInventoryForEquipment($equipmentId: String!) {
    inventoryForEquipment(equipmentId: $equipmentId) {
      id
      productName
      manufacturer
      partNumber
    }
  }
`

interface InventoryForEquipmentResult {
  inventoryForEquipment: Inventory[];
  inventoryForEquipmentLoading: boolean;
  inventoryForEquipmentCount: number;
}

export const useGetInventoryForEquipment = (equipmentId: string | undefined): InventoryForEquipmentResult => {

  if (!equipmentId) {
    return {
      inventoryForEquipment: [],
      inventoryForEquipmentLoading: false,
      inventoryForEquipmentCount: 0
    };
  }

  const { data, loading, error } = useQuery(GET_INVENTORY_FOR_EQUIPMENT, {
    variables: { equipmentId },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetInventoryForEquipment').error('Error fetching spares-inventory for equipment -->', error.message);
  }

  return {
    inventoryForEquipment: data?.inventoryForEquipment || [],
    inventoryForEquipmentLoading: loading,
    inventoryForEquipmentCount: data?.inventoryForEquipment.length || 0
  };
};

  