import { ApolloError, gql, useMutation } from '@apollo/client';
import { logger } from 'src/helpers/logger';
import { DocumentRevisionMutationResponse } from './useUpsertDocumentRevision';
import { GET_DOCUMENT_REVISIONS } from './useGetDocumentRevisions';


export const DELETE_DOCUMENT_REVISION = gql`
  mutation DeleteDocumentRevision($documentRevisionId: String!) {
    deleteDocumentRevision(documentRevisionId: $documentRevisionId) {
      documentRevision {
        id
        deletedAt
        deletedBy
      }
    }
  }
`;

interface DeleteDocumentRevisionResult {
  removeDocumentRevision: (documentRevisionId: string, documentationId: string) => Promise<DocumentRevisionMutationResponse>;
  removeDocumentRevisionLoading: boolean;
}

export const useDeleteDocumentRevision = (): DeleteDocumentRevisionResult => {
  const [deleteDocumentRevision, { loading }] = useMutation(DELETE_DOCUMENT_REVISION, {
    onError: (error: ApolloError) => {
      logger('DeleteDocumentRevision').error('Error deleting document revision -->', error.message);
    }
  });

  const removeDocumentRevision = async (documentRevisionId: string, documentationId: string): Promise<DocumentRevisionMutationResponse> => {
    const response = await deleteDocumentRevision({ 
      variables: { documentRevisionId },
      refetchQueries: [{
        query: GET_DOCUMENT_REVISIONS,
        variables: { documentationId }
      }],
    });
    const responseData = response.data?.deleteDocumentRevision?.documentRevision;
    if (responseData) {
      logger('DeleteDocumentRevision').info('Document revision deleted successfully', response.data);
      return {
        responseData,
        responseMessage: `Document revision removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove document revision!`,
      };
    }
  };

  return { 
    removeDocumentRevision, 
    removeDocumentRevisionLoading: loading, 
  };
};
