import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { logger } from "src/helpers/logger";
import { LogEntryData } from "./useGetLazyLogEntry";

export const GET_LOG_ENTRY_COUNTS = gql`
  query GetLogEntryCounts($logEntryId: String!) {
    logEntry(logEntryId: $logEntryId) {
      __typename
      id
      photosCount
      documentsCount
      sparesUsedCount
      commentsCount
    }
  }
`

interface LogEntryResult {
    getLogEntryCounts: LazyQueryExecFunction<LogEntryData, OperationVariables>;
}

export const useGetLogEntryCounts = (): LogEntryResult => {

  const [ getLogEntry, { error }] = useLazyQuery(GET_LOG_ENTRY_COUNTS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLogEntryCounts').error('Error fetching log entry -->', error.message);
  }

  return {
    getLogEntryCounts: getLogEntry
  };
};
  