// React components
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm  } from 'react-hook-form';

// MUI components
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Portal, Snackbar } from '@mui/material';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

// Data
import { getDatabase } from 'src/rxdb';
import { TblDdListDefaultsDocument } from 'src/api/queries/tblDDListDefaults/rxdb';

// TDI components
import { CHAR_LIMIT, UpdateRecordsOptionsTypeOld } from 'src/consts';
import Input from 'src/components/UI/Forms/Input';
import LocationDropdownOld from 'src/components/Dropdowns/LocationDropdown/indexOld';
import CategoryDropdownOld from 'src/components/Dropdowns/CategoryDropdown/indexOld';
import Autocomplete from 'src/components/UI/Forms/Autocomplete';

// Utils
import { handleCharLimitWarning, toJSON } from 'src/utils';
import { last } from 'lodash';
import Select from 'src/components/UI/Forms/Select';
import CompaniesDropdownOld from 'src/components/Dropdowns/CompaniesDropdown/indexOld';

interface InjectedProps {
  visible: boolean;
  recordsNumber?: number;
  options: UpdateRecordsOptionsTypeOld,
  onCancel?: () => void;
  onSubmit: (payload: any) => void;
  saveLoading: boolean;
}

const booleanOptions = ['True', 'False']

const UpdateRecordPopupOld: FC<InjectedProps> = ({
  visible,
  recordsNumber,
  options,
  onCancel,
  onSubmit,
  saveLoading,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
  } = useForm<any>({
    mode: 'onBlur',
    shouldFocusError: true,
  });

  const [alert, setAlert] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [reportingType, setReportingType] = useState<TblDdListDefaultsDocument[]>([]);
  const [departments, setDepartments] = useState<TblDdListDefaultsDocument[]>([]);
  const { EQUIPMENT } = CHAR_LIMIT;

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const getDepartments = async () => {
    const db = await getDatabase();
  
    db.tblddlistdefaults.find({ 
      selector: { 
        deletedBy: { $eq: null }, 
        fldListName: 'Department' 
      } 
    }).$.subscribe((departments: any) => {
      setDepartments(toJSON(departments));
    });
  };
  
  const getReportingTypes = async () => {
    const db = await getDatabase();
  
    db.tblddlistdefaults.find({ 
      selector: { 
        deletedBy: { $eq: null }, 
        fldListName: 'Reporting Type', 
        fldMember: { $ne: "" } 
      } 
    }).$.subscribe((reportingTypes: any) => {
      setReportingType(toJSON(reportingTypes));
    });
  };
  
  useEffect(() => {
    if (selectedOption === options.DEPARTMENT) {
      getDepartments();
    } else if (selectedOption === options.REPORTING_TYPE) {
      getReportingTypes();
    }
  }, [selectedOption]);

  const onChange = async (name: string, value: any) => {
    let shouldDirty = true;
    if (name === 'option') {
      setSelectedOption(value)
    }
    setValue(name, value, { shouldDirty: shouldDirty });
  };
  
  const onSaveClicked = async (data: any) => {
    let value;

    switch (data.option.fieldName) {
      case 'Manufacturer':
      case 'Supplier':
        value = data[data.option.fieldName].id;
        break;
      case 'fldSRHKey':
      case 'fldLocHierarchy':
        value = last(data[data.option.fieldName]);
        break;
      case 'Department':
      case 'fldReportingType':
        value = data[data.option.fieldName].fldMember;
        break;
      case 'fldRestricted':
      case 'fldCountHours':
      case 'fldSMS':
        value = data[data.option.fieldName] === 'True' ? true : false;
        break;
      default:
        value = data[data.option.fieldName];
        break;
    }

    const payload = {
      fieldName: data.option.fieldName,
      columnName: data.option.columnName,
      tableName: data.option.tableName,
      value: value,
    }

    onSubmit(payload);
    setSelectedOption(null);
    reset();
  };

  const onCancelClick = () => {
    reset();
    setAlert(false);
    setSelectedOption(null);
    onCancel && onCancel();
  };

  const renderListDefaultBox = useMemo(() => (items: TblDdListDefaultsDocument[], fieldName: string, fldListName: string, freeSolo = false) => {
    const sortedData = items?.sort((a: any, b: any) => a.fldMember.localeCompare(b.fldMember));
    return (
      <Autocomplete
        defaultPayload={{
          fldListName,
          fldT4Reqd: true,
        }}
        displayExpr="fldMember"
        keyExpr="PKey"
        onChange={onChange}
        freeSolo={freeSolo}
        options={sortedData}
        label={fldListName}
        control={control}
        name={fieldName}
        collection="tblddlistdefaults"
      />
    );
  }, [control, onChange]);

  return (
    <div>
      <form
        id="updateRecords-popup"
        className="relative bg-white flex-grow"
        onSubmit={handleSubmit(onSaveClicked)}
      > 
        <Dialog
          open={visible}
          onClose={onCancelClick}
          fullWidth
          maxWidth="md"
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle>
            <span className="font-bold text-2xl">
              Update Records Utility
            </span>
            {onCancel ? (
              <IconButton
                aria-label="close"
                onClick={onCancelClick}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 14,
                  color: (theme) => theme.palette.grey[400],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent dividers>
              <div className="bg-white h-full flex-grow">
                <div className="h-full">
                  <div className="mt-6 mb-4" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <span className="font-semibold text-gray-600 text-sm">
                      {`Which field would you like to update for the selected Records?`}
                    </span>
                  </div>
                  <div className="mb-6">
                    <div className="mb-4">
                      <Autocomplete
                        label='Column Name'
                        placeholder='Select Column Name'
                        control={control} 
                        name='option' 
                        options={Object.values(options)} 
                        onChange={onChange} 
                        displayExpr='columnName' 
                        freeSolo={true}
                        keyExpr=''                        
                      />
                    </div>
                  </div>
                  {selectedOption && (
                    <div className="mt-6 mb-4" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                      <span className="font-semibold text-gray-600 text-sm">
                        {selectedOption === options.MODEL_NUMBER
                          ? `Enter the new "${(selectedOption as { columnName: string }).columnName}"`
                          : `Select the new "${(selectedOption as { columnName: string }).columnName}"`
                        }
                      </span>
                    </div>
                  )}
                  <div className="mb-6">
                    {selectedOption === options.MANUFACTURER && (
                      <div className="mb-4">
                        <CompaniesDropdownOld
                          control={control}
                          label="Manufacturer"
                          name="Manufacturer"
                          onChange={onChange}
                          size="small"
                        />
                      </div>
                    )}
                    {selectedOption === options.MODEL_NUMBER && (
                      <div className="mb-4">
                        <Input
                          inputProps={{
                            size: 'small',
                            label: 'Model Number',
                          }}
                          rules={{ maxLength: EQUIPMENT.ModelNumber }}
                          warning={(value) => handleCharLimitWarning(value, EQUIPMENT.ModelNumber)}
                          control={control}
                          name="ModelNumber"
                        />
                      </div>
                    )}
                    {selectedOption === options.CATEGORY && (
                      <div className="mb-4">
                        <CategoryDropdownOld
                          name="fldSRHKey"
                          label="Category"
                          control={control}
                          onChange={onChange}
                          sourceForm="updateRecords-popup"
                        />
                      </div>
                    )}
                    {selectedOption === options.LOCATION && (
                      <div className="mb-4">
                        <LocationDropdownOld
                          control={control}
                          label="Location"
                          name="fldLocHierarchy"
                          onChange={onChange}
                        />
                      </div>
                    )}
                    {selectedOption === options.DEPARTMENT && (
                      <div className="mb-4">
                        {renderListDefaultBox(departments, 'Department', 'Department', true)}
                      </div>
                    )}
                    {selectedOption === options.RESTRICTED_TO_HOD && (
                      <div className="mb-4" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Select
                          control={control}
                          name="fldRestricted"
                          label="Restricted to HoD"
                          options={booleanOptions}
                        />
                      </div>
                    )}
                    {selectedOption === options.REQUIRED_BY_SMS && (
                      <div className="mb-4" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Select
                          control={control}
                          name="fldSMS"
                          label="Critical Equipment (SMS Required)"
                          options={booleanOptions}
                        />
                      </div>
                    )}
                    {selectedOption === options.SUPPLIER && (
                      <div className="mb-4">
                        <CompaniesDropdownOld
                          control={control}
                          label="Supplier"
                          name="Supplier"
                          onChange={onChange}
                        />
                      </div>
                    )}
                    {selectedOption === options.MONITOR_HOURS && (
                      <div className="mb-4" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Select
                          control={control}
                          name="fldCountHours"
                          label="Monitor Hours"
                          options={booleanOptions}
                        />
                      </div>
                    )}
                    {selectedOption === options.REPORTING_TYPE && (
                      <div className="mb-4">
                        {renderListDefaultBox(
                          reportingType,
                          'fldReportingType',
                          'Reporting Type',
                          true
                        )}
                      </div>
                    )}

                  </div>
                  {selectedOption && (
                    <div className="mt-20" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                      <span className="text-gray-600 text-sm">
                        {`"${recordsNumber}" records will be updated.`}
                      </span>
                    </div>
                  )}
                </div>
              </div>
          </DialogContent>
          <DialogActions sx={{ m: 0, p: 3 }}>
            <LoadingButton
              type='submit'
              loading={saveLoading}
              form='updateRecords-popup'
              className="ml-4 mr-3 w-32"
              variant="contained"
            >
              Save
            </LoadingButton>
            <Button onClick={onCancelClick} className="w-32">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Portal>
          <Snackbar
            open={snackBar.open}
            autoHideDuration={2000}
            onClose={onSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
              {snackBar.message}
            </Alert>
          </Snackbar>
        </Portal>
      </form>
    </div>
  );
};

export default UpdateRecordPopupOld;
