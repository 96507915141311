import { isNil } from 'lodash';
import { TblSchedChk } from 'src/generated/graphql';

// RXDBRemoval - change Node checks type with dotnet checks type
export const extractChecks = (checks: string | undefined | null): TblSchedChk[] => {
  if (checks !== undefined && checks !== null) {
    const parsedChecks = JSON.parse(checks);
    parsedChecks.sort((a: { fldTitle?: string }, b: { fldTitle?: string }) => { 
      if (a.fldTitle && b.fldTitle) {
        return a.fldTitle.localeCompare(b.fldTitle);
      }
      return 0;
    });
    return parsedChecks;
  }
  return [];
}

export const validateForm = (data: any, setSnackbar: any) => {
  const {logDate, categoryId, performedBy } = data;

  if (isNil(categoryId) || isNil(logDate) || isNil(performedBy)) {
    setSnackbar({
      open: true,
      message: 'Please fill required field(s). Check form field(s) marked with red color',
      type: 'error',
    });
    return false;
  }

  return true;
};