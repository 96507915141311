import { ApolloError, gql, useMutation } from '@apollo/client';
import { LogEntry, LogEntryInput, QueryLogEntriesArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_LOG_ENTRIES_WITH_COUNTS } from './useGetLogEntriesWithCounts';
import { GET_LOG_ENTRIES_WITH_NO_COUNTS } from './useGetLogEntriesWithNoCounts';

export const UPSERT_LOGENTRY = gql`
  mutation UpsertLogentry($input: LogEntryInput!) {
    upsertLogEntry(input: $input) {
      logEntry {
        id
        curr
        dayLogType
        department
        description
        equipmentId
        company
        cost
        enteredBy
        crewId
        hours
        html
        isWarranty
        locationId
        performedBy
        restricted
        sms
        categoryId
        time
        timeQty
        workList
        logDate
      }
    }
  }
`;

export interface LogEntryMutationResponse {
  responseData?: LogEntry;
  responseMessage: string;
}

interface UpsertLogEntryResult {
  upsertLogEntry: (input: LogEntryInput, isCreate: boolean, queryVariables: QueryLogEntriesArgs, callerComponent: string) => Promise<LogEntryMutationResponse>;
  upsertLogEntryLoading: boolean;
  upsertLogEntryError?: ApolloError;
}

export const useUpsertLogEntry = (): UpsertLogEntryResult => {
  const [AddOrUpdateLogEntry, { loading, error }] = useMutation(UPSERT_LOGENTRY, {
    onError: (error: ApolloError) => {
      logger('UpsertLogentry').error(`Error upserting log entry -->`, error.message);
    }
  });

  const upsertLogEntry = async (input: LogEntryInput, isCreate: boolean, queryVariables: QueryLogEntriesArgs, callerComponent: string): Promise<LogEntryMutationResponse> => {
    const refetchQueries = [];
    if (callerComponent === 'History') {
      refetchQueries.push({
        query: GET_LOG_ENTRIES_WITH_NO_COUNTS,
        variables: queryVariables,
      });
    } else {
      refetchQueries.push(
        {
          query: GET_LOG_ENTRIES_WITH_COUNTS,
          variables: queryVariables,
        }
      );
    }

    const response = await AddOrUpdateLogEntry({ 
      variables: { input },
      refetchQueries: refetchQueries,
    });

    const responseData = response.data?.upsertLogEntry?.logEntry;
    if (responseData) {
      logger('LogEntry').info(`Log entry ${isCreate ? 'added' : 'updated'} successfully`, response.data);
      return {
        responseData,
        responseMessage: `Log entry ${isCreate ? 'added' : 'updated'} successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to ${isCreate ? 'add' : 'update'} log entry!`,
      };
    }
  };

  return { 
    upsertLogEntry, 
    upsertLogEntryLoading: loading, 
    upsertLogEntryError: error,
  };
};