import {
  isEmpty, isNil,
} from 'lodash';

export const validateForm = (data: any, setSnackbar: any) => {
  const { docTitle, categoryId } = data;

  if (isNil(docTitle) || isEmpty(docTitle) || isNil(categoryId)) {
    setSnackbar({
      open: true,
      message: 'Please fill required field(s). Check form field(s) marked with red color',
      type: 'error',
    });
    return false;
  }
  return true;
};
