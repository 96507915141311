import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { logger } from "src/helpers/logger";
import { LogEntryData } from "./useGetLazyLogEntry";

export const GET_LOG_ENTRY_DOCUMENT_COUNT = gql`
  query GetLogEntryDocumentsCount($logEntryId: String!) {
    logEntry(logEntryId: $logEntryId) {
      __typename
      id
      documentsCount
    }
  }
`

interface LogEntryResult {
    getLogEntryDocumentsCount: LazyQueryExecFunction<LogEntryData, OperationVariables>;
}

export const useGetLogEntryDocumentsCount = (): LogEntryResult => {

  const [ getLogEntry, { error }] = useLazyQuery(GET_LOG_ENTRY_DOCUMENT_COUNT, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLogEntryDocumentsCount').error('Error fetching log entry -->', error.message);
  }

  return {
    getLogEntryDocumentsCount: getLogEntry
  };
};
  