import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery } from "@apollo/client";
import { Documentation } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_DOCUMENTATION_BY_ID = gql`
  query GetLazyDocumentationById($documentationId: String!) {
    documentationById(documentationId: $documentationId) {
      id
      addedBy
      categoryId
      completeReqd
      description
      docFigure
      docPage
      docTitle
      fileType
      libType
      number
      password
      received
      showInCentral
      isReport
      requiresVerification
      commentsCount
    }
  }
`

export interface DocumentationByIdData {
  documentationById: Documentation;
};

interface DocumentationByIdResult {
  getLazyDocumentationById: LazyQueryExecFunction<DocumentationByIdData, OperationVariables>;
}

export const useGetLazyDocumentationById = (): DocumentationByIdResult => {

  const [ getDocumentationById, { error }] = useLazyQuery(GET_LAZY_DOCUMENTATION_BY_ID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyDocumentationById').error('Error fetching documentation -->', error.message);
  }

  return {
    getLazyDocumentationById: getDocumentationById
  };
};
  