import { ApolloQueryResult, gql, useLazyQuery } from "@apollo/client";
import { CollectionSegmentInfo, ScheduleFilterInput, ScheduleSortInput, Schedule } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_EVENT_SCHEDULES = gql`
  query GetEventSchedules($skip: Int, $take: Int, $filterInput: ScheduleFilterInput, $order: [ScheduleSortInput!]) {
    eventSchedules(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        id
        subject
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

export interface SchedulesResult {
  data: Schedule[];
  currentCount: number;
  totalCount: number;
  pageInfo: CollectionSegmentInfo;
  loading: boolean;
  loadData: (skip: number, take: number,  filterInput: ScheduleFilterInput, order: ScheduleSortInput[]) => void;
}

export const useGetLazyEventSchedules = (): SchedulesResult => {
  const [getEventSchedules, { data, loading, error }] = useLazyQuery(GET_EVENT_SCHEDULES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const loadData = (skip: number, take: number, filterInput: ScheduleFilterInput, order: ScheduleSortInput[]) => {
    logger('GetEventSchedules').debug('Query variables', {skip, take, filterInput, order});   

    getEventSchedules({ variables: { skip, take, filterInput, order }})
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.eventSchedules) {
          const { items, pageInfo } = result.data.eventSchedules;
          logger('GetEventSchedules').info('Event schedules fetched successfully', {
            recordsCount: items?.length,
            pageInfo: pageInfo,
          });
        } else {
          logger('GetEventSchedules').warning('No data received in query result');
        }
      }).catch((apolloError) => {
        logger('GetEventSchedules').error('Error re-fetching event schedules -->', apolloError.message);
      });
  };
  
  if (error) {
    logger('GetEventSchedules').error('Error fetching event schedules -->', error.message);
  }

  return {
    data: data?.eventSchedules?.items || [],
    totalCount: data?.eventSchedules?.totalCount || 0,
    currentCount: data?.eventSchedules?.items?.length || 0,
    pageInfo: data?.eventSchedules?.pageInfo,
    loading,
    loadData,
  };
};