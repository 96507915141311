import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { DocumentationCollectionSegment, DocumentationFilterInput, DocumentationSortInput,  } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_DOCUMENTATIONS = gql`
  query GetLazyDocumentations(
    $filterInput: DocumentationFilterInput, 
    $order: [DocumentationSortInput!]
  ) {
    documentation(filterInput: $filterInput, order: $order) {
      items {
        description
        docTitle
        fileType
        id
        libType
        number
        showInCentral
      }
    }
  }
`

interface LazyDocumentationsData {
  documentations: DocumentationCollectionSegment;
}

interface LazyDocumentationResult {
  getLazyDocumentations: LazyQueryExecFunction<LazyDocumentationsData, OperationVariables>;
}

export const useGetLazyDocumentations = (filterInput: DocumentationFilterInput, order: DocumentationSortInput[]): LazyDocumentationResult => {

  const [ getDocumentations, { error }] = useLazyQuery(GET_LAZY_DOCUMENTATIONS, {
    fetchPolicy: "network-only",
    variables: { filterInput, order },
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyDocumentations').error('Error fetching documentations -->', error.message);
  }

  return {
    getLazyDocumentations: getDocumentations
  };
};