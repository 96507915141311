import { gql, useQuery } from '@apollo/client';
import { DocumentationFilterInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { CategoriesResult } from '../logEntries/useGetLogEntryCountsByCategory';

export const GET_DOCUMENTATION_COUNTS_BY_CATEGORY = gql`
  query GetDocumentationCountsByCategory($filterInput: DocumentationFilterInput) {
    documentationCountsByCategory(filterInput: $filterInput) {
      count
      item {
        id
        category
        depts
        noView
        tags
        parentId
      }
    }
  }
`;

export const useGetDocumentationCountsByCategory = (filterInput: DocumentationFilterInput): CategoriesResult => {
  const { data, loading, error, refetch } = useQuery(GET_DOCUMENTATION_COUNTS_BY_CATEGORY, {
    variables: { filterInput },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetDocumentationCountsByCategory').error('Error fetching categories -->', error.message);
  }

  return {
    recordCountsByCategory: data?.documentationCountsByCategory || [],
    refetchRecordCountsByCategory: refetch,
    loading,
  };
};
