import { ApolloError, gql, useMutation } from '@apollo/client';
import { BulkModifyDocumentationInput, Documentation, QueryDocumentationArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_DOCUMENTATIONS } from './useGetDocumentations';

export const BULK_UPDATE_DOCUMENTATIONS = gql`
  mutation BulkUpdateDocumentations($input: BulkModifyDocumentationInput!) {
    bulkModifyDocumentations(input: $input) {
      documentationList {
        id
        categoryId
        libType
        showInCentral
        isReport
        requiresVerification
      }
    }
  }
`;

export interface BulkDocumentationsMutationResponse {
  responseData?: Documentation[];
  responseMessage: string;
}

interface BulkUpdateDocumentationsResult {
  bulkUpdateDocumentations: (input: BulkModifyDocumentationInput, columnName: string, queryVariables: QueryDocumentationArgs) => Promise<BulkDocumentationsMutationResponse>;
  bulkUpdateDocumentationsLoading: boolean;
  bulkUpdateDocumentationsError?: ApolloError;
}

export const useBulkUpdateDocumentations = (): BulkUpdateDocumentationsResult => {
  const [UpdateDocumentations, { loading, error }] = useMutation(BULK_UPDATE_DOCUMENTATIONS, {
    onError: (error: ApolloError) => {
      logger('BulkUpdateDocumentations').error("Error updating documentations -->", error.message);
    }
  });

  const bulkUpdateDocumentations = async (input: BulkModifyDocumentationInput, columnName: string, refetchVariables: QueryDocumentationArgs): Promise<BulkDocumentationsMutationResponse> => {
    const response = await UpdateDocumentations({ 
      variables: { input },
      refetchQueries: [{
        query: GET_DOCUMENTATIONS,
        variables: refetchVariables,
      }],
    });

    const responseData = response.data?.bulkModifyDocumentations?.documentationList;

    if (responseData) {
      logger('BulkUpdateDocumentations').info(`${columnName} successfully updated on all selected record(s)`, response.data);
      return {
        responseData,
        responseMessage: `${columnName} successfully updated on all selected record(s)!`
      };
    } else {
      return {
        responseMessage: `Failed to update ${columnName}!`,
      };
    }
  };

  return { 
    bulkUpdateDocumentations, 
    bulkUpdateDocumentationsLoading: loading, 
    bulkUpdateDocumentationsError: error,
  };
};
