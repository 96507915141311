import React from "react";
import moment from 'moment';
import pdf from '@react-pdf/renderer';
const { Page, Text, View, Document, StyleSheet, Font } = pdf;
import Html from 'react-pdf-html';
import { isNil } from "lodash";

Font.register({
    family: 'Roboto',
    fonts: [
      { src: `./fonts/Roboto-Regular.ttf` },
      { src: `./fonts/Roboto-Bold.ttf`, fontWeight: 'bold' },
      { src: `./fonts/Roboto-Italic.ttf`, fontStyle: 'italic' },
      { src: `./fonts/Roboto-BoldItalic.ttf`, fontWeight: 'bold', fontStyle: 'italic' },
    ],
  });

const styles = StyleSheet.create({
    header: {
        fontSize: 16,
        marginLeft: 160,
        marginBottom: 10,
        fontWeight: "bold",
        fontStyle: "italic",
        fontFamily: "Roboto",
    },
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 14,
        padding: "0px 10px 5px ",
        fontFamily: "Roboto",
        fontWeight: "bold",
    },
    sectionContainer: {
        flexDirection: "row",
        background: "#ff0000",
        paddingTop: 20,
        paddingBottom: 4,
        borderTop: "4px solid #000000",
        flexWrap: "wrap"
    },
    sectionFirstItem: {
        flexBasis: "60%",
    },
    sectionLastItem: {
        flexBasis: "40%"
    },
    sectionBottomItem: {
        flexBasis: "100%",
        display: "flex",
        flexDirection: "row"
    },
    page: {
        flexDirection: "row",
        backgroundColor: "#E4E4E4",
    },
    sectionItemField: {
        flexDirection: "row",
        marginBottom: 10,
    },
    sectionItemFieldTitle: {
        fontSize: 10,
        marginRight: 10,
        lineHeight: 1.4,
        width: 70,
        fontFamily: "Roboto",
        fontWeight: "bold",
    },
    sectionItemFieldEqTitle: {
        fontSize: 10,
        marginRight: 20,
        lineHeight: 1.4,
        width: 200,
        fontFamily: "Roboto",
        fontWeight: "bold",
        whiteSpace: "nowrap"
    },
    sectionItemFieldDesc: {
        fontSize: 10,
        marginRight: 70,
        lineHeight: 1.6,
        fontFamily: "Roboto",
        flexGrow: 1,
        textAlign: "left"
    },
    sectionItemFieldHours: {
        fontSize: 10,
        marginLeft: 30,
        width: "65%",
        lineHeight: 1.6,
        fontFamily: "Roboto",
    },
    sectionItemFieldCritical:{
        fontSize: 14,
        flexGrow: 1,
        lineHeight: 1.6,
        fontFamily: "Roboto",
        color:"red",
        fontStyle:"italic",
        fontWeight:"bold"
    },
    sectionItemFieldDescError: {
        fontSize: 20,
        marginRight: 10,
        lineHeight: 1.6,
        fontFamily: "Roboto",
        fontStyle: "italic",
        color: "#ff0000",
        width: "50%",
        marginTop: -10,
    },
});

const stylesheet = {
    p: {
        margin: 0
    },
    br: {
        display: 'none !important',
        lineHeight: '0px !important',
        fontSize: '0px !important',
        height: '0px !important'
    }
}

const LogEntryPDF = (docData: any, recordsCount: number, header: string) => {
    return {
        count: recordsCount, 
        document: <Document>
            <Page style={styles.body}>
                <Text style={styles.header}>{header}</Text>
                <Text style={styles.title}>Maintenance and Repair Log</Text>
                {docData.slice(0, 100).map((data: any) => {
                    const logDate = data?.logDate ? moment(data.logDate).format("D-MMM-YYYY") : "";
                    return (
                        <View style={styles.sectionContainer} key={data.id}>
                            <View style={styles.sectionFirstItem}>
                                <View style={styles.sectionItemField}>
                                    <Text style={styles.sectionItemFieldTitle}>Log Date:</Text>
                                    <Text style={styles.sectionItemFieldDesc}>{logDate}</Text>
                                </View>
                                <View style={styles.sectionItemField}>
                                    <Text style={styles.sectionItemFieldTitle}>Category:</Text>
                                    <Text style={styles.sectionItemFieldDesc} wrap>
                                        {data?.category?.category || ""}
                                    </Text>
                                </View>
                                <View style={styles.sectionItemField}>
                                    <Text style={styles.sectionItemFieldTitle}>Equipment:</Text>
                                    <Text style={styles.sectionItemFieldDesc}>
                                        {data?.equipment?.uniqueName || ""}
                                    </Text>
                                </View>
                                <View style={styles.sectionItemField}>
                                    <Text style={styles.sectionItemFieldTitle}>Entry Type:</Text>
                                    <Text style={styles.sectionItemFieldDesc}>{isNil(data?.scheduleId) ? "Repaired / Planned" : "Planned Maintenance" }</Text>
                                </View>
                            </View>

                            <View style={styles.sectionLastItem}>
                                <View style={styles.sectionItemField}>
                                    <Text style={styles.sectionItemFieldTitle}>Performed By:</Text>
                                    <Text style={styles.sectionItemFieldDesc}>{data?.performedBy || ""}</Text>
                                </View>
                                <View style={styles.sectionItemField}>
                                    <Text style={styles.sectionItemFieldTitle}>Company:</Text>
                                    <Text style={styles.sectionItemFieldDesc}>{data?.company || ""}</Text>
                                </View>
                                <View style={styles.sectionItemField}>
                                    <Text style={styles.sectionItemFieldTitle}>Cost:</Text>
                                    <Text style={styles.sectionItemFieldDesc}>{data?.cost || ""}</Text>
                                </View>
                                <View style={styles.sectionItemField}>
                                    <Text style={styles.sectionItemFieldTitle}>Time:</Text>
                                    <Text style={styles.sectionItemFieldDesc}>{data?.time || ""}</Text>
                                </View>
                                {!!data?.fldSms && (
                                    <View style={styles.sectionItemField}>
                                        <Text style={styles.sectionItemFieldCritical}>CRITICAL SYSTEM!</Text>
                                    </View>
                                )}
                                <View style={styles.sectionItemField}>
                                    <Text style={styles.sectionItemFieldEqTitle}>Equipment Hours:</Text>
                                    <Text style={styles.sectionItemFieldHours}>{data?.hours || ""}</Text>
                                </View>
                            </View>

                            <View style={styles.sectionBottomItem}>
                                <Text style={styles.sectionItemFieldTitle}>Entry:</Text>
                                <View style={{ ...styles.sectionItemFieldDesc }}>
                                    <Html stylesheet={stylesheet} style={{ fontSize: 10 }}>
                                        {data?.html || ""}
                                    </Html>
                                </View>
                            </View>
                        </View>
                    );
                })}
            </Page>
        </Document>
    }
}

export default LogEntryPDF;