import { FC, useState } from 'react';
import { Button, DialogTitle, DialogActions, DialogContent, Box, Autocomplete as MaterialAutocomplete, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CustomDialog } from 'src/helpers/modals';
import DocumentRelatedItemAddGrid from './DocumentRelatedItemAddGrid';
import { RecordType } from 'src/generated/dotnet.graphql';

export type DropDownOptionType = {
  type: string;
  name: string;
  recordType: RecordType;
};

export const DROPDOWN_OPTIONS = {
  EQUIPMENT: { type: 'Equipment', name: 'Equipment', recordType: RecordType.Equipment },
  INV_GENERAL: { type: 'InventoryGeneral', name: 'Inventory - General', recordType: RecordType.InventoryGeneral },
  INV_BEVERAGES: { type: 'InventoryBeverages', name: 'Inventory - Beverage', recordType: RecordType.InventoryBeverages },
  INV_UNIFORM: { type: 'InventoryUniform', name: 'Inventory - Uniform', recordType: RecordType.InventoryUniform },
  INV_DRY_COLD: { type: 'InventoryDryCold', name: 'Inventory - Dry & Cold Stores', recordType: RecordType.InventoryDryAndColdStore },
  INV_MEDICAL: { type: 'InventoryMedical', name: 'Inventory - Medical', recordType: RecordType.InventoryMedical },
  SCHED_EQUIPMENT: { type: 'ScheduleEquipment', name: 'Schedule - Equipment', recordType: RecordType.EquipmentSchedule },
  SCHED_EVENT: { type: 'ScheduleEvent', name: 'Schedule - Event', recordType: RecordType.EventSchedule },
  SCHED_DRILL: { type: 'ScheduleDrill', name: 'Schedule - Drill', recordType: RecordType.DrillSchedule },
  SCHED_INSPECTION: { type: 'ScheduleInspections', name: 'Schedule - Inspections', recordType: RecordType.InspectionSchedule },
} as const;

interface InjectedProps {
  visible: boolean;
  onSave: (recordIds: string[], recordType: RecordType) => void;
  onCancel?: () => void;
  documentationName: any;
  excludedIds: string[];
  readOnly?: boolean;
}

const DocumentRelatedItemAddDialog: FC<InjectedProps> = ({
  visible,
  onSave,
  onCancel,
  documentationName,
  excludedIds,
  readOnly
}) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<any>(null);
  const [selectedOption, setSelectedOption] = useState<DropDownOptionType | null>(null);
  const dropDownOptionValues = Object.values(DROPDOWN_OPTIONS);

  const onDropDownValueChange = (event: any, option: DropDownOptionType | null) => {
    setSelectedOption(option);
  };

  const handleSave = async () => {
    if (!selectedItems || !selectedOption) return;
    onSave(selectedItems, selectedOption.recordType);
    setSelectedOption(null);
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setSelectedOption(null);
    setProcessing(false);
  };

  const handleSelect = (recordIds: string[]) => {
    setSelectedItems(recordIds);
  };

  return (
    <>
      <CustomDialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={visible}
        onClose={handleCancel}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">{`Associated Item for ${documentationName}`}</span>
        </DialogTitle>

        <DialogContent dividers sx={{ p: 0 }}>
          <div>
            <MaterialAutocomplete
              className="flex m-4 mt-5"
              disablePortal
              id="combo-box-demo"
              options={dropDownOptionValues}
              onChange={onDropDownValueChange}
              getOptionLabel={(option) => option.name} // Use `name` as the display value
              renderInput={(params) => (
                <TextField {...params} label="Relate Item From" autoComplete='off' size="small" />
              )}
            />
            {!selectedOption && (
              <div className="w-full my-40 flex justify-center items-center">
                Please Select a List Name
              </div>
            )}
            {selectedOption && (
              <DocumentRelatedItemAddGrid
                selectedOptionType={selectedOption.type}
                onSelect={handleSelect}
                excludedIds={excludedIds}
              />
            )}
          </div>
        </DialogContent>

        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
            <LoadingButton
              loading={processing}
              type="submit"
              onClick={handleSave}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default DocumentRelatedItemAddDialog;
