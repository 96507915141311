// React Components
import { isEmpty, isNil, pick } from 'lodash';
import { getDatabase } from 'src/rxdb';

// Data
import { v4 as uuid } from 'uuid';
import { ICheckListType, IMoveItemType } from './CheckListComponent/CheckListTab';

export const validateForm = (data: any, setSnackbar: any) => {
  const { fldSubject, Department, Equipment,scheduleType } = data;

  if (isNil(fldSubject) || isEmpty(fldSubject) || isNil(Department) || isEmpty(scheduleType) || isNil(scheduleType)) {
    setSnackbar({
      open: true,
      message: 'Please fill required field(s). Check form field(s) marked with red color',
      type: 'error',
    });
    return false;
  }
  return true;
};

export const handleCheckItemUpsert = async (relatedKey: any, data?: ICheckListType) => {
  const db = await getDatabase();
  try {
      // check if description exists so we can reuse it instead of created a new one
      let description = await db.tblschedchkdesc.findOne({
        selector: {
          fldDescriptions: data?.fldDescriptions,
          deletedBy: { $eq: null },
        }
      })
      .exec();

      if(!description){
        // upsert into tblschedchkdesc so we can have DescKey
        description = await db.tblschedchkdesc.upsert({
          PKey: uuid(),
          fldDescriptions: data?.fldDescriptions,
        });
      }
      
      // if fldIndex is null it means create new record
      if (data?.fldIndex === null) {
        let nextFldIndex = await getNextFldIndex(relatedKey);
        console.log('nextFldIndex: ', nextFldIndex)
        data.fldIndex = nextFldIndex;
      }

      // upsert into tblschedchk 
      // use the PKey if present from checkItem data - null/empty means a new item is being added so generate a new uuid.
      // record is upserted/updated
      await db.tblschedchk.upsert({
        PKey: data?.PKey || uuid(),
        FKey: data?.FKey || relatedKey,
        EqKey: data?.EqKey,
        DescKey: description.PKey,
        fldLocHierarchy: data?.fldLocHierarchy,
        fldTitle: data?.fldTitle,
        fldIndex: data?.fldIndex,
        Completed: data?.Completed || false
      });
      
    } catch (e: any) {
      console.error(e);
      throw e
  }  
}

export const getNextFldIndex = async (relatedKey: any): Promise<any> => {
  const db = await getDatabase();

  let checks = await db.tblschedchk.find({
    selector: {
      FKey: { $eq: relatedKey},
      Completed: { $eq: false },
      deletedBy: { $eq: null },
    }
  })
  .exec();

  checks.sort((itemA: any, itemB: any) => {
    if (itemA.fldIndex > itemB.fldIndex) {
        return -1;
    } else {
        return 1;
    }
  })
  
  const maxFldIndex = checks.length > 0 ? checks[0].fldIndex : 0;
  return maxFldIndex ? maxFldIndex + 1 : 1;
}

export const handleMoveItemUpsert = async (data: IMoveItemType) => {
  const db = await getDatabase();
  try {
      // get schedMaintEQ PKey based on schedMaint PKey
      let schedMaintEQ = await db.tblschedmainteq.findOne({
        selector: {
          SchedKey: data?.SchedMaintKey,
          deletedBy: { $eq: null },
        }
      })
      .exec();

      let nextFldIndex = await getNextFldIndex(schedMaintEQ?.PKey);
      
      // upsert into tblschedchk 
      // use the [schedMaintEQ].[PKey] to update [tblschedchk].[FKey] - check item's new inspection 
      await db.tblschedchk.upsert({
        PKey: data.PKey,
        FKey: schedMaintEQ?.PKey || data?.FKey,
        fldIndex: nextFldIndex,
      });
      
    } catch (e: any) {
      console.error(e);
      throw e
  }  
}
