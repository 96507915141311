import { ApolloError, gql, useMutation } from '@apollo/client';
import { Documentation, DocumentationInput, QueryDocumentationArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_DOCUMENTATIONS } from './useGetDocumentations';

export const UPSERT_DOCUMENTATION = gql`
  mutation UpsertDocumentation($input: DocumentationInput!) {
    upsertDocumentation(input: $input) {
      documentation {
        id
        docTitle
        description
        docPage
        docFigure
        addedBy
        toc
        number
        specRef
        password
        libType
        fileType
        status
        statusInternal
        categoryId
        isReport
        received
        showInCentral
        completeReqd
        requiresVerification
      }
    }
  }
`;

export interface DocumentationMutationResponse {
  responseData?: Documentation;
  responseMessage: string;
}

interface UpsertDocumentationResult {
  upsertDocumentation: (input: DocumentationInput, isCreate: boolean, queryVariables: QueryDocumentationArgs) => Promise<DocumentationMutationResponse>;
  upsertDocumentationLoading: boolean;
  upsertDocumentationError?: ApolloError;
}

export const useUpsertDocumentation = (): UpsertDocumentationResult => {
  const [AddOrUpdateDocumentation, { loading, error }] = useMutation(UPSERT_DOCUMENTATION, {
    onError: (error: ApolloError) => {
      logger('UpsertDocumentation').error("Error upserting documentation -->", error.message);
    }
  });

  const upsertDocumentation = async (input: DocumentationInput, isCreate: boolean, refetchVariables: QueryDocumentationArgs): Promise<DocumentationMutationResponse> => {
    const response = await AddOrUpdateDocumentation({ 
      variables: { input },
      refetchQueries: [{
        query: GET_DOCUMENTATIONS,
        variables: refetchVariables
      }],
    });

    const responseData = response.data?.upsertDocumentation?.documentation;
    if (responseData) {
      logger('UpsertDocumentation').info(`Documentation ${isCreate ? 'added' : 'updated'} successfully`, response.data);
      return {
        responseData,
        responseMessage: `Documentation ${isCreate ? 'added' : 'updated'} successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to ${isCreate ? 'add' : 'update'} documentation!`,
      };
    }
  };

  return { 
    upsertDocumentation, 
    upsertDocumentationLoading: loading, 
    upsertDocumentationError: error,
  };
};
