import { ApolloQueryResult, gql, useQuery } from '@apollo/client';
import { CollectionSegmentInfo, Documentation, DocumentationFilterInput, DocumentationSortInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_DOCUMENTATIONS = gql`
  query GetDocumentations(
    $skip: Int
    $take: Int
    $filterInput: DocumentationFilterInput
    $order: [DocumentationSortInput!]
  ) {
    documentation(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        __typename
        id
        addedBy
        categoryId
        completeReqd
        description
        docFigure
        docPage
        docTitle
        fileType
        libType
        number
        password
        received
        showInCentral
        isReport
        requiresVerification
        commentsCount
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export interface DocumentationsResult {
  data: Documentation[];
  currentCount: number;
  totalCount: number;
  pageInfo: CollectionSegmentInfo;
  loading: boolean;
  loadData: (skip: number, take: number, filterInput: DocumentationFilterInput, order: DocumentationSortInput[]) => void;
}

export const useGetDocumentations = (skip: number, take: number, filterInput: DocumentationFilterInput, order: DocumentationSortInput[]): DocumentationsResult => {
  const { data, loading, error, refetch } = useQuery(GET_DOCUMENTATIONS, {
    variables: { skip, take, filterInput, order },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });

  const loadData = ( skip: number, take: number, filterInput: DocumentationFilterInput, order: DocumentationSortInput[]) => {
    logger('GetDocumentations').debug('Query variables', { skip: skip, take: take, filterInput: filterInput, order: order });
    refetch({ skip: skip, take: take, filterInput: filterInput, order: order })
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.documentation) {
          const { items, pageInfo } = result.data.documentation;
          logger('GetDocumentations').info('Documentations fetched successfully', { recordsCount: items?.length, pageInfo: pageInfo });
        } else {
          logger('GetDocumentations').warning('No data received in query result');
        }
      })
      .catch((apolloError) => {
        logger('GetDocumentations').error('Error re-fetching documentations -->', apolloError.message);
      });
  };

  if (error) {
    logger('GetDocumentations').error('Error fetching documentations -->', error.message);
  }

  return {
    data: loading ? [] : data?.documentation?.items || [],
    totalCount: data?.documentation?.totalCount || 0,
    currentCount: data?.documentation?.items?.length || 0,
    pageInfo: data?.documentation?.pageInfo,
    loading,
    loadData,
  };
};
