import { ApolloQueryResult, gql, useLazyQuery } from "@apollo/client";
import { CollectionSegmentInfo, EquipmentFilterInput, EquipmentSortInput, Equipment } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_EQUIPMENT = gql`
  query GetEquipment($skip: Int, $take: Int, $filterInput: EquipmentFilterInput, $order: [EquipmentSortInput!]) {
    equipment(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        id
        uniqueName
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface EquipmentResult {
  data: Equipment[];
  currentCount: number;
  totalCount: number;
  pageInfo: CollectionSegmentInfo;
  loading: boolean;
  loadData: (skip: number, take: number,  filterInput: EquipmentFilterInput, order: EquipmentSortInput[]) => void;
}

export const useGetLazyEquipment = (): EquipmentResult => {
  const [getEquipment, { data, loading, error }] = useLazyQuery(GET_EQUIPMENT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const loadData = (skip: number, take: number, filterInput: EquipmentFilterInput, order: EquipmentSortInput[]) => {
    logger('GetEquipment').debug('Query variables', {skip, take, filterInput, order});   

    getEquipment({ variables: { skip, take, filterInput, order }})
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.equipment) {
          const { items, pageInfo } = result.data.equipment;
          logger('GetEquipment').info('Equipment fetched successfully', {
            recordsCount: items?.length,
            pageInfo: pageInfo,
          });
        } else {
          logger('GetEquipment').warning('No data received in query result');
        }
      }).catch((apolloError) => {
        logger('GetEquipment').error('Error re-fetching equipment -->', apolloError.message);
      });
  };
  
  if (error) {
    logger('GetEquipment').error('Error fetching equipment -->', error.message);
  }

  return {
    data: data?.equipment?.items || [],
    totalCount: data?.equipment?.totalCount || 0,
    currentCount: data?.equipment?.items?.length || 0,
    pageInfo: data?.equipment?.pageInfo,
    loading,
    loadData,
  };
};