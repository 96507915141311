import { ApolloError, gql, useMutation } from '@apollo/client';
import { LogEntry, QueryLogEntriesArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_LOG_ENTRIES_WITH_COUNTS } from './useGetLogEntriesWithCounts';
import { LogEntryMutationResponse } from './useUpsertLogEntry';

export const DELETE_LOG_ENTRIES = gql`
  mutation DeleteLogEntries($logEntryIds: [String!]!) {
    deleteLogEntries(logEntryIds: $logEntryIds) {
      logEntries {
        id
        deletedAt
        deletedBy
      }
    }
  }
`;

export interface LogEntriesMutationResponse {
  responseData?: LogEntry[];
  responseMessage: string;
}

interface DeleteLogEntriesResult {
  removeLogEntries: (logEntryIds: string[], variables: QueryLogEntriesArgs) => Promise<LogEntryMutationResponse>;
  removeLogEntriesLoading: boolean;
}

export const useDeleteLogEntries = (): DeleteLogEntriesResult => {
  const [deleteLogEntries, { loading }] = useMutation(DELETE_LOG_ENTRIES, {
    onError: (error: ApolloError) => {
      logger('DeleteLogEntries').error('Error deleting log entry/entries -->', error.message);
    }
  });

  const removeLogEntries = async (logEntryIds: string[], refetchVariables: QueryLogEntriesArgs): Promise<LogEntryMutationResponse> => {
    const response = await deleteLogEntries({ 
      variables: { logEntryIds },
      refetchQueries: [
        {
          query: GET_LOG_ENTRIES_WITH_COUNTS,
          variables: refetchVariables,
        }
      ],
    });
    
    const responseData = response.data?.deleteLogEntries?.logEntries;
    if (responseData) {
      logger('DeleteLogEntries').info(`Log ${logEntryIds.length > 1 ? 'entries' : 'entry'} deleted successfully`, response.data);
      return {
        responseData,
        responseMessage: `Log ${logEntryIds.length > 1 ? 'entries' : 'entry'} removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove log ${logEntryIds.length > 1 ? 'entries' : 'entry'}!`,
      };
    }
  };

  return { 
    removeLogEntries, 
    removeLogEntriesLoading: loading, 
  };
};
