import React, { FC } from 'react';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import {
  Card,
  CardHeader,
} from '@mui/material';

interface InjectedProps {
  message?: string;
  recordType?: string;
}

const RecordEditWarningCard: FC<InjectedProps> = ({ message, recordType }) => {
  return (
    <div className="mb-2">
      <Card>
        <CardHeader
          avatar={<InfoTwoToneIcon color="error" />}
          title={
            message
              ? message
              : `You don't have permission to edit this ${recordType ? recordType : 'record'}.`
          }
        />
      </Card>
    </div>
  );
};

export default RecordEditWarningCard;
