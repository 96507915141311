import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { LogEntriesCollectionSegment, LogEntry, LogEntryFilterInput, LogEntrySortInput, QueryLogEntriesArgs } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_LOG_ENTRIES = gql`
  query GetLazyLogEntries($filterInput: LogEntryFilterInput, $order: [LogEntrySortInput!]) {
    logEntries(filterInput: $filterInput, order: $order) {
      items {
        id
        category {
          id
          category
        }
        department
        equipment {
          id
          uniqueName
        }
        categoryId
        performedBy
        enteredBy
        workList
        company
        cost
        time
        sms
        hours
        html
        logDate
        scheduleId
      }
    }
  }
`

interface LazyLogEntriesData {
  logEntries: LogEntriesCollectionSegment;
}

interface LazyLogEntriesResult {
  getLazyLogEntries: LazyQueryExecFunction<LazyLogEntriesData, OperationVariables>;
}

export const useGetLazyLogEntries = (filterInput: LogEntryFilterInput, order: LogEntrySortInput[]): LazyLogEntriesResult => {

  const [ getLogEntries, { error }] = useLazyQuery(GET_LAZY_LOG_ENTRIES, {
    fetchPolicy: "network-only",
    variables: { filterInput, order },
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyLogEntries').error('Error fetching log entries -->', error.message);
  }

  return {
    getLazyLogEntries: getLogEntries
  };
};