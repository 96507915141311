import { ApolloQueryResult, gql, useLazyQuery } from "@apollo/client";
import { InventoryFilterInput, InventorySortInput } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";
import { InventoryResult } from "./useGetInventory";

export const GET_LAZY_INVENTORY = gql`
  query GetLazyInventory($skip: Int, $take: Int, $filterInput: InventoryFilterInput, $order: [InventorySortInput!]) {
    inventory(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        id
        productName
        manufacturer
        partNumber
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const useGetLazyInventory = (): InventoryResult => {
  const [getLazyInventory, { data, loading, error }] = useLazyQuery(GET_LAZY_INVENTORY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const loadData = (skip: number, take: number, filterInput: InventoryFilterInput, order: InventorySortInput[]) => {
    logger('GetLazyInventory').debug('Query variables', { skip, take, filterInput, order });

    getLazyInventory({ variables: { skip, take, filterInput, order } })
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.inventory) {
          const { items, pageInfo } = result.data.inventory;
          logger('GetLazyInventory').info('Inventory fetched successfully', {
            recordsCount: items?.length,
            pageInfo,
          });
        } else {
          logger('GetLazyInventory').warning('No data received in query result');
        }
      })
      .catch((apolloError) => {
        logger('GetLazyInventory').error('Error re-fetching inventory -->', apolloError.message);
      });
  };

  if (error) {
    logger('GetLazyInventory').error('Error fetching inventory -->', error.message);
  }

  return {
    data: data?.inventory?.items || [],
    totalCount: data?.inventory?.totalCount || 0,
    currentCount: data?.inventory?.items?.length || 0,
    pageInfo: data?.inventory?.pageInfo,
    loading,
    loadData,
  };
};
