import '../styles.css';
import { useCallback, useState, MutableRefObject, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { isNil, pick, isEmpty, isBoolean } from 'lodash';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import {exportCSV, exportExcel} from '../../../utils';
import LicensedReactDataGrid from '../../../components/UI/LicensedReactDataGrid';
import { TypeComputedProps, TypeFilterValue, TypeSingleSortInfo, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { useGetDocumentations } from 'src/hooks/documentationLibraries/useGetDocumentations';
import { Documentation, DocumentationSortInput, DocumentationLibType, SortEnumType, DocumentationFilterInput } from 'src/generated/dotnet.graphql';
import { useGetLazyDocumentations } from 'src/hooks/documentationLibraries/useGetLazyDocumentations';
import GridRowIcon from 'src/components/UI/LicensedReactDataGrid/components/GridRowIcon';
import { ExportDialogType } from 'src/consts';

const filter = [
  {
    name: 'docTitle',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'number',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const getSelectorByFilterName = async (
  name: string,
  value: any,
): Promise<Record<string, any>> => {
  switch (name) {
    case 'number':
    case 'docTitle': {
      return {
        [name]: value,
      };
    }
    default:
      return {};
  }
};

const transformData = (data: any[]): Record<string, Documentation> => {
  return data.reduce((acc, { pkey, ...rest }) => {
    acc[pkey] = rest;
    return acc;
  }, {});
}

type DocumentationGridProps = {
  darken: boolean;
  onSelect: (item: Documentation) => void;
  libraryType: any;
  skip: number;
  setSkip: (skip: number) => void;
  limit: number;
  setLimit: (limit: number) => void;
  filterValue: DocumentationFilterInput;
  setFilterValue: (filterValue: DocumentationFilterInput) => void;
  sortValue: DocumentationSortInput[];
  setSortValue: (sortValue: DocumentationSortInput[]) => void;
  rowSelected: Record<string, Documentation>;
  setRowSelected: (rowSelected: Record<string, Documentation>) => void;
};

interface DocumentationGridRef {
  handleExport: (type: string) => void;
}

const DocumentationGrid = forwardRef<DocumentationGridRef, DocumentationGridProps>(({ 
  onSelect, 
  libraryType,
  skip,
  setSkip,
  limit,
  setLimit,
  filterValue,
  setFilterValue,
  sortValue,
  setSortValue,
  rowSelected,
  setRowSelected, 
}: DocumentationGridProps, ref) => {
  const [gridRef, setGridRef] = useState<any>(null);
  const [exportDialog, setExportDialog] = useState<ExportDialogType | null>(null);
  const { getLazyDocumentations } = useGetLazyDocumentations(filterValue, sortValue);
  const { data, totalCount, loading, loadData } = useGetDocumentations(skip,limit, filterValue, sortValue);

  const prevFilterValue = useRef(filterValue);
  const prevSkip = useRef(skip);
  const prevLimit = useRef(limit);
  const prevSortValue = useRef(sortValue);

  useImperativeHandle(ref, () => ({
    handleExport: (type) => {
      handleDataExport(type);
    },
  }));

  useEffect(() => {
    const isFilterChanged = JSON.stringify(filterValue) !== JSON.stringify(prevFilterValue.current);
    const isPaginationChanged = skip !== prevSkip.current || limit !== prevLimit.current;
    const isSortChanged = JSON.stringify(sortValue) !== JSON.stringify(prevSortValue.current);
  
    if (isFilterChanged || isPaginationChanged || isSortChanged) {
      loadData(skip, limit, filterValue, sortValue);
      prevFilterValue.current = filterValue;
      prevSkip.current = skip;
      prevLimit.current = limit;
      prevSortValue.current = sortValue;
    }
  }, [skip, limit, filterValue, sortValue]);
    
  const dataSource = async () => {
    return {
      data,
      count: totalCount,
    };
  };

  const onSortInfoChange = (value: TypeSortInfo) => {
    const sortInfo = value as TypeSingleSortInfo
    if (isNil(sortInfo)) return;

    let sortPayload: DocumentationSortInput[];
    const sortDirection = sortInfo.dir === 1 ? SortEnumType.Asc : SortEnumType.Desc;
    const [field, subField] = sortInfo.name.split('.');

    if (subField) {
        // Handle nested objects
        sortPayload = [{
            [field]: {
                [subField]: sortDirection,
            },
        }];
    } else {
        // Handle non-nested objects
        sortPayload = [{
            [sortInfo.name]: sortDirection,
        }];
    }
    setSortValue(sortPayload)
  }

  const onFilterValueChange = async (filterValue: TypeFilterValue) => {
    if (isNil(filterValue)) return;

    const filterPayload: Record<string, any> = {};

    await Promise.all(
      filterValue.map(async (v: { value: any; name: any; operator: any }) => {
        if (isEmpty(v.value)) return;

        const selector = await getSelectorByFilterName(v.name, v.value);
        if (selector) {
          Object.keys(selector).forEach((key) => {
            filterPayload[key] = selector[key];
          });
        }
      })
    );

    const defaultFilterValue = {
      libType: libraryType as DocumentationLibType,
      showInCentral: true
    }

    // Merge with the existing filterValue
    setFilterValue({
      ...defaultFilterValue,
      ...filterPayload,
    });
  };

  const onSelectionChange = useCallback(({ selected }) => {
    if (isBoolean(selected) && selected) {
      const transformedData = transformData(data);
      setRowSelected(transformedData)
    } else {
      setRowSelected(selected);
    }
  }, [data, rowSelected]);

  const onRowClick = useCallback(
    ({ data }) => {
      if (!data?.__group) {
        if (Object.keys(rowSelected || {}).length < 2) {
          onSelect(data);
        }
      }
    },
    [rowSelected]
  );

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
  };

  // Find if any filters applied to grid
  const areFiltersActive = gridRef?.current.computedFilterValue.some((f: { value: any; }) => !isEmpty(f.value));

  const exportData = (type: string, withFilters = true) => {
    setExportDialog(null);
    switch (type) {
      case 'CSV':
        return onExportToCSV(withFilters);
      case 'xlsx':
        return onExportToExcel(withFilters);
      default:
    }
  };

  const getRows = async (issue: any) => {
    const rows =  issue.map((data: any) =>{
      return {
        ...pick(data, [
          'docTitle',
          'number',
        ]),
      };
    }   
    );
    return rows
  };

  const onExportToExcel = async (withFilters: boolean) => {
    const queryResult = await getLazyDocumentations({ variables: { where: withFilters ? filterValue : null, order: sortValue } });
    const data = queryResult?.data?.documentations || [];
    const columnsData = gridRef.current.visibleColumns.map((c: any) => ({
      header: c.header,
      key: c.id,
    }));
    const columns = columnsData.filter((item: { header: any; }) => {
      return item.header && typeof item.header === 'string';
    });
    const rows = await getRows(data)
    return exportExcel(columns, rows);
  };

  const onExportToCSV = async (withFilters: boolean) => {
    const columns = gridRef.current.visibleColumns;
    const queryResult = await getLazyDocumentations({ variables: { where: withFilters ? filterValue : null, order: sortValue } });
    const data = queryResult?.data?.documentations || [];    
    const rows = await getRows(data)
    return exportCSV(columns, rows);
  };

  const handleDataExport = (type: string) => {
    if (areFiltersActive) {
      setExportDialog({
        visible: true,
        type,
        title: type === 'CSV' ? 'CSV' : 'Excel',
      });

      return;
    }
    exportData(type, false);
  };

  const columns = [
    {
      id: 'icons',
      header: 'Icons',
      defaultWidth: 90,
      render: ({ data }: any) => {
        return (
        <GridRowIcon 
          hasComments={data.commentsCount > 0} 
          isReport={data.isReport} 
          requiresVerification={data.requiresVerification} 
        />
      )},
      onRender: (cellProps: any) => {
        cellProps.style.borderLeft = '#e4e3e2 3px solid';
      },
      sortable: false
    },
    {
      name: 'docTitle',
      header: 'Document Title',
      flex: 2,
    },
    {
      name: 'number',
      header: 'Document Number',
      flex: 1,
    },
  ];

  return (
    <div data-testid="data-grid" className="flex flex-col flex-grow">
      <LicensedReactDataGrid
        onReady={onReady}
        loading={loading}
        columns={columns}
        pagination='remote'
        idProperty='id'
        dataSource={dataSource}
        enableSelection
        checkboxColumn
        skip={skip}
        onSkipChange={setSkip}
        limit={limit}
        onLimitChange={setLimit}
        onRowClick={onRowClick}
        selected={rowSelected}
        onSelectionChange={onSelectionChange}
        onSortInfoChange={onSortInfoChange}
        defaultFilterValue={filter}
        onFilterValueChange={onFilterValueChange}
        disableGroupByToolbar
        sourceRoot={true}
        enableColumnFilterContextMenu={false}
        // pageSizes={[10, 50, 100, 500, 1000, totalCount]}
      />

      <Dialog maxWidth="xs" open={exportDialog?.visible || false}>
        <DialogTitle>
          Export data to
          {exportDialog?.title}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            You have filters applied. Would you like to export with current
            filters?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            autoFocus
            onClick={() => exportDialog && exportData(exportDialog?.type, false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => exportDialog && exportData(exportDialog?.type, true)}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
);

export default DocumentationGrid;
