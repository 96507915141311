import 'src/components/dataentry/inventoryDE/component/style.css';
import { FC, useState } from 'react';
import { Alert, Snackbar, Button, AlertColor } from '@mui/material';
import DocumentRelatedItemsGrid from './DocumentRelatedItemsGrid';
import { useDeleteDocumentRelatedItem } from 'src/hooks/documentationLibraries/useDeleteDocumentRelatedItem';
import { Attachment, RecordType } from 'src/generated/dotnet.graphql';
import { SEVERITY } from 'src/consts';
import DocumentRelatedItemAddDialog from './DocumentRelatedItemAddDialog';
import { useAddDocumentRelatedItems } from 'src/hooks/documentationLibraries/useAddDocumentRelatedItems';

interface DocumentRelatedItemsProps {
  documentationId: string;
  readOnly?: boolean;
  documentationName?: string;
}

const DocumentRelatedItems: FC<DocumentRelatedItemsProps> = ({ documentationId, documentationName, readOnly }) => {
  const { removeDocumentRelatedItem } = useDeleteDocumentRelatedItem();
  const { addDocumentRelatedItems } = useAddDocumentRelatedItems();
  
  const [relatedItemAddDialogVisible, setRelatedItemAddDialogVisible] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const [excludedIds, setExcludedIds] = useState<string[]>([]);

  const handleDelete = async (relatedItem: Attachment) => {
    if (!(relatedItem.recordId && relatedItem.documentationId)) return;

    const { responseData, responseMessage } = await removeDocumentRelatedItem(relatedItem.documentationId, relatedItem.recordId);

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleAddClick = () => {
    setRelatedItemAddDialogVisible(true);
  };

  const handleSave = async (recordIds: string[], recordType: RecordType) => {
    const payload = {
      documentationId,
      recordIds,
      recordType
    }

    const { responseData, responseMessage} = await addDocumentRelatedItems(payload);

    if(responseData) {
      setRelatedItemAddDialogVisible(false);
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleCancel = () => {
    setRelatedItemAddDialogVisible(false);
  };

  return (
    <>
      <div>
        <div className="flex justify-end mb-4">
          {!readOnly && (
            <Button variant="contained" onClick={handleAddClick}>
              Add
            </Button>
          )}
        </div>

        <DocumentRelatedItemsGrid
          documentationId={documentationId}
          onDelete={handleDelete}
          setExcludedIds={setExcludedIds}
          readOnly={readOnly}
        />
      </div>

      <DocumentRelatedItemAddDialog
        visible={relatedItemAddDialogVisible}
        onSave={handleSave}
        onCancel={handleCancel}
        documentationName={documentationName}
        excludedIds={excludedIds}
        readOnly={readOnly}
      />

      {/* <RelatedItemEditDialog
        visible={editPopupVisible}
        initialValue={selectedRowItem}
        onSave={editSave}
        onCancel={editCancel}
        tableName={tableName}
      /> */}

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default DocumentRelatedItems;
