import { FC, useState, useRef, useEffect, useCallback, SyntheticEvent } from 'react';
import { Box, Tabs, Tab, Button } from '@mui/material';
import Webcam from 'react-webcam';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { generateUniqueFileName } from 'src/modules/Attachments/component/utils';
import { LIBRARY, UPLOAD_URL } from 'src/consts';
import { DocumentationLibType, DocumentRevision } from 'src/generated/dotnet.graphql';
import DocRevDropZone from './DocRevDropZone';

interface FileUploadFormProps {
  setUploadedFile: any;
  uploadedFile: any;
  libraryType: DocumentationLibType;
  revision?: DocumentRevision;
  maxFiles?: number;
}
const TAB_LOCAL = 'TAB_LOCAL';
const TAB_CAMERA = 'TAB_CAMERA';

const VIEW_MODE_LIVE = 'VIEW_MODE_LIVE';
const VIEW_MODE_IMAGE = 'VIEW_MODE_IMAGE';

const FileUploadFormOld: FC<FileUploadFormProps> = ({
  setUploadedFile,
  uploadedFile,
  libraryType,
  revision,
  maxFiles = 0,
}) => {
  const [activeTab, setActiveTab] = useState(TAB_LOCAL);
  const webcamRef: any = useRef(null);
  const [photoData, setPhotoData] = useState<any>(null);
  const [viewMode, setViewMode] = useState(VIEW_MODE_LIVE);
  const [facingMode, setFacingMode] = useState(true);
  const [fileName, setFileName] = useState<string>();

  useEffect(() => {
    if (photoData) {
      handleUpload();
    }
  }, [photoData]);

  const DataURIToBlob = (dataURI: string) => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  const handleUpload = async () => {
    const myHeaders = new Headers();
    myHeaders.append('x-upload-path', 'images');

    const formdata = new FormData();
    formdata.append('Content-Type', 'multipart/form-data');
    formdata.append('x-upload-path', 'images');
    formdata.append('attachment', DataURIToBlob(photoData), fileName);
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
    };

    const response = await fetch(UPLOAD_URL, requestOptions);
    const body = await response.text();
    console.log('body', body)
    setUploadedFile(JSON.parse(body));
  };

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const handleCapture = useCallback(() => {
    if (webcamRef?.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setViewMode(VIEW_MODE_IMAGE);
      setPhotoData(imageSrc);
      setFileName(generateUniqueFileName());
    }
  }, [webcamRef]);

  const handleSwitch = () => {
    setFacingMode(!facingMode);
  }

  const videoConstraints = {
    width: 400,
    height: 400,
    facingMode: facingMode ? 'user' : 'environment',
  };

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="file-mode-tab"
        >
          <Tab label="My Files" value={TAB_LOCAL} />
          {libraryType === LIBRARY.PHOTOS.TYPE && <Tab label="Camera" value={TAB_CAMERA} />}
        </Tabs>
      </Box>
      {activeTab === TAB_LOCAL && (
        <Box>
          <DocRevDropZone
            setUploadedFile={setUploadedFile}
            uploadedFile={uploadedFile}
            libraryType={libraryType}
            revision={revision}
            maxFiles={maxFiles}
          />
        </Box>
      )}
      {activeTab === TAB_CAMERA && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <Webcam
            audio={false}
            height={400}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={400}
            style={{ display: viewMode === VIEW_MODE_LIVE ? 'block' : 'none' }}
            videoConstraints={videoConstraints}
          />
          {viewMode === VIEW_MODE_IMAGE && photoData && <img src={photoData} />}
          {viewMode === VIEW_MODE_LIVE && (
            <div className="flex items-center justify-center cursor-pointer	mt-2">
              <Button
                variant="contained"
                onClick={handleCapture}
              >
                Capture
              </Button>
              <div className="my-2 mx-4" onClick={handleSwitch}>
                <FlipCameraAndroidIcon />
              </div>
            </div>
          )}
          {viewMode === VIEW_MODE_IMAGE && (
            <Button
              sx={{ marginTop: '10px' }}
              variant="contained"
              onClick={() => setViewMode(VIEW_MODE_LIVE)}
            >
              Take Another
            </Button>
          )}
        </Box>
      )}
    </div>
  );
};

export default FileUploadFormOld;
