import { FC, useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import Autocomplete from 'src/components/UI/Forms/Autocomplete';
import { getDatabase } from 'src/rxdb';
import { toJSON } from 'src/utils';

type CheckListProps = {
  currentSchedKey?: string;
  onChange: (field: string, value: any) => void;
  placeholder?: string;
  control: Control;
  name: string;
  label?: string;
  variant?: string;
  size?: string;
};

const CheckListsDropDown: FC<CheckListProps> = ({
  currentSchedKey,
  placeholder,
  control,
  label,
  name,
  onChange,
  variant,
  size,
}) => {
  const [inspections, setInspections] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const init = async () => {
    const db = await getDatabase();

    db.tblschedmaint.find().$.subscribe((defaults) => {
      const inspections = defaults .filter((c) => c.fldSchedType === 'CHKL' && c.PKey !== currentSchedKey);
      setInspections(toJSON(inspections));
    })
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Autocomplete
      onChange={onChange}
      loading={loading}
      label={label}
      placeholder={placeholder}
      options={inspections}
      control={control}
      name={name}
      keyExpr="PKey"
      displayExpr="fldSubject"
      variant={variant}
      size={size}
      freeSolo={true}
    />
  )
};

export default CheckListsDropDown
