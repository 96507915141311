export enum AccessType {
  NO_ACCESS = 'no_access',
  FULL_ACCESS = 'full_access',
  READ_ONLY = 'read_only'
}

export const getAccessType = (permission: any): AccessType => {
    if (permission === 1) {
      return AccessType.READ_ONLY;
    }
    if (permission === 0) {
      return AccessType.NO_ACCESS;
    }
    if (permission === 2) {
      return AccessType.FULL_ACCESS;
    }
    return AccessType.FULL_ACCESS;
  };
