import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { DocumentationCollectionSegment, DocumentationFilterInput, DocumentationSortInput,  } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_DOCUMENTATIONS_BY_CATEGORY = gql`
  query GetLazyDocumentationsByCategory($filterInput: DocumentationFilterInput) {
    documentation(filterInput: $filterInput) {
      items {
        id
        addedBy
        categoryId
        completeReqd
        description
        docFigure
        docPage
        docTitle
        fileType
        libType
        number
        password
        received
        showInCentral
        isReport
        requiresVerification
        commentsCount
      }
    }
  }
`

interface DocumentationsByCategoryData {
  documentation: DocumentationCollectionSegment;
}

interface DocumentationsByCategoryResult {
  getLazyDocumentationsByCategory: LazyQueryExecFunction<DocumentationsByCategoryData, OperationVariables>;
}

export const useGetLazyDocumentationByCategory = (): DocumentationsByCategoryResult => {

  const [ getDocumentationsByCategory, { error }] = useLazyQuery(GET_LAZY_DOCUMENTATIONS_BY_CATEGORY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyDocumentationsByCategory').error('Error fetching documentations by category -->', error.message);
  }

  return {
    getLazyDocumentationsByCategory: getDocumentationsByCategory
  };
};