import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { Equipment } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_EQUIPMENT_BY_ID = gql`
  query GetEquipmentById($eqKey: String!) {
    equipmentById(eqKey: $eqKey) {
      id
      countHours
      hoursOffset
      locationId
      categoryId
      hours
      uniqueName
    }
  }
`
interface EquipmentByIdData {
  equipmentById: Equipment;
}

interface EquipmentByIdResult {
  getEquipmentById: LazyQueryExecFunction<EquipmentByIdData, OperationVariables>;
  loading: boolean;
}

export const useGetEquipmentById = (): EquipmentByIdResult => {

  const [ getEquipmentById, { loading, error }] = useLazyQuery(GET_EQUIPMENT_BY_ID, {
    fetchPolicy:"network-only",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetEquipmentById').error('Error fetching equipment by ID -->', error.message);
  }

  return {
    getEquipmentById: getEquipmentById,
    loading
  };
};