import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery } from "@apollo/client";
import { Equipment, EquipmentFilterInput, SortEnumType } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_EQUIPMENT_LIST = gql`
  query GetEquipmentList($filterInput: EquipmentFilterInput, $order: [EquipmentSortInput!]) {
    equipment(filterInput: $filterInput, order: $order) {
      items {
        id
        countHours
        hoursOffset
        locationId
        categoryId
        hours
        uniqueName
      }
    }
  }
`
interface EquipmentListData {
  equipment: {
    items: Equipment[];
  };
}

interface EquipmentListResult {
  getEquipmentList: LazyQueryExecFunction<EquipmentListData, OperationVariables>;
  loading: boolean;
}

export const useGetEquipmentList = ( filterInput: EquipmentFilterInput | undefined ): EquipmentListResult => {

  const [ getEquipmentList, { loading, error }] = useLazyQuery(GET_EQUIPMENT_LIST, {
    variables: { 
      filterInput, 
      order: { uniqueName: SortEnumType.Asc } },
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetEquipmentList').error('Error fetching equipment list for dropdown -->', error.message);
  }

  return {
    getEquipmentList: getEquipmentList,
    loading
  };
};