// @ts-nocheck
import { useCallback, useState, MutableRefObject, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import LicensedReactDataGrid from '../../../components/UI/LicensedReactDataGrid';
import { pick, get, size, isEmpty, isNil } from 'lodash';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TypeComputedProps, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import '../styles.css';
import { MangoQuerySortDirection } from 'rxdb/dist/types/types/rx-query';
import {exportCSV, exportExcel, getRegexByOperator} from '../../../utils';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useAppState } from 'src/contexts/app-state';
import { useAuth } from 'src/contexts/auth';

// Data
import { EquipmentDocument } from '../rxdb';
import { getDatabase, TDIDb } from '../../../rxdb';
import DeleteRecordBtnOld from '../../../components/DeleteRecordsButton/indexOld';
import { TblDdListDefaultsDocument } from '../../../api/queries/tblDDListDefaults/rxdb';
import GridRowAttachmentIcon from "../../../components/UI/LicensedReactDataGrid/components/GridRowAttachmentIcon";
import UpdateRecordButtonOld from 'src/components/UpdateRecordsButton/indexOld';
import { equipmentDeleteWarningMessage, UpdateRecordsOptionsTypeOld } from 'src/consts';

type EquipmentGridProps = {
  darken: boolean;
  onSelect: (item: EquipmentDocument) => void;
  sourceRoot?: boolean;
  moduleLevelSearch?: any;
};

const filter = [
  {
    name: 'UniqueName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'Manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'ModelNumber',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'Department',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const transformData = async (
  item: EquipmentDocument,
  workIssuesExpireDate,
  scheduleExpireDate
) => ({
  ...pick(item, [
    'EqKey',
    'UniqueName',
    'ModelNumber',
    'Department',
    'Manufacturer',
    'fldExpireDate',
    'Hours',
    'fldCountHours',
  ]),
  // Department: (await eqitem.populate("Department"))?.fldMember,
  workIssuesDueDate: workIssuesExpireDate[item.EqKey] || null,
  fldDateTrigger: scheduleExpireDate[item.EqKey] || null,
  original: item,
});

export const getSelectorByFilterName = async (
  name: string,
  value: string | string[],
  operator: string,
  db: TDIDb
) => {
  switch (name) {
    case 'EqKey': {
      return {
        EqKey: { $in: value },
      };
    }
    case 'UniqueName':
      return {
        UniqueName: { $regex: getRegexByOperator(operator, value) },
      };

    case 'Manufacturer':
      return {
        Manufacturer: { $regex: getRegexByOperator(operator, value) },
      };

    case 'ModelNumber':
      return {
        ModelNumber: { $regex: getRegexByOperator(operator, value) },
      };

    case 'Department': {
      return {
        Department: { $regex: getRegexByOperator(operator, value) },
      };
    }

    default:
      return {};
  }
};
let currentCount = 0;

const loadData = async ({
  skip,
  limit,
  filterValue,
  sortInfo,
}: {
  sortInfo: TypeSortInfo;
  skip: number;
  limit: number;
  filterValue: any;
}): Promise<{ data: any[]; count: number }> => {
  const db = await getDatabase();
  let preSort = {
    UniqueName: 'asc' as MangoQuerySortDirection,
  };

  if (!isNil(sortInfo)) {
    if (sortInfo.name === 'UniqueName') {
      preSort = {
        [sortInfo.name]:
          sortInfo.dir === 1
            ? ('asc' as MangoQuerySortDirection)
            : ('desc' as MangoQuerySortDirection),
      };
    }
  }

  const sort = [
    {
      ...preSort,
    },
  ];

  // TODO: Handle filtervalue.OPERATOR
  let selector = {
    deletedBy: {
      $eq: null,
    },
  };

  await Promise.all(
    filterValue.map(async (v) => {
      if (isEmpty(v.value)) return v;

      const s = await getSelectorByFilterName(v.name, v.value, v.operator, db);

      selector = {
        ...selector,
        ...s,
      };
      return v;
    })
  );

  const rawData = await db.equipment.find({ selector }).exec();
  const length = size(rawData);
  currentCount = length;

  const items = await db.equipment
    .find({
      selector,
      skip,
      limit,
      sort,
    })
    .exec();
  const workIssuesList = await db.workissues
    .find({
      selector: {
        Completed: { $eq: false },
        deletedAt: { $eq: null },
      },
    })
    .exec();

  const scheduleEqList = await db.tblschedmainteq
    .find({
      selector: {
        deletedAt: { $eq: null },
      },
    })
    .exec();
  const workIssuesExpireDate = {},
    scheduleExpireDate = {};

  workIssuesList.forEach((item: any) => {
    if (workIssuesExpireDate[item.EqKey]) {
      if (workIssuesExpireDate[item.EqKey] > item.DateDue) {
        workIssuesExpireDate[item.EqKey] = item.DateDue;
      }
    } else {
      if (item.EqKey) {
        workIssuesExpireDate[item.EqKey] = item.DateDue || null;
      }
    }
  });

  scheduleEqList.forEach((item: any) => {
    if (scheduleExpireDate[item.EqKey]) {
      if (scheduleExpireDate[item.EqKey] < item.fldDateTrigger) {
        scheduleExpireDate[item.EqKey] = item.fldDateTrigger;
      }
    } else {
      if (item.EqKey) {
        scheduleExpireDate[item.EqKey] = item.fldDateTrigger || null;
      }
    }
  });

  const data = await Promise.all(items.map((item) => transformData(item, workIssuesExpireDate, scheduleExpireDate)));
  return { data, count: length };
};

export const RECORDS_UPDATE_OPTIONS: UpdateRecordsOptionsTypeOld = {
  MANUFACTURER: { tableName: 'Equipment', fieldName: 'Manufacturer', columnName: 'Manufacturer' },
  MODEL_NUMBER: { tableName: 'Equipment', fieldName: 'ModelNumber', columnName: 'Model Number' },
  CATEGORY: { tableName: 'Equipment', fieldName: 'fldSRHKey', columnName: 'Category' },
  LOCATION: { tableName: 'Equipment', fieldName: 'fldLocHierarchy', columnName: 'Location' },
  DEPARTMENT: { tableName: 'Equipment', fieldName: 'Department', columnName: 'Department' },
  RESTRICTED_TO_HOD: { tableName: 'Equipment', fieldName: 'fldRestricted', columnName: 'Restricted to HOD' },
  REQUIRED_BY_SMS: { tableName: 'Equipment', fieldName: 'fldSMS', columnName: 'Critical Equipment (SMS Required)' },
  SUPPLIER: { tableName: 'Equipment', fieldName: 'Supplier', columnName: 'Supplier' },
  MONITOR_HOURS: { tableName: 'Equipment', fieldName: 'fldCountHours', columnName: 'Monitor Hours' },
  REPORTING_TYPE: { tableName: 'Equipment', fieldName: 'fldReportingType', columnName: 'Reporting Type' },
}

interface EquipmentGridRef {
  handleExport: (type: string) => void;
}

const EquipmentGrid = forwardRef<EquipmentGridRef, EquipmentGridProps>(
  ({ onSelect, sourceRoot = false, moduleLevelSearch = null }: EquipmentGridProps, ref) => {
    const { user } = useAuth();
    const { settingsPersonal } = useAppState();
    const [loading, setLoading] = useState<boolean>(false);
    const highlightedRowId = useRef<string>('-1');
    const [gridRef, setGridRef] = useState<any>(null);
    const [collapsedGroups, setCollapsedGroups] = useState({});
    const [groupCollapsedStatus, setGroupCollapsedStatus] = useState(false);
    const [count, setCount] = useState(0);
    const [exportDialog, setExportDialog] = useState(null);
    const [rowSelected, setRowSelected] = useState(null);
    const [resetFilterOnModuleSwitch, setResetFilterOnModuleSwitch] = useState(false);

    const isTablet = useMediaQuery('(min-width: 700px) and (max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 420px)')

    const isActionable =
      Object.values(rowSelected || {}).filter((item: any) => item).length > 0;

    const handleSelectionChange = useCallback(({ selected }) => {
      setRowSelected(selected);
    }, []);

    useImperativeHandle(ref, () => ({
      handleExport: (type) => {
        handleDataExport(type);
      },
    }));
    const currentFilters = get(gridRef, ['current', 'computedFilterValue'], []);

    const handleGetCount = async () => {
      const db = await getDatabase();
      let selector = {
        deletedBy: {
          $eq: null,
        },
      };
      const length = size(await db.equipment.find({selector}).exec());
      setCount(length);
    };

    useEffect(() => {
      handleGetCount();
      if (!isNil(gridRef) && resetFilterOnModuleSwitch) {
        gridRef?.current?.setFilterValue(
          gridRef.current.computedFilterValue.filter((f) => f.name !== 'EqKey')
        );
        setResetFilterOnModuleSwitch(false);
      }
    }, [loading]);

    useEffect(() => {
      if (isNil(settingsPersonal) || isNil(gridRef)) return;

      if(moduleLevelSearch && moduleLevelSearch.length) {
        gridRef?.current?.setFilterValue([
          ...gridRef.current.computedFilterValue.filter(
            (f) => f.name !== 'EqKey'
          ),
          {
            name: 'EqKey',
            value: moduleLevelSearch,
          },
        ]);
      }
      else {
        setResetFilterOnModuleSwitch(true);
        if (!isNil(gridRef)) {
          gridRef?.current?.setFilterValue(
            gridRef.current.computedFilterValue.filter(
              (f) => f.name !== 'EqKey'
            )
          );
        }
      }

      // TODO: Please fix that, it creates subscription that's never unsubscribed
      init(gridRef);
    }, [settingsPersonal, gridRef, moduleLevelSearch]);

    // Find if any filters applied to grid
    const areFiltersActive = gridRef?.current.computedFilterValue.some(
      (f) => !isEmpty(f.value)
    );

    const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
      if (isNil(settingsPersonal)) return;
      const db = await getDatabase();
      // Always reload grid on new item / update / delete.
      return db.equipment.$.subscribe(async (ev) => {
        if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
          highlightedRowId.current = ev.documentId;
          ref.current?.reload();
        }
        if (ev.operation === 'DELETE') {
          highlightedRowId.current = '-1';
          ref.current?.reload();
        }
      });
    };

    const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
      setGridRef(ref);
    };

    const onLoadingChange = (status: boolean) => {
      // If loading completed - check if there any items that needs to be highlighted.
      if (!status && highlightedRowId.current !== '-1') {
        gridRef?.current?.scrollToId(highlightedRowId.current);
        gridRef?.current?.setSelectedById(highlightedRowId.current, true);
        highlightedRowId.current = '-1';
      }
      setLoading(status);
    };

    const dataSource = useCallback(loadData, []);

    const onRowClick = useCallback(
      ({ data }) => {
        if (Object.keys(rowSelected || {}).length < 2) {
          onSelect(data.original);
        }
      },
      [rowSelected]
    );

    const exportData = (type = 'CSV' | 'xlsx', withFilters = true) => {
      setExportDialog(null);

      switch (type) {
        case 'CSV':
          return onExportToCSV(withFilters);
        case 'xlsx':
          return onExportToExcel(withFilters);
        default:
      }
    };

    const onExportToExcel = async (withFilters: boolean) => {
      const { data } = await loadData({
        filterValue: withFilters ? currentFilters : [],
      });

      const columnsData = gridRef.current.visibleColumns.map((c: any) => ({
        header: c.header,
        key: c.id,
      }));
      const columns = columnsData.filter(item => {
        return item.header && typeof item.header === 'string';
      });

      const rows = data.map((data: any) =>
        pick(data, ['UniqueName', 'ModelNumber', 'Department', 'Manufacturer', 'Hours'])
      );

      return exportExcel(columns, rows);
    };

    const onExportToCSV = async (withFilters: boolean) => {
      const columns = gridRef.current.visibleColumns;

      const { data } = await loadData({
        filterValue: withFilters ? currentFilters : [],
      });
      const rows = data.map((data: any) =>
        pick(data, ['UniqueName', 'ModelNumber', 'Department', 'Manufacturer', 'Hours'])
      );

      return exportCSV(columns, rows);
    };

    const handleDataExport = (type = 'CSV' | 'xlsx') => {
      if (areFiltersActive) {
        setExportDialog({
          visible: true,
          type,
          title: type === 'CSV' ? 'CSV' : 'Excel',
        });

        return;
      }

      exportData(type, false);
    };

    const renderGroupTitle = (value, { data }) => {
      const columns = data.fieldPath.map((col) =>
        col === data.name ? col.toUpperCase() : col
      );
      const path = columns && columns.length && columns.join('>');
      return isNil(data.value) ? `No ${path} Assigned` : data.value;
    };

    const CollapseButton = () => {
      if (groupCollapsedStatus) {
        setGroupCollapsedStatus(false);
        return gridRef.current.collapseAllGroups();
      }
      if (!groupCollapsedStatus) {
        setGroupCollapsedStatus(true);
        return gridRef.current.expandAllGroups();
      }
    };

    const columns = [
      {
        id: 'icons',
        header: 'Icons',
        visible: !(isTablet || isMobile),
        defaultWidth: 65,
        render: ({ data }: any) => (
          <GridRowAttachmentIcon selector={{ fldRecordKey: data.EqKey }} />
        ),
        onRender: (cellProps: any, { data }: { data: any }) => {
          if (
            !isEmpty(data?.fldExpireDate) &&
            new Date().toISOString() > data?.fldExpireDate
          ) {
            cellProps.style.borderLeft = 'red 3px solid';
          } else if (
            !isEmpty(data?.workIssuesDueDate) &&
            new Date().toISOString() > data?.workIssuesDueDate
          ) {
            cellProps.style.borderLeft = 'red 3px solid';
          } else if (
            !isEmpty(data?.fldExpireDate) &&
            new Date().toISOString() < data?.fldExpireDate &&
            data?.fldExpireDate <= moment().add(14, 'days').toISOString()
          ) {
            cellProps.style.borderLeft = '#fcba03 3px solid';
          } else if (
            !isEmpty(data?.fldDateTrigger) &&
            new Date().toISOString() < data?.fldDateTrigger &&
            data?.fldDateTrigger <= moment().add(14, 'days').toISOString()
          ) {
            cellProps.style.borderLeft = '#fcba03 3px solid';
          } else {
            cellProps.style.borderLeft = '#e4e3e2 3px solid';
          }
        },
      },
      {
        name: 'UniqueName',
        header: 'Equipment Name',
        defaultFlex: 1,
      },
      {
        name: 'Manufacturer',
        header: 'Manufacturer',
        defaultFlex: 0.75,
        visible: !isMobile,
      },
      {
        name: 'ModelNumber',
        header: 'Model Number',
        defaultFlex: 0.75,
        visible: !isMobile,
      },
      {
        name: 'Department',
        header: 'Department',
        defaultFlex: 0.5,
        visible: !isMobile,
      },
      {
        name: 'Hours',
        header: 'Hours',
        defaultFlex: 0.4,
        visible: !(isTablet || isMobile),
        headerAlign: 'start' as any,
        textAlign: 'end' as any,
        render: ({ data }: any) => (
          data.fldCountHours ? data.Hours : null
        )
      }
    ];
  
    const footerRows = [
      {
        position: 'end',
        render: {
          icons: () => {
  
            const style = {
              paddingLeft: 20,
            };
  
            return (
              <div style={style}>
                Total Records: {currentCount}/{count}
              </div>
            );
          },
        },
        colspan: {
          icons: (_, computedProps) => computedProps.visibleColumns.length,
        },
      },
    ];

    return (
      <div data-testid="data-grid" className="flex flex-col flex-grow">
        <div className="flex flex-row items-center justify-end">
          {!isEmpty(gridRef?.current.computedGroupBy) &&
            (groupCollapsedStatus ? (
              <div>
                <Tooltip title="Collapse All">
                  <IconButton onClick={CollapseButton}>
                    <UnfoldLessIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div>
                <Tooltip title="Expand All">
                  <IconButton onClick={CollapseButton}>
                    <UnfoldMoreIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ))}

        {settingsPersonal?.fldEQ === 2 && (
          <>
            <UpdateRecordButtonOld
              visible={isActionable}
              records={rowSelected}
              onSelect={setRowSelected}
              options={RECORDS_UPDATE_OPTIONS}
            />
            <DeleteRecordBtnOld
              visible={isActionable}
              records={rowSelected}
              onSelect={setRowSelected}
              warningMessage={equipmentDeleteWarningMessage}
            />
          </>
        )}

        </div>
        <LicensedReactDataGrid
          onRowClick={onRowClick}
          onLoadingChange={onLoadingChange}
          defaultLimit={5000}
          livePagination
          onReady={onReady}
          rowHeight={40}
          defaultGroupBy={[]}
          onGroupByChange={() => gridRef.current.collapseAllGroups()}
          collapsedGroups={collapsedGroups}
          onGroupCollapseChange={setCollapsedGroups}
          loading={loading}
          enableSelection
          defaultFilterValue={filter}
          defaultActiveIndex={3}
          idProperty="EqKey"
          columns={columns}
          dataSource={dataSource}
          renderGroupTitle={renderGroupTitle}
          footerRows={footerRows}
          selected={rowSelected}
          onSelectionChange={handleSelectionChange}
          checkboxColumn
          sourceRoot={sourceRoot}
        />

        <Dialog maxWidth="xs" open={exportDialog?.visible || false}>
          <DialogTitle>
            Export data to
            {exportDialog?.title}
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              You have filters applied. Would you like to export with current
              filters?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              autoFocus
              onClick={() => exportData(exportDialog?.type, false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => exportData(exportDialog?.type)}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);

export default EquipmentGrid;
