import { gql, useQuery  } from "@apollo/client";
import { GridSavedView } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_GRID_SAVED_VIEWS = gql`
  query GetGridSavedViews($moduleName: String!) {
    gridSavedViews(moduleName: $moduleName) {
      id
      layout
      module
      viewName
    }
  }
`

interface GridSavedViewsResult {
  gridSavedViews: GridSavedView[];
  gridSavedViewsLoading: boolean;
  gridSavedViewsCount: number;
}

export const useGetGridSavedViews = (moduleName: string): GridSavedViewsResult => {

  if (!moduleName) {
    return {
      gridSavedViews: [],
      gridSavedViewsLoading: false,
      gridSavedViewsCount: 0
    };
  }

  const { data, loading, error } = useQuery(GET_GRID_SAVED_VIEWS, {
    variables: { moduleName },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetGridSavedViews').error('Error fetching grid saved views for this module -->', error.message);
  }

  return {
    gridSavedViews: data?.gridSavedViews,
    gridSavedViewsLoading: loading,
    gridSavedViewsCount: data?.gridSavedViews.length
  };
};

  