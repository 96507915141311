import { ApolloQueryResult, gql, useLazyQuery } from "@apollo/client";
import { CollectionSegmentInfo, EquipmentScheduleFilterInput, EquipmentScheduleSortInput, EquipmentSchedule } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_EQUIPMENT_SCHEDULES = gql`
  query GetEquipmentSchedules($skip: Int, $take: Int, $filterInput: EquipmentScheduleFilterInput, $order: [EquipmentScheduleSortInput!]) {
    equipmentSchedules(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        id
        subject
        equipment {
          id
          uniqueName
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface EquipmentSchedulesResult {
  data: EquipmentSchedule[];
  currentCount: number;
  totalCount: number;
  pageInfo: CollectionSegmentInfo;
  loading: boolean;
  loadData: (skip: number, take: number,  filterInput: EquipmentScheduleFilterInput, order: EquipmentScheduleSortInput[]) => void;
}

export const useGetLazyEquipmentSchedules = (): EquipmentSchedulesResult => {
  const [getEquipmentSchedules, { data, loading, error }] = useLazyQuery(GET_EQUIPMENT_SCHEDULES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const loadData = (skip: number, take: number, filterInput: EquipmentScheduleFilterInput, order: EquipmentScheduleSortInput[]) => {
    logger('GetEquipmentSchedules').debug('Query variables', {skip, take, filterInput, order});   

    getEquipmentSchedules({ variables: { skip, take, filterInput, order }})
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.equipmentSchedules) {
          const { items, pageInfo } = result.data.equipmentSchedules;
          logger('GetEquipmentSchedules').info('Equipment schedules fetched successfully', {
            recordsCount: items?.length,
            pageInfo: pageInfo,
          });
        } else {
          logger('GetEquipmentSchedules').warning('No data received in query result');
        }
      }).catch((apolloError) => {
        logger('GetEquipmentSchedules').error('Error re-fetching equipment schedules -->', apolloError.message);
      });
  };
  
  if (error) {
    logger('GetEquipmentSchedules').error('Error fetching equipment schedules -->', error.message);
  }

  return {
    data: data?.equipmentSchedules?.items || [],
    totalCount: data?.equipmentSchedules?.totalCount || 0,
    currentCount: data?.equipmentSchedules?.items?.length || 0,
    pageInfo: data?.equipmentSchedules?.pageInfo,
    loading,
    loadData,
  };
};