import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery, useQuery } from "@apollo/client";
import { Documentation } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_DOCUMENTATION_BY_TITLE = gql`
  query GetLazyDocumentationByDocTitle($docTitle: String!) {
    documentationByDocTitle(docTitle: $docTitle) {
      id
      docTitle
    }
  }
`

export interface DocumentationByTitleData {
  documentationByDocTitle: Documentation;
};

interface DocumentationByTitleResult {
  getLazyDocumentationByTitle: LazyQueryExecFunction<DocumentationByTitleData, OperationVariables>;
}

export const useGeLazyDocumentationByTitle = (): DocumentationByTitleResult => {

  const [ getDocumentationByTitle, { error }] = useLazyQuery(GET_LAZY_DOCUMENTATION_BY_TITLE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyDocumentationByDocTitle').error(`Error fetching documentation -->`, error.message);
  }

  return {
    getLazyDocumentationByTitle: getDocumentationByTitle
  };
};
