// React components
import { FC, useState } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { isArray, isNil, last } from 'lodash';
// Third party components
import { Accordion, AccordionDetails, AccordionSummary, Alert, FormGroup, useMediaQuery } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
// TDI components
import RichTextEditor from 'src/components/UI/Forms/RichTextEditor';
import Checkbox from 'src/components/UI/Forms/Checkbox';
import DatePicker from 'src/components/UI/Forms/DatePicker';
import Input from 'src/components/UI/Forms/Input';
import Select from 'src/components/UI/Forms/Select';
import MultiSelect from 'src/components/UI/Forms/MultiSelect';
import ListDefaultAutocomplete from 'src/components/UI/Forms/ListDefaultAutocomplete';
import CategoryDropdown from 'src/components/Dropdowns/CategoryDropdown/index';
import LocationDropdown from 'src/components/Dropdowns/LocationDropdown';
// Utils
import { useAppState } from 'src/contexts/app-state';
import { isValidDateFormat } from 'src/utils/format-dates';
// GraphQL
import { LogEntry, RecordType, SortEnumType } from 'src/generated/dotnet.graphql';
import { useGetCrewNames } from 'src/hooks/crewNames/useGetCrewNames';
import EquipmentDropdown from 'src/components/Dropdowns/EquipmentDropdown';
import CurrenciesDropdown from 'src/components/Dropdowns/CurrenciesDropdown';
import CompaniesDropDown from 'src/components/Dropdowns/CompaniesDropdown';

interface LogEntrySummaryProps {
  initialValue: LogEntry;
  onChange: (field: string, value: any) => void;
  control: Control;
  watch: UseFormWatch<any>;
  isCreate: boolean;
  isNotCompletion?: boolean;
  readOnly?: boolean;
}

const LogEntrySummaryForm: FC<LogEntrySummaryProps> = ({
  initialValue,
  onChange,
  control,
  watch,
  isCreate,
  isNotCompletion,
  readOnly = false,
}) => {
  const { settingsPersonal } = useAppState();
  const isMobile = useMediaQuery('(max-width: 420px)');
  const { data } = useGetCrewNames({ order: [{ first: SortEnumType.Asc }, { last: SortEnumType.Asc }] });
  const [selectedEquipment, setSelectedEquipment] = useState(initialValue.equipment);
  const category = watch('categoryId', null);

  const handleEqDropdownOnChange = (field: string, value: any) => {
    onChange(field, value);
    setSelectedEquipment(value);
  }

  return (
    <div className="mt-3">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="general-information"
          id="general-information"
        >
          General Information
        </AccordionSummary>
        <AccordionDetails>
          {isCreate && selectedEquipment?.hoursOffset && selectedEquipment.countHours && (
            <Alert severity="info">{`${selectedEquipment.uniqueName} has an hours offset of ${selectedEquipment.hoursOffset}. This offset will be applied to the hours you enter after clicking 'Create'.`}</Alert>
          )}
          <div className="mt-3 flex flex-col md:flex-row">
            <DatePicker
              views={['year', 'month', 'day']}
              rules={{
                required: '*Date value is required for this field!',
                validate: (value) =>
                  isValidDateFormat(value) ||
                  'Please enter a valid date format dd-MMM-yyyy',
              }}
              name="logDate"
              control={control}
              label="Date Completed"
            />
            {selectedEquipment?.countHours && (
              <div className={isMobile ? 'mt-3' : 'ml-3 w-1/4'}>
                <Input
                  control={control}
                  name="hours"
                  inputProps={{
                    label: 'Equipment Hours',
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        '& .MuiOutlinedInput-input': {
                          textAlign: 'right',
                        },
                      },
                    },
                  }}
                />
              </div>
            )}
          </div>

          <div className="mt-3">
            <CategoryDropdown
              disabled={!isNotCompletion || !isNil(initialValue.scheduleId)}
              name="categoryId"
              label="Category"
              control={control}
              onChange={onChange}
              recordType={RecordType.LogEntry}
              rules={{ required: true }}
              allDepts={settingsPersonal?.fldAllDepts > 0}
            />
          </div>

          <div className="mt-3">
            <EquipmentDropdown
              control={control}
              onChange={handleEqDropdownOnChange}
              disabled={!isNotCompletion || !isNil(initialValue.scheduleId)}
              defaultValue={initialValue.equipmentId}
              label="Equipment"
              category={(isArray(category) ? last(category) : category) || null}
            />
          </div>

          <div className="mt-3">
            <LocationDropdown
              disabled={!isNotCompletion || !isNil(initialValue.scheduleId)}
              control={control}
              label="Location"
              name="locationId"
              onChange={onChange}
              recordType={RecordType.LogEntry}
              allDepts={settingsPersonal?.fldAllDepts > 0}
            />
          </div>

          <div className="my-4">
            <RichTextEditor
              control={control}
              name="html"
              onChange={onChange}
            />
          </div>

          <div className="mt-3">
            <FormGroup row>
              <Checkbox
                control={control}
                name="restricted"
                label="Restricted to HoD"
              />
              <Checkbox
                control={control}
                name="sms"
                label="Critical Log Entry (SMS Required)"
              />
            </FormGroup>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className="mt-3" defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="additional-details"
          id="additional-details"
        >
          Additional Details
        </AccordionSummary>
        <AccordionDetails>
          <div className="mt-3">
            <MultiSelect
              control={control}
              name="performedBy"
              keyExpr="performedBy"
              displayExpr="name"
              options={data}
              rules={{ required: 'Performed By is required!' }}
              label="Performed By"
              onChange={onChange}
            />
          </div>

          <div className="mt-6">
            <ListDefaultAutocomplete
              disabled={readOnly}
              onChange={onChange}
              control={control}
              name="department"
              label="Department"
              listName="Department"
            />
          </div>

          <div className="mt-6">
            <ListDefaultAutocomplete
              disabled={readOnly}
              onChange={onChange}
              control={control}
              name="workList"
              label="Worklist Name"
              listName="Work List Names"
            />
          </div>

          <div className="mt-6">
            <CompaniesDropDown
              control={control}
              label="Company/Contractor"
              name="company"
              onChange={onChange}
            />
          </div>

          <div className="mt-6">
            <div className="flex flex-row items-center">
              <Input
                control={control}
                name="time"
                inputProps={{
                  type: 'number',
                  inputProps: { min: 1 },
                  label: 'Time to Complete',
                  className: 'mr-6',
                  sx: {
                    '& .MuiOutlinedInput-root': {
                      '& .MuiOutlinedInput-input': {
                        textAlign: 'right',
                      },
                    },
                  },
                }}
              />
              <Select
                control={control}
                name="timeQty"
                options={['Hours', 'Minutes']}
              />
            </div>
          </div>

          <div className="mt-6 flex flex-col md:flex-row">
            <Input
              control={control}
              name="cost"
              inputProps={{
                label: 'Cost',
                sx: {
                  marginRight: 7,
                  '& .MuiOutlinedInput-root': {
                    '& .MuiOutlinedInput-input': {
                      textAlign: 'right',
                    },
                  },
                },
              }}
            />

            <CurrenciesDropdown
              label="Currency"
              onChange={onChange}
              control={control}
              name="curr"
            />
          </div>

          <div className="mt-6">
            <Checkbox
              control={control}
              name="isWarranty"
              label="Warranty Item"
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default LogEntrySummaryForm;
