// React Components
import { FC, useEffect, useState } from 'react';
import { isNil, isEmpty } from 'lodash';
import { Control, UseFormWatch } from 'react-hook-form';

// Style
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Checkbox as MaterialCheckbox, FormControlLabel } from '@mui/material';

// TDI Components
import Checkbox from 'src/components/UI/Forms/Checkbox';
import Input from 'src/components/UI/Forms/Input';
import Autocomplete from '../../../UI/Forms/Autocomplete';
import WeeklyScheduleForm from 'src/components/dataentry/EquipmentSchedulesDE/ScheduleComponent/WeeklySchedule';
import DailyScheduleForm from 'src/components/dataentry/EquipmentSchedulesDE/ScheduleComponent/DailySchedule';
import MonthlyScheduleForm from 'src/components/dataentry/EquipmentSchedulesDE/ScheduleComponent/MonthlySchedule';
import YearlyScheduleForm from 'src/components/dataentry/EquipmentSchedulesDE/ScheduleComponent/YearlySchedule';
import EventBasedSchedule from 'src/components/dataentry/EquipmentSchedulesDE/ScheduleComponent/EventBasedSchedule';
import { ScheduleOptions } from 'src/consts';

interface ScheduleProps {
  form: any;
  initialValue: any;
  onChange: (field: string, value: any) => void;
  control: Control;
  watch: UseFormWatch<any>;
  getValues: any;
  disableEdit?: boolean;
  setValue: any;
}

const ScheduleForm: FC<ScheduleProps> = ({
  form,
  onChange,
  initialValue,
  control,
  watch,
  setValue,
  getValues,
  disableEdit = false,
}) => {
  const [showLimitIterations, setShowLimitIterations] = useState<boolean>(initialValue?.fldIterations > 0);
  const [showRemindInfo, setShowRemindInfo] = useState<boolean>(false);
  const [showSimpleCheckbox, setShowSimpleCheckbox] = useState(true);
  const [disableRemindMe,setDisableRemindMe] = useState(true)
  const fldHourInterval = watch("fldHourInterval");
  const scheduleType = watch("scheduleType");

  useEffect(()=>{
    if (!isNil(initialValue.fldHoursTrigger)){
      setDisableRemindMe(false)
    }
  })
  useEffect(()=>{
    if (isEmpty(fldHourInterval) || parseInt(fldHourInterval,10) <= 0){
      setDisableRemindMe(true)
    } else {
      setDisableRemindMe(false)
    }
  },[fldHourInterval])
  
  useEffect(()=>{
    if(scheduleType?.scheduleType === 'Hours Only'){
      // TODO: Make it better
      // Currently when you switch from Hours only to Daily and back, RecurType will stay as Daily...
      // Fix that by resetting
      setValue('RecurType', 0);
      setValue('RecurPattern', undefined);
      setValue('RecurInterval', undefined);
      setShowSimpleCheckbox(false)
    } else 
    if (scheduleType?.scheduleType === "Event Based"){
      setShowSimpleCheckbox(false)
    } else {
      setShowSimpleCheckbox(true)
    }
  },[scheduleType])

  const handleNumberIterationsChange = (e: any) => {
    const checked = e.target.checked;
    setShowLimitIterations(checked);

    const iterations = initialValue?.fldIterations >= 0 ? initialValue.fldIterations : null;

    onChange('fldIterations', checked ? iterations : null)
  }

  return (
    <div><div>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <Autocomplete
            onChange={onChange}
            label={"Schedule Pattern"}
            options={ScheduleOptions}
            control={control}
            name={"scheduleType"}
            keyExpr="id"
            displayExpr="scheduleType"
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={6}>
          {showSimpleCheckbox && !isNil(getValues('scheduleType')) && 
            <Checkbox
              control={control}
              name="simpleCheck"
              label="Simple"
            />
          }
        </Grid>
      </Grid>
    </div>
    {scheduleType?.id === "0" || scheduleType === "Daily"
      ? <div>
          <DailyScheduleForm
            watch={watch}
            control={control}
            initialValue={initialValue}
            form={form}
            onChange={onChange}
            getValues={getValues}
            setValue={setValue}
            disableEdit={disableEdit}
          />
        </div>
      : null
    }
    { scheduleType?.id === "1" || scheduleType === "Weekly" ? <div>
      <WeeklyScheduleForm
        watch={watch}
        control={control}
        initialValue={initialValue}
        form={form}
        onChange={onChange}
        getValues={getValues}
        setValue={setValue}
        disableEdit={disableEdit}
      />

    </div>: null}
    {scheduleType?.id === "2" || scheduleType === "Monthly"
      ? <div>
          <MonthlyScheduleForm
            watch={watch}
            control={control}
            initialValue={initialValue}
            form={form}
            onChange={onChange}
            getValues={getValues}
            setValue={setValue}
            disableEdit={disableEdit}
          />
        </div>
      : null
    }
    {scheduleType?.id === "3" || scheduleType === "Yearly"
      ? <div>
          <YearlyScheduleForm
            watch={watch}
            control={control}
            initialValue={initialValue}
            form={form}
            onChange={onChange}
            getValues={getValues}
            setValue={setValue}
            disableEdit={disableEdit}
          />
        </div>
      : null
    }
    {scheduleType?.id === "4" || scheduleType === "Event Based"
      ? <div>
          <EventBasedSchedule
            watch={watch}
            control={control}
            initialValue={initialValue}
            form={form}
            onChange={onChange}
            getValues={getValues}
            setValue={setValue}
            disableEdit={disableEdit}
          />
        </div>
      : null
    }
      <div className="pt-2">
        <Box display={"flex"} alignItems="center" gap={2}>
          <Box flex={1}>
            <FormControlLabel
              control={
                <MaterialCheckbox checked={showLimitIterations} onChange={handleNumberIterationsChange} />
              }
              label="Limit number of iterations"
            />
          </Box>
        </Box>
        <div className="pt-2">
          {showLimitIterations && (
            <Box display={"flex"} alignItems="center" gap={2}>
              <Box flex={1} maxWidth="30%">
                <Input
                  name="fldIterations"
                  inputProps={{
                    label: 'Perform this task',
                    type:'number',
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        '& .MuiOutlinedInput-input': {
                          textAlign: 'right',
                        },
                      },
                    },
                  }}
                  control={control}
                />
              </Box>
              <Box flex={1}>times (empty means unlimited)</Box>
            </Box>
          )}
        </div>
      </div>
      <Dialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={showRemindInfo}
        onClose={() => setShowRemindInfo(false)}
      >
        <DialogContent dividers>
          Fill in the drill hours that should warn you the DRILL is due before the due hours are reached.  This will put the task on the work list early, but not yet due.
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button onClick={() => setShowRemindInfo(false)}>
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  )
};

export default ScheduleForm;
