import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Portal, Snackbar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { IMoveItemType } from './CheckListTab';
import { SchedMaintDocument } from 'src/rxdb/collections/SchedMaint/schema';
import CheckListsDropDown from 'src/components/Dropdowns/CheckListsDropdown';

interface InjectedProps {
  currentSchedKey?: string;
  initialValue?: IMoveItemType;
  visible: boolean;
  onCancel?: () => void;
  onSubmit: (payload: IMoveItemType) => void;
  saveLoading: boolean;
  disableCheckbox?: boolean;
}

const CheckListMovePopup: FC<InjectedProps> = ({
  currentSchedKey,
  initialValue,
  visible,
  onCancel,
  onSubmit,
  saveLoading,
}) => {
  const {
    control,
    handleSubmit,
  } = useForm<any>({
    mode: 'onBlur',
    shouldFocusError: true,
  });

  const [alert, setAlert] = useState(false);
  const [schedMaint, setSchedMaint] = useState<SchedMaintDocument>();
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const onChange = async (name: string, value: any) => {
    setSchedMaint(value);
  };

  const onSaveClicked = async () => {

    const payload: any = {
      PKey: initialValue?.PKey,
      FKey: initialValue?.FKey,
      SchedMaintKey: schedMaint?.PKey,
      fldSubject: schedMaint?.fldSubject,
    };

    onSubmit(payload);
  };
  
  const onCancelClick = () => {
    setAlert(false);
    onCancel && onCancel();
  };

  return (
    <div>
      <form>
        <Dialog
          open={visible}
          onClose={onCancelClick}
          fullWidth
          maxWidth="sm"
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '16px' }}>
            <span className="ml-2">
              Move Check to another List
            </span>
            {onCancel ? (
              <IconButton
                aria-label="close"
                onClick={onCancelClick}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 14,
                  color: (theme) => theme.palette.grey[400],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent dividers style={{ maxHeight: '60vh' }}>
            <form
              id="moveCheckItem-popup"
              className="relative bg-white flex-grow"
              onSubmit={handleSubmit(onSaveClicked)}
            >
              <div className="bg-white h-full flex-grow">
                <div className="h-full">
                  <div className="mb-6">
                    <CheckListsDropDown
                      currentSchedKey={currentSchedKey}
                      control={control}
                      label="Select List"
                      name="CheckListMove"
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions sx={{ m: 0, p: 3 }}>
            <LoadingButton
              onClick={handleSubmit(onSaveClicked)}
              loading={saveLoading}
              className="ml-4 mr-3 w-32"
              variant="contained"
            >
              Save
            </LoadingButton>
            <Button onClick={onCancelClick} className="w-32">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Portal>
          <Snackbar
            open={snackBar.open}
            autoHideDuration={2000}
            onClose={onSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
              {snackBar.message}
            </Alert>
          </Snackbar>
        </Portal>
      </form>
    </div>
  );
};

export default CheckListMovePopup;
