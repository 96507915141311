import { FC, useCallback, useMemo, useState } from 'react';
import { Alert, CircularProgress } from '@mui/material';
import { isEmpty, last } from 'lodash';
import { useDropzone } from 'react-dropzone';
import paperClip from '../../../assets/icon-paperclip.svg';
import { DOWNLOAD_URL, FileExtensionType, UPLOAD_URL } from '../../../consts';
import { Attachment, AttachmentType } from 'src/generated/dotnet.graphql';

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#1185df',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

interface InjectedProps {
  setUploadedFile: any;
  uploadedFile: any;
  attachmentType: AttachmentType;
  document?: Attachment;
  maxFiles?: number;
}

const FileDropZone: FC<InjectedProps> = ({
  document,
  attachmentType,
  uploadedFile,
  setUploadedFile,
  maxFiles = 0,
}) => {
  const [uploadFailAlert, setUploadFailAlert] = useState(false);
  const [wrongFileAlert, setWrongFileAlert] = useState(false);
  const [waitUploading, setWaitUploading] = useState(false);

  const uploadFiles = async (file: any) => {
    setWaitUploading(true);
    const path = attachmentType === AttachmentType.Photo ? 'images' : 'attachments';
    const myHeaders = new Headers();
    myHeaders.append('x-upload-path', path);

    const formdata = new FormData();
    formdata.append('Content-Type', 'multipart/form-data');
    formdata.append('x-upload-path', path);
    formdata.append('attachment', file, file.name);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
    };

    const extension: any = last(file.name.split('.'));

    const photoExtensionAllowed = [
      FileExtensionType.JPEG,
      FileExtensionType.JPG,
      FileExtensionType.PNG,
      FileExtensionType.TIF,
      FileExtensionType.BMP,
    ].includes(extension.toUpperCase());

    if (attachmentType === AttachmentType.Photo && photoExtensionAllowed) {
      try {
        const response = await fetch(UPLOAD_URL, requestOptions);
        const body = await response.text();
        setUploadedFile(JSON.parse(body));
        setWaitUploading(false);
        setWrongFileAlert(false);
        setUploadFailAlert(false);
      } catch (e: any) {
        console.log('error', e);
        setWaitUploading(false);
        setUploadFailAlert(true);
      }
    } else if (attachmentType === AttachmentType.Document && !photoExtensionAllowed) {
      try {
        const response = await fetch(UPLOAD_URL, requestOptions);
        const body = await response.text();
        setUploadedFile(JSON.parse(body));
        setWrongFileAlert(false);
        setUploadFailAlert(false);
        setWaitUploading(false);
      } catch (e: any) {
        console.log('error', e);
        setWaitUploading(false);
        setUploadFailAlert(true);
      }
    } else {
      setWrongFileAlert(true);
      setWaitUploading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: any) => {
      uploadFiles(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, fileRejections } = useDropzone({ 
    onDrop, 
    maxFiles: 1,
    multiple: false,
   });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [isDragActive, isDragReject, isDragAccept]);

  const displayFileContent = () => {
    if (!isEmpty(uploadedFile.key) && uploadedFile) {
      if (attachmentType === AttachmentType.Document) {
        return (
          <div className="flex justify-center" key={uploadedFile.name}>
            <img
              src={paperClip}
              alt={uploadedFile.name}
              style={{ height: '30px' }}
            />
            <p className="text-gray-600 ml-1">{uploadedFile.name}</p>
          </div>
        );
      }
      return (
        <div className="flex justify-center" key={uploadedFile.name}>
          <img
            src={`${DOWNLOAD_URL}/${uploadedFile.key}`}
            alt={uploadedFile.name}
            style={{ height: '100px' }}
          />
        </div>
      );
    }

    if (document && document.fileName) {
      if (attachmentType === AttachmentType.Document) {
        if (document.fileName) {
          return (
            <div className="flex justify-center" key={document.fileName}>
              <img
                src={paperClip}
                alt={document.fileName}
                style={{ height: '30px' }}
              />
              <p className="text-gray-600 ml-1">{document.fileName}</p>
            </div>
          );
        }
        return (
          <div className="flex justify-center" key={document.docTitle}>
            <img
              src={paperClip}
              alt={document.docTitle}
              style={{ height: '30px' }}
            />
            <p className="text-gray-600 ml-1">{document.docTitle}</p>
          </div>
        );
      }
      return (
        <div className="flex justify-center" key={document.docTitle}>
          <img
            src={`${DOWNLOAD_URL}/${document.fileName}`}
            alt={document.docTitle}
            style={{ height: '100px' }}
          />
        </div>
      );
    }
  };

  const displayFileName = () => {
    if (uploadedFile.url) {
      return (
        <a href={uploadedFile.url} target="_blank" rel="noopener noreferrer">
          {uploadedFile.name}
        </a>
      );
    }
  };

  return (
    <>
      {uploadFailAlert && (
        <Alert severity="error" className="mb-5">
          Upload Fail
        </Alert>
      )}

      {wrongFileAlert && (
        <Alert severity="error" className="mb-5">
          {attachmentType === AttachmentType.Photo
            ? 'Only Photos are allowed here: JPG, JPEG, PNG, TIF, BMP. Upload other file types in the Attachments tab.'
            : 'Photos are not allowed here. Upload JPG, JPEG, PNG, TIF, BMP file types in the Photos tab.'
          }
        </Alert>
      )}

      {displayFileName()}

      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {waitUploading ? (
          <div className="flex items-center justify-center w-full">
            <CircularProgress />
          </div>
        ) : (
          <div className="w-full">
            {isDragActive ? (
              <p className="text-center my-2">Drop the file here ...</p>
            ) : (
              <p className="text-center my-2">
                Drag 'n' drop, or click to select an individual file
              </p>
            )}

            {displayFileContent()}
            
          </div>
        )}
      </div>
    </>
  );
};

export default FileDropZone;
