import './style.css';
import { useState } from 'react';
import { Alert, Snackbar, Button, AlertColor, Tooltip } from '@mui/material';
import DocumentRevisionsGrid from './DocumentRevisionsGrid';
import DocumentRevisionDialog from './DocumentRevisionDialog';
import { DocumentationLibType, DocumentRevision, DocumentRevisionInput } from 'src/generated/dotnet.graphql';
import { useUpsertDocumentRevision } from 'src/hooks/documentationLibraries/useUpsertDocumentRevision';
import { SEVERITY } from 'src/consts';
import { useDeleteDocumentRevision } from 'src/hooks/documentationLibraries/useDeleteDocumentRevision';

interface DocumentRevisionsProps {
  documentationId: string;
  libraryType: DocumentationLibType;
  isCreate: boolean;
  readOnly?: boolean;
}

interface IFile {
  contentType: string;
  extension: string;
  key: string;
  name: string;
  url: string;
}

const defaultFile = {
  contentType: '',
  extension: '',
  key: '',
  name: '',
  url: '',
};

const DocumentRevisions = ({ documentationId, libraryType, isCreate, readOnly }: DocumentRevisionsProps) => {
  const [uploadedFile, setUploadedFile] = useState<IFile>(defaultFile);
  const [revisionDialogVisible, setRevisionDialogVisible] = useState<boolean>(false);
  const [editableRevision, setEditableRevision] = useState<DocumentRevision>();
  const [isCreateRevision, setIsCreateRevision] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const { upsertDocumentRevision } = useUpsertDocumentRevision();
  const { removeDocumentRevision } = useDeleteDocumentRevision();

  const handleUpsert = async (data: DocumentRevision) => {
    const document: DocumentRevisionInput = {
      id: data.id,
      documentationId: data.documentationId || documentationId,
      fileName: data?.fileName || uploadedFile.key,
      notes: data?.notes,
      page: String(data?.page),
      revision: String(data?.revision),
      revisionDate: data?.revisionDate || new Date(),
    };
    
    const { responseData, responseMessage } = await upsertDocumentRevision(document, isCreateRevision, documentationId);

    if (responseData) {
      setUploadedFile(defaultFile);
      setEditableRevision(undefined);
      setRevisionDialogVisible(false);
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleDelete = async (revision: DocumentRevision) => {
    const { responseData, responseMessage } = await removeDocumentRevision(revision.id, documentationId);

    if (responseData) {
      setEditableRevision(undefined);
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleAddClick = () => {
    setIsCreateRevision(true);
    setEditableRevision(undefined);
    setRevisionDialogVisible(true);
  };

  const handleCancel = () => {
    setEditableRevision(undefined);
    setRevisionDialogVisible(false);
    setUploadedFile(defaultFile);
  };

  const onEdit = (data: DocumentRevision) => {
    setEditableRevision(data);
    setRevisionDialogVisible(true);
  };

  return (
    <>
      <div className="pt-2">
        <div className="flex justify-end mb-4">
        {isCreate ? (
          <Tooltip 
            title="Create Document to add Revision"
            placement="left"
            arrow
          >
            <span>
              <Button 
                variant="contained" 
                onClick={handleAddClick} 
                disabled={isCreate}
              >
                Add
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Button 
            variant="contained" 
            onClick={handleAddClick} 
            disabled={readOnly}
          >
            Add
          </Button>
        )}
        </div>

        <DocumentRevisionsGrid
          documentationId={documentationId}
          onEdit={onEdit}
          onDelete={handleDelete}
          readOnly={readOnly}
        />
      </div>

      <DocumentRevisionDialog
        visible={revisionDialogVisible}
        initialValue={editableRevision}
        onSubmit={handleUpsert}
        onCancel={handleCancel}
        setUploadedFile={setUploadedFile}
        uploadedFile={uploadedFile}
        libraryType={libraryType}
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default DocumentRevisions;
