import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { GroupListOfEquipmentAndLogEntry } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_LOG_ENTRIES_GROUPED_BY_EQUIPMENT = gql`
  query GetLazyLogEntriesGroupedByEquipment($filterById: String, $filterBy: LogEntryFilterBy!, $filterInput: LogEntryFilterInput!) {
    logEntriesGroupedByEquipment(filterById: $filterById, filterBy: $filterBy, filterInput: $filterInput) {
      count
      item {
        id
        uniqueName
      }
      list {
        id
        html
        logDate
      }
    }
  }
`

interface LogEntriesGroupedByEquipmentData {
  logEntriesGroupedByEquipment: GroupListOfEquipmentAndLogEntry[];
};

interface LogEntriesGroupedByEquipmentResult {
  getLazyLogEntriesGroupedByEquipment: LazyQueryExecFunction<LogEntriesGroupedByEquipmentData, OperationVariables>;
  getLazyLogEntriesGroupedByEquipmentLoading: boolean;
}

export const useGetLazyLogEntriesGroupedByEquipment = (): LogEntriesGroupedByEquipmentResult => {

  const [ getLogEntries, { loading, error }] = useLazyQuery(GET_LAZY_LOG_ENTRIES_GROUPED_BY_EQUIPMENT, {
    fetchPolicy:"network-only",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyLogEntriesGroupedBy').error('Error fetching log entries grouped by equipment -->', error.message);
  }

  return {
    getLazyLogEntriesGroupedByEquipment: getLogEntries,
    getLazyLogEntriesGroupedByEquipmentLoading: loading
  };
};