import React, { FC, useState } from 'react';
import Button from '@mui/material/Button';
import { Alert, Snackbar } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { isNil } from 'lodash';
import { TblSparesUsed } from 'src/generated/graphql';
import { ISpareFormPayload } from 'src/types/spares';
import { getDatabase } from 'src/rxdb';
import { TblSparesUsedDocument } from './rxdb';
import SparePopupOld from './components/SparePopup/indexOld';
import SpareLocationPopupOld from './components/LocationPopup/indexOld';
import { LogEntryDocument } from 'src/pages/LogEntryPage/rxdb';
import SpareGridOld from './components/SpareGrid/indexOld';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  issue?: WorkIssuesDocument;
  onDelete?: (spare: TblSparesUsedDocument) => Promise<void>;
  onCreate?: (spare: ISpareFormPayload, isCreate: boolean) => Promise<void>;
  spares?: TblSparesUsedDocument[];
  loading?: boolean;
  logEntry?: LogEntryDocument;
  isLogentry?: boolean;
  isReport?: boolean;
  disableEdit?: boolean;
  isLogentryCreation?: boolean;
}
type Severity = 'error' | 'success' | 'info' | 'warning' | undefined;

const SparesTabOld: FC<Props> = ({
  issue,
  onDelete,
  onCreate,
  spares,
  loading,
  isLogentry= false,
  logEntry,
  isReport = false,
  disableEdit = false,
  isLogentryCreation = false,
}) => {
  const [isFormVisible, setFormVisible] = useState<boolean>(false);
  const [editableItem, setEditableItem] = useState<TblSparesUsedDocument>();
  const [locationInfo, setLocationInfo] = useState<TblSparesUsedDocument>();
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<Severity>(undefined);
  const [isLocationVisible, setLocationVisible] = useState<boolean>(false);

  const handleAddClick = () => {
    setEditableItem(undefined);
    setFormVisible(true);
  };

  const onEdit = async (spare: TblSparesUsedDocument) => {
    setEditableItem(spare);
    setFormVisible(true);
  };

  const onLocation = (spare: TblSparesUsedDocument)=>{
    setLocationInfo(spare);
    setLocationVisible(true);
  }

  const handleDelete = async (spare: TblSparesUsedDocument) => {
    try {
      // If we have handler outside - dont do anything.
      // It's just simplification
      if (onDelete) {
        await spare.remove();
        await onDelete(spare);
      } else {
        await spare.remove();
      }

      setAlert(true);
      setAlertMessage('Spare Record successfully removed');
      setAlertSeverity('success');
    } catch (e: any) {
      setAlert(true);
      setAlertMessage(`${e.message}`);
      setAlertSeverity('error');
    }
  };
  const handleCreate = async (payload: ISpareFormPayload) => {
    const db = await getDatabase();

    try {
      const document: Partial<TblSparesUsed> = {
        PKey: payload.PKey || uuid(),
        DateEntered: new Date().toISOString(),
        Amount: payload.Amount,
        EqKey: issue?.EqKey || null,
        WorkKey: issue?.JobNumber,
        ProductID: payload.ProductID || '',
        LogKey: logEntry?.PKey || null,
      };

      // Probably wouldn't be saved in db for a while. So having another handler.
      if (onCreate) {       
        await onCreate(payload, isNil(payload.PKey));
      } else {
        await db.tblsparesused.upsert(document);
      }

      setFormVisible(false);
      setAlert(true);
      setAlertMessage('Spare Record successfully created');
      setAlertSeverity('success');
    } catch (e: any) {
      setFormVisible(false);
      setAlert(true);
      setAlertMessage(`${e.message}`);
      setAlertSeverity('error');
    }
  };

  const onFormCancel = () => {
    setEditableItem(undefined);
    setFormVisible(false);
    setLocationVisible(false);
  };

  return (
    <>
      <div>
        <div className="flex justify-end mb-4">
          {!isReport &&
            <Button 
              variant="contained" 
              startIcon={<AddIcon />}
              onClick={handleAddClick} 
              disabled={disableEdit}
            >
              Add
            </Button>
          }
        </div>
        <SpareGridOld
          onEdit={onEdit}
          onDelete={handleDelete}
          issue= {issue}
          onLocation={onLocation}
          referenceValue={isLogentry? logEntry?.PKey : ''}
          referenceKey={isLogentry? 'LogKey':''}
          isLogentryCreation={isLogentryCreation}
          spares={spares}
        />
      </div>

      <SparePopupOld
        initialValues={editableItem}
        onCancel={onFormCancel}
        onSubmit={handleCreate}
        visible={isFormVisible}
        issue={issue}
      />

      <SpareLocationPopupOld
        onCancel={onFormCancel}
        spare={locationInfo}
        visible={isLocationVisible}
      />

      <Snackbar
        open={alert}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setAlert(false);
        }}
      >
        <Alert severity={alertSeverity}>{alertMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default SparesTabOld;
