import 'src/components/dataentry/inventoryDE/component/style.css';
import { useState, FC, useEffect, useRef } from 'react';
import { IconButton } from '@mui/material';
import { DeleteTwoTone } from '@mui/icons-material';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import WarningDialog from 'src/components/UI/WarningDialog';
import { useGetDocumenRelatedItems } from 'src/hooks/documentationLibraries/useGetDocumentRelatedItems';
import { Attachment } from 'src/generated/dotnet.graphql';

interface DocumentRelatedItemsGridProps {
  documentationId: string;
  onDelete: (relatedItem: Attachment) => void;
  setExcludedIds: (excludedIds: string[]) => void;
  readOnly?: boolean;
}

const DocumentRelatedItemsGrid: FC<DocumentRelatedItemsGridProps> = ({ documentationId, onDelete, setExcludedIds, readOnly }) => {
  const { relatedItems, relatedItemsLoading } = useGetDocumenRelatedItems(documentationId);
  const [isDeletingWarningPopup, setIsDeletingWarningPopup] = useState(false);
  const [selectedRelatedItem, setSelectedRelatedItem] = useState<Attachment>();
  const prevRelatedItemsRef = useRef<Attachment[]>([]);

  useEffect(() => {
    const onRelatedItemsFetch = () => {
      const excludedIds = relatedItems
        .map((item) => item.recordId)
        .filter((recordId) => recordId !== undefined) as string[];
        
      // Only update if `relatedItems` has changed
      if (JSON.stringify(relatedItems) !== JSON.stringify(prevRelatedItemsRef.current)) {
        setExcludedIds(excludedIds);
        prevRelatedItemsRef.current = relatedItems;
      }
    };
    onRelatedItemsFetch();
  }, [relatedItems]);



  const handleDelete = (relatedItem: Attachment) => {
    setSelectedRelatedItem(relatedItem);
    setIsDeletingWarningPopup(true);
  };

  const handleDeleteOk = () =>{
    if(selectedRelatedItem) {
      onDelete(selectedRelatedItem);
      setIsDeletingWarningPopup(false);
      setSelectedRelatedItem(undefined);
    }
  };

  const handleDeleteCancel = () =>{
    setIsDeletingWarningPopup(false);
    setSelectedRelatedItem(undefined);
  };
  
  const columns = [
    {
      name: 'recordName',
      header: 'Associated Record',
      flex: 1,
    },
    {
      name: 'recordType',
      header: 'Module',
      flex: 1,
    },
    {
      id: 'actions',
      header: 'Actions',
      render: ({ data }: any) => (
        <div  className = "flex justify-center">
          {/* <IconButton
            onClick={() => rowEdit(data)}
            size="small"
            aria-label="Edit item"
          >
            <Edit fontSize="inherit" />
          </IconButton> */}
          <IconButton
            onClick={() => handleDelete(data)}
            size="small"
            color="error"
            aria-label="Delete item"
          >
            <DeleteTwoTone fontSize="inherit" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <>
      <div data-testid="data-grid" className="flex flex-col flex-grow attachment-grid">
        <LicensedReactDataGrid
          rowHeight={40}
          loading={relatedItemsLoading}
          showColumnMenuTool={false}
          idProperty="id"
          dataSource={relatedItems}
          columns={columns}
          enableSelection={false}
          disableGroupByToolbar
          defaultGroupBy={["recordType"]}
        />
      </div>

      <WarningDialog
        visible={isDeletingWarningPopup}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};

export default DocumentRelatedItemsGrid;
