import { ApolloQueryResult, gql, useQuery } from '@apollo/client';
import { LogEntry, CollectionSegmentInfo, LogEntryFilterInput, LogEntrySortInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_LOG_ENTRIES_WITH_COUNTS = gql`
  query GetLogEntriesWithCounts(
    $skip: Int
    $take: Int
    $filterInput: LogEntryFilterInput!
    $order: [LogEntrySortInput!]
  ) {
    logEntries(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        __typename
        id
        logDate
        scheduleId
        equipmentId
        cost
        curr
        company
        time
        timeQty
        hours
        performedBy
        enteredBy
        tsachk
        department
        crewId
        restricted
        isWarranty
        locationId
        dateEntered
        isCheckList
        workList
        html
        categoryId
        sms
        checks
        year
        month
        equipment {
          __typename
          id
          countHours
          hoursOffset
          hours
          uniqueName
          sms
        }
        photosCount
        documentsCount
        sparesUsedCount
        commentsCount
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export interface LogEntriesResult {
  data: LogEntry[];
  currentCount: number;
  totalCount: number;
  pageInfo: CollectionSegmentInfo;
  loading: boolean;
  loadData: (skip: number, take: number, filterInput: LogEntryFilterInput, order: LogEntrySortInput[]) => void;
}

export const useGetLogEntriesWithCounts = (skip: number, take: number, filterInput: LogEntryFilterInput, order: LogEntrySortInput[]): LogEntriesResult => {
  const { data, loading, error, refetch } = useQuery(GET_LOG_ENTRIES_WITH_COUNTS, {
    variables: { skip, take, filterInput, order },
    notifyOnNetworkStatusChange: true,
  });

  const loadData = ( skip: number, take: number, filterInput: LogEntryFilterInput, order: LogEntrySortInput[]) => {
    logger('GetLogEntriesWithCounts').debug('Query variables', { skip: skip, take: take, filterInput: filterInput, order: order });
    refetch({ skip: skip, take: take, filterInput: filterInput, order: order })
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.logEntries) {
          const { items, pageInfo } = result.data.logEntries;
          logger('GetLogEntriesWithCounts').info('Log entries fetched successfully', { recordsCount: items?.length, pageInfo: pageInfo });
        } else {
          logger('GetLogEntriesWithCounts').warning('No data received in query result');
        }
      })
      .catch((apolloError) => {
        logger('GetLogEntriesWithCounts').error('Error re-fetching log entries -->', apolloError.message);
      });
  };

  if (error) {
    logger('GetLogEntriesWithCounts').error('Error fetching log entries -->', error.message);
  }

  return {
    data: loading ? [] : data?.logEntries?.items || [],
    totalCount: data?.logEntries?.totalCount || 0,
    currentCount: data?.logEntries?.items?.length || 0,
    pageInfo: data?.logEntries?.pageInfo,
    loading,
    loadData,
  };
};
