import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty, isNil, isString } from 'lodash';
import { IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Autocomplete as MaterialAutocomplete, FormGroup, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from 'src/components/UI/Forms/Checkbox';
import { ATTACHMENTS, CHAR_LIMIT, LIBRARY, libTypeOptions } from 'src/consts';
import Input from 'src/components/UI/Forms/Input';
import DatePicker from 'src/components/UI/Forms/DatePicker';
import UploadValidationPopup from './UploadValidationPopup';
import { handleCharLimitWarning } from 'src/utils';
import { isValidDateFormat } from 'src/utils/format-dates';
import { Attachment, AttachmentInput, AttachmentType, Documentation } from 'src/generated/dotnet.graphql';
import FileUploadForm from './FileUploadForm';
import RichTextEditor from 'src/components/UI/Forms/RichTextEditor';
import { ExpandMore } from '@mui/icons-material';
import { useGeLazyDocumentationByTitle } from 'src/hooks/attachments/useGetLazyDocumentationByTitle';
import { defaultUploadedFile } from '..';

export interface IAttachmentPopupForm {
  id: string;
  documentationId: string;
  docTitle: any;
  revision: any;
  page: any;
  showInCentral: any;
  description: any;
  notes: any;
  revisionDate: any;
  fileName: any;
  libType: any;
  recordId: any;
  recordType: any;
};

const fileDefaultValue = {
  id: '',
  documentationId: '',
  docTitle: '',
  revision: 0,
  page: 0,
  showInCentral: false,
  description: '',
  notes: '',
  revisionDate: new Date(),
  recordKey: '',
  fileName: '',
  libType: '',
};

interface InjectedProps {
  visible: boolean;
  document?: Attachment;
  attachmentType: AttachmentType;
  setUploadedFile: any;
  uploadedFile: any;
  onCancel?: () => void;
  onSubmit: (payload: AttachmentInput) => void;
  saveLoading: boolean;
  maxFiles?: number;
  disableCheckbox?: boolean;
}

const AttachmentPopup: FC<InjectedProps> = ({
  visible,
  document,
  setUploadedFile,
  uploadedFile,
  attachmentType,
  onCancel,
  onSubmit,
  saveLoading,
  maxFiles = 0,
  disableCheckbox = false,
}) => {
  const { control, handleSubmit, reset, getValues, register, setValue, watch } = useForm<any>({ mode: 'onBlur', shouldFocusError: true });
  const { ref, ...inputProps } = register('docTitle', { required: 'Field required' });
  const docTitleInput = watch("docTitle");
  const [error, setError] = useState(false);
  const [libTypeSelected, setLibTypeSelected] = useState<any>(null);
  const [documentation, setDocumentation] = useState<Documentation>(); // state for checkTitle response
  const [showLibTypeDropdown, setShowLibTypeDropdown] = useState<boolean>(false);
  const [isCheckFormVisible, setIsCheckFormVisible] = useState<boolean>(false);
  const [emtyFileWarning, setEmtyFileWarning] = useState(false);
  const { DOCUMENTATION, DOCUMENT_REVISIONS } = CHAR_LIMIT;
  const { getLazyDocumentationByTitle } = useGeLazyDocumentationByTitle();
 
  const updateFormData = (payload: any) => {
    if (!isNil(payload)) {
      reset(payload);
    }
  };

  const setInitialValues = async () => {
    if (!document) return reset(fileDefaultValue);

    const payload: IAttachmentPopupForm = {
      id: document.id ?? '',
      documentationId: document.documentationId ?? '',
      docTitle: document.docTitle,
      revision: document.revision,
      page: document.page,
      showInCentral: document.showInCentral,
      description: document.description,
      notes: document.notes,
      revisionDate: document.revisionDate ? new Date(document.revisionDate) : null,
      fileName: document.fileName,
      libType: document.libType,
      recordId: document.recordId,
      recordType: document.recordType,
    };
    updateFormData(payload);
  };

  const setLibraryType = async () => {
    if (!document) {
      setLibTypeSelected(LIBRARY.VESSEL_REFERENCE.NAME);
      return;
    }

    if (document.showInCentral) {
      setShowLibTypeDropdown(true);
    }

    if (document.libType === LIBRARY.SMS.TYPE) {
      setLibTypeSelected(LIBRARY.SMS.NAME);
    } else if (document.libType === LIBRARY.PHOTOS.TYPE) {
      setLibTypeSelected(LIBRARY.PHOTOS.NAME);
    } else if (document.libType === LIBRARY.DRAWINGS.TYPE) {
      setLibTypeSelected(LIBRARY.DRAWINGS.NAME);
    } else if (!document.libType || document.libType === LIBRARY.VESSEL_REFERENCE.TYPE) {
      setLibTypeSelected(LIBRARY.VESSEL_REFERENCE.NAME);
    }
  };

  useEffect(() => {
    setInitialValues();
    setLibraryType();
  }, [document]);

  const checkTitle = async () => {
    if (!docTitleInput || docTitleInput === document?.docTitle) {
      return; // No need to validate if input is empty or unchanged.
    }

    try {
      const queryResult = await getLazyDocumentationByTitle({ variables: { docTitle: docTitleInput } });
      const existingDocumentation = queryResult.data?.documentationByDocTitle;

      if (existingDocumentation) {
        setIsCheckFormVisible(true);
        setDocumentation(existingDocumentation)
      }
    } catch (error) {
      console.error("Error checking title:", error);
    }
  };

  useEffect(() => {
    checkTitle();
  }, [docTitleInput]);

  const onChange = async (name: string, value: any) => {
    let shouldDirty = true;
    setValue(name, value, { shouldDirty: shouldDirty });
  };

  const updateFormAfterFileUpload = async () => {
    const currentDocTitle = document?.docTitle;
    let newDocTitle;
  
    if (isNil(currentDocTitle)) {
      const formDocTitle = getValues().docTitle;
      newDocTitle = isEmpty(formDocTitle) ? uploadedFile.name : formDocTitle;
    } else {
      newDocTitle = currentDocTitle;
    }

    setValue('docTitle', newDocTitle);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      if (!isEmpty(uploadedFile.name)) {
        updateFormAfterFileUpload();
      }
    };

    fetchData();
  }, [uploadedFile]);

  const handleChangeLibDropdown = (evt: any) => {
    setShowLibTypeDropdown(evt.target.checked);
  };

  const onSaveClicked = async (data: IAttachmentPopupForm) => {
    if (!uploadedFile.url && document && !document.fileName) {
      setEmtyFileWarning(true);
      return;
    }
    //Note: Add below code for check file is uploaded or not for add new
    if (isNil(document)) {
      if (uploadedFile.url === '') {
        setEmtyFileWarning(true);
        return;
      }
    }

    const libType = attachmentType === AttachmentType.Photo 
        ? 'PHOTOS'
        : isString(data.libType)
        ? data.libType
        : !isNil(data.libType)
        ? data.libType.type
        : null;

    const payload: AttachmentInput = {
      id: data.id || null,
      documentationId: data.documentationId || null,
      fileName: data.fileName,
      docTitle: data.docTitle,
      revision: data.revision || null,
      page: data.page || null,
      showInCentral: data.showInCentral,
      description: data.description,
      notes: data.notes,
      revisionDate: data.revisionDate,
      libType: libType,
      recordId: data.recordId || null,
      recordType: data.recordType || null,
    };

    onSubmit(payload);
    setLibTypeSelected(undefined);
    setEmtyFileWarning(false);
    setShowLibTypeDropdown(false);
    reset(fileDefaultValue);
  };

  const handleChange = (newData: any) => {
    setLibTypeSelected(newData);
    setValue('libType', newData);
    setError(!newData)
  };

  const onCancelClick = () => {
    reset(fileDefaultValue);
    setLibTypeSelected(undefined);
    setShowLibTypeDropdown(false);
    setEmtyFileWarning(false);
    onCancel && onCancel();
  };

  const handleOnCancel = () => {
    setUploadedFile(defaultUploadedFile);
    reset(fileDefaultValue);
    setIsCheckFormVisible(false);
  };

  const handleOnUpload = async () => {
    const formValues = await getValues();
    onSaveClicked(formValues)
    handleOnCancel();
    onCancelClick();
  };

  const handleOnLink = async () => {
    const formValues = await getValues();
    if (documentation) {
      const payload = {
        ...formValues, 
        documentationId: documentation.id
      }
      onSaveClicked(payload);
    }
    handleOnCancel();
    onCancelClick();
  };

  const showDropdown = attachmentType === AttachmentType.Document && showLibTypeDropdown;

  return (
    <div>
      <form>
        <Dialog
          open={visible}
          fullWidth
          maxWidth="md"
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '16px' }}>
            <span className="ml-2"> {attachmentType === AttachmentType.Photo ? ATTACHMENTS.ATTACH_PHOTOS : ATTACHMENTS.ATTACH_FILES}</span> 
            {onCancel 
              ? 
                <IconButton
                  aria-label="close"
                  onClick={onCancelClick}
                  sx={{
                    position: 'absolute',
                    right: 10,
                    top: 14,
                    color: (theme) => theme.palette.grey[400],
                  }}
                >
                  <CloseIcon />
                </IconButton>
             : null
            }
          </DialogTitle>

          <DialogContent dividers style={{ maxHeight: '60vh' }}>
            {emtyFileWarning && (
              <Alert severity="error" className="mb-5">
                File is empty
              </Alert>
            )}

            <form
              id="Attachment-popup"
              className="relative bg-white flex-grow"
              onSubmit={handleSubmit(onSaveClicked)}
            >
              <div className="bg-white h-full flex-grow">
                <div className="h-full">
                  <div className="mb-6">

                    <div className="mb-3">
                      <Input
                        name="docTitle"
                        inputProps={{
                          size: 'small',
                          label: 'Document Title',
                        }}
                        rules={{
                          required: 'Document Title Required!',
                          maxLength: DOCUMENTATION.docTitle,
                        }}
                        warning={(value) => handleCharLimitWarning(value, DOCUMENTATION.docTitle)}
                        control={control}
                      />
                    </div>
                    
                    {!disableCheckbox && 
                      <div>
                        <Checkbox
                          name="showInCentral"
                          control={control}
                          label="Include in Documentation Libraries"
                          onChange={handleChangeLibDropdown}
                        />
                      </div>
                    }

                    {showDropdown &&
                      <>
                        <Accordion 
                          defaultExpanded 
                          disableGutters
                          className='mt-2'
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="general-information"
                            id="general-information"
                          >
                            General Information
                          </AccordionSummary>

                          <AccordionDetails>
                            <div>
                              <MaterialAutocomplete
                                disablePortal
                                id="File-type"
                                value={libTypeSelected}
                                options={libTypeOptions}
                                onChange={(event, newValue: any) => { handleChange(newValue) }}
                                getOptionLabel={(option: any) => {
                                  if (typeof option === 'string') {
                                    return option;
                                  }
                                  return option.label;
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Library Type"
                                    autoComplete="off"
                                    size="small"
                                    error={error}
                                    helperText={error ? 'Library type is required' : ''}
                                  />
                                )}
                              />
                            </div>

                            <div className="mt-3">
                              <RichTextEditor
                                name="description"
                                control={control}
                                onChange={onChange}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        
                        <Accordion 
                          defaultExpanded 
                          disableGutters 
                          className='mt-2'
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="revision-information"
                            id="revision-information"
                          >
                            Revision Information
                          </AccordionSummary>

                          <AccordionDetails>
                            <FormGroup row>
                              <div className="mr-5">
                                <DatePicker
                                  name="revisionDate"
                                  control={control}
                                  label="Revision Date"
                                  rules={{ validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy' }}
                                />
                              </div>
                              <div className="mr-5">
                                <Input
                                  name="revision"
                                  control={control}
                                  rules={{ maxLength: DOCUMENT_REVISIONS.revision }}
                                  warning={(value) => handleCharLimitWarning(value, DOCUMENT_REVISIONS.revision)}
                                  inputProps={{
                                    size: 'small',
                                    label: 'Revision #',
                                    style: { textAlign: 'end' },
                                  }}
                                />
                              </div>
                              <div>
                                <Input
                                  name='page'
                                  control={control}
                                  rules={{ maxLength: DOCUMENTATION.page }}
                                  warning={(value) => handleCharLimitWarning(value, DOCUMENTATION.page)}
                                  inputProps={{
                                    size: 'small',
                                    label: 'Page #',
                                    style: { textAlign: 'end' },
                                  }}
                                />
                              </div>
                            </FormGroup>

                            <div className="mt-3">
                              <Input
                                name="notes"
                                control={control}
                                rows={2}
                                multiline={true}
                                inputProps={{
                                  size: 'small',
                                  label: 'Revision Notes',
                                }}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    }
                  </div>
                </div>
              </div>
              <UploadValidationPopup
                visible={isCheckFormVisible}
                onCancel={handleOnCancel}
                onLink={handleOnLink}
                onUpload={handleOnUpload}
              />
            </form>
            <FileUploadForm
              setUploadedFile={setUploadedFile}
              uploadedFile={uploadedFile}
              attachmentType={attachmentType}
              document={document}
              maxFiles={maxFiles}
            />
          </DialogContent>
          <DialogActions sx={{ m: 0, p: 3 }}>
            <LoadingButton
              onClick={handleSubmit(onSaveClicked)}
              loading={saveLoading}
              className="ml-4 mr-3 w-32"
              variant="contained"
            >
              Save
            </LoadingButton>
            <Button onClick={onCancelClick} className="w-32">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default AttachmentPopup;
