import { ApolloError, gql, useMutation } from '@apollo/client';
import { logger } from 'src/helpers/logger';
import { ProcedureDocumentMutationResponse } from './useUpsertProcedureDocument';
import { GET_PROCEDURE_DOCUMENT } from './useGetProcedureDocument';

export const DELETE_PROCEDURE_DOCUMENT = gql`
  mutation DeleteProcedureDocument($scheduleId: String!) {
    deleteProcedureDocument(scheduleId: $scheduleId) {
      attachment {
        id
        deletedAt
      }
    }
  }
`;

interface DeleteProcedureDocumentResult {
  removeProcedureDocument: (scheduleId: string) => Promise<ProcedureDocumentMutationResponse>;
  removeProcedureDocumentLoading: boolean;
}

export const useDeleteProcedureDocument = (): DeleteProcedureDocumentResult => {
  const [deleteProcedureDocument, { loading }] = useMutation(DELETE_PROCEDURE_DOCUMENT, {
    onError: (error: ApolloError) => {
      logger('DeleteProcedureDocument').error('Error deleting procedure document -->', error.message);
    }
  });

  const removeProcedureDocument = async (scheduleId: string): Promise<ProcedureDocumentMutationResponse> => {
    const response = await deleteProcedureDocument({ 
      variables: { scheduleId },
      refetchQueries: [{
        query: GET_PROCEDURE_DOCUMENT,
        variables: { scheduleId }
      }],
    });
    const responseData = response.data?.deleteProcedureDocument?.attachment;
    if (responseData) {
      logger('DeleteProcedureDocument').info('Procedure document deleted successfully', response.data);
      return {
        responseData,
        responseMessage: `Procedure document removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove Procedure document!`,
      };
    }
  };

  return { 
    removeProcedureDocument, 
    removeProcedureDocumentLoading: loading, 
  };
};
