import { ApolloQueryResult, gql, useLazyQuery } from "@apollo/client";
import { ScheduleFilterInput, ScheduleSortInput, Schedule } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";
import { SchedulesResult } from "../eventSchedules/useGetLazyEventSchedules";

export const GET_DRILL_SCHEDULES = gql`
  query GetDrillSchedules($skip: Int, $take: Int, $filterInput: ScheduleFilterInput, $order: [ScheduleSortInput!]) {
    drillSchedules(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        id
        subject
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

export const useGetLazyDrillSchedules = (): SchedulesResult => {
  const [getDrillSchedules, { data, loading, error }] = useLazyQuery(GET_DRILL_SCHEDULES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const loadData = (skip: number, take: number, filterInput: ScheduleFilterInput, order: ScheduleSortInput[]) => {
    logger('GetDrillSchedules').debug('Query variables', {skip, take, filterInput, order});   

    getDrillSchedules({ variables: { skip, take, filterInput, order }})
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.drillSchedules) {
          const { items, pageInfo } = result.data.drillSchedules;
          logger('GetDrillSchedules').info('Drill schedules fetched successfully', {
            recordsCount: items?.length,
            pageInfo: pageInfo,
          });
        } else {
          logger('GetDrillSchedules').warning('No data received in query result');
        }
      }).catch((apolloError) => {
        logger('GetDrillSchedules').error('Error re-fetching drill schedules -->', apolloError.message);
      });
  };
  
  if (error) {
    logger('GetDrillSchedules').error('Error fetching drill schedules -->', error.message);
  }

  return {
    data: data?.drillSchedules?.items || [],
    totalCount: data?.drillSchedules?.totalCount || 0,
    currentCount: data?.drillSchedules?.items?.length || 0,
    pageInfo: data?.drillSchedules?.pageInfo,
    loading,
    loadData,
  };
};