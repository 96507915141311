import { ApolloError, gql, useMutation } from '@apollo/client';
import { GridSavedViewInput, GridSavedView } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { useState } from 'react';
import { GET_GRID_SAVED_VIEWS } from './useGetGridSavedViews';

export const UPSERT_GRID_SAVED_VIEW = gql`
  mutation UpsertGridSavedView($input: GridSavedViewInput!) {
    upsertGridSavedView(input: $input) {
      view {
        id
        layout
        module
        viewName
      }
    }
  }
`;

export interface GridSavedViewMutationResponse {
  responseData?: GridSavedView;
  responseMessage: string;
}

interface UpsertGridSavedViewResult {
  upsertGridSavedView: (input: GridSavedViewInput, isCreate: boolean, moduleName: string) => Promise<GridSavedViewMutationResponse>;
  upsertGridSavedViewLoading: boolean;
  upsertGridSavedViewError?: ApolloError;
}

export const useUpsertGridSavedView = (): UpsertGridSavedViewResult => {
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [moduleName, setModuleName] = useState<string>();

  const [AddOrUpdateGridSavedView, { loading, error }] = useMutation(UPSERT_GRID_SAVED_VIEW, {
    onError: (error: ApolloError) => {
      logger('UpsertGridSavedView').error(`Error ${isCreate ? 'adding' : 'updating'} view for ${moduleName} -->`, error.message);
    }
  });

  const upsertGridSavedView = async (input: GridSavedViewInput, isCreate: boolean, moduleName: string): Promise<GridSavedViewMutationResponse> => {
    setIsCreate(isCreate);
    setModuleName(moduleName);

    const response = await AddOrUpdateGridSavedView({ 
      variables: { input },
      refetchQueries: [{
        query: GET_GRID_SAVED_VIEWS,
        variables: {
          moduleName,
        },
      }],
    });

    const responseData = response.data?.upsertGridSavedView?.view;
    if (responseData) {
      logger('UpsertGridSavedView').info(`View ${isCreate ? 'added' : 'updated'} successfully`, response.data);
      return {
        responseData,
        responseMessage: `View ${isCreate ? 'added' : 'updated'} successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to ${isCreate ? 'add' : 'update'} view!`,
      };
    }
  };

  return { 
    upsertGridSavedView, 
    upsertGridSavedViewLoading: loading, 
    upsertGridSavedViewError: error,
  };
};