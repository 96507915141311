import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery } from "@apollo/client";
import { LogEntry } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LOG_ENTRY = gql`
  query GetLogEntry($logEntryId: String!) {
    logEntry(logEntryId: $logEntryId) {
      __typename
      id
      logDate
      scheduleId
      equipmentId
      cost
      curr
      company
      time
      timeQty
      hours
      performedBy
      enteredBy
      tsachk
      department
      crewId
      restricted
      isWarranty
      locationId
      dateEntered
      isCheckList
      workList
      html
      categoryId
      sms
      checks
      year
      month
      equipment {
        __typename
        id
        countHours
        hoursOffset
        hours
        uniqueName
      }
      sparesUsedCount
      photosCount
      documentsCount
      commentsCount
    }
  }
`

export interface LogEntryData {
  logEntry: LogEntry;
};

interface LogEntryResult {
  getLazyLogEntry: LazyQueryExecFunction<LogEntryData, OperationVariables>;
}

export const useGetLazyLogEntry = (): LogEntryResult => {

  const [ getLogEntry, { error }] = useLazyQuery(GET_LOG_ENTRY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLogEntry').error('Error fetching log entry -->', error.message);
  }

  return {
    getLazyLogEntry: getLogEntry
  };
};
  