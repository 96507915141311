import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isNil } from 'lodash';
import Button from '@mui/material/Button';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Portal, Snackbar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DatePicker from 'src/components/UI/Forms/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import { getDatabase } from 'src/rxdb';
import Input from 'src/components/UI/Forms/Input';
import AddIcon from '@mui/icons-material/Add';
import { convertDateNumberToDateString, convertDateToDateNumber, isValidDateFormat } from 'src/utils/format-dates';
import { v4 as uuid } from 'uuid';
import AutocompleteInput from 'src/components/UI/Forms/AutocompleteInput';
import CreateRunningLogDialog from './CreateRunningLogDialog';
import { TblRlDataDocument } from 'src/rxdb/collections/RLData/schema';
import { TblRunningLogDocument } from 'src/rxdb/collections/RunningLog/schema';

interface InjectedProps {
  initialValue?: TblRunningLogDocument;
  visible: boolean;
  isCreate: boolean;
  onCancel?: () => void;
  onSubmit: () => void;
  onReadingSave?: (item: TblRunningLogDocument) => void;
}

const timezoneOptions = [
  { timezoneID: 1, fldTimeZone: '(UTC-12:00) International Date Line West' },
  { timezoneID: 2, fldTimeZone: '(UTC-11:00) Midway Island, Samoa' },
  { timezoneID: 3, fldTimeZone: '(UTC-10:00) Hawaii' },
  { timezoneID: 4, fldTimeZone: '(UTC-09:00) Alaska' },
  { timezoneID: 5, fldTimeZone: '(UTC-08:00) Pacific Time (US & Canada)' },
  { timezoneID: 6, fldTimeZone: '(UTC-07:00) Mountain Time (US & Canada)' },
  { timezoneID: 7, fldTimeZone: '(UTC-06:00) Central Time (US & Canada)' },
  { timezoneID: 8, fldTimeZone: '(UTC-05:00) Eastern Time (US & Canada)' },
  { timezoneID: 9, fldTimeZone: '(UTC-04:00) Atlantic Time (Canada)' },
  { timezoneID: 10, fldTimeZone: '(UTC-03:00) Buenos Aires, Georgetown' },
  { timezoneID: 11, fldTimeZone: '(UTC-02:00) Mid-Atlantic' },
  { timezoneID: 12, fldTimeZone: '(UTC-01:00) Azores, Cape Verde Is.' },
  { timezoneID: 13, fldTimeZone: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London' },
  { timezoneID: 14, fldTimeZone: '(UTC+01:00) Amsterdam, Berlin, Rome, Vienna' },
  { timezoneID: 15, fldTimeZone: '(UTC+02:00) Athens, Bucharest, Istanbul, Minsk' },
  { timezoneID: 16, fldTimeZone: '(UTC+03:00) Moscow, St. Petersburg, Volgograd' },
  { timezoneID: 17, fldTimeZone: '(UTC+04:00) Abu Dhabi, Muscat, Tbilisi' },
  { timezoneID: 18, fldTimeZone: '(UTC+05:00) Islamabad, Karachi, Tashkent' },
  { timezoneID: 19, fldTimeZone: '(UTC+06:00) Almaty, Novosibirsk' },
  { timezoneID: 20, fldTimeZone: '(UTC+07:00) Bangkok, Hanoi, Jakarta' },
  { timezoneID: 21, fldTimeZone: '(UTC+08:00) Beijing, Hong Kong, Singapore' },
  { timezoneID: 22, fldTimeZone: '(UTC+09:00) Osaka, Sapporo, Tokyo' },
  { timezoneID: 23, fldTimeZone: '(UTC+10:00) Brisbane, Guam, Port Moresby' },
  { timezoneID: 24, fldTimeZone: '(UTC+11:00) Magadan, Solomon Is., New Caledonia' },
  { timezoneID: 25, fldTimeZone: '(UTC+12:00) Auckland, Fiji, Marshall Is.' },
  { timezoneID: 26, fldTimeZone: '(UTC+13:00) Nuku\'alofa' }
];

const filedefaultValue = {
  PKey: '',
  fldYMSLogDate: new Date().toISOString(),
  fldTimeZone: 0,
  fldApproxLocation: '',
};

const getFldTimeZone = (timezoneID: number): string => {
  const timezone = timezoneOptions.find(option => option.timezoneID === timezoneID);
  return timezone ? timezone.fldTimeZone : ''; // Return fldTimeZone if found, otherwise return an empty string
};

const getTimezoneID = (fldTimeZone: string): number | null => {
  const timezone = timezoneOptions.find(option => option.fldTimeZone === fldTimeZone);
  return timezone ? timezone.timezoneID : null; // Return timezoneID if found, otherwise return null
};

const CreateRunningLogPopup: FC<InjectedProps> = ({
  initialValue,
  visible,
  isCreate,
  onCancel,
  onSubmit,
  onReadingSave,
}) => {
  const { control, handleSubmit, reset } = useForm<any>({ mode: 'onBlur', shouldFocusError: true });
  const [dayLoading, setDayLoading] = useState(false);
  const [readingLoading, setReadingLoading] = useState(false);
  const [isCreatingReading, setIsCreatingReading] = useState(false);
  const [newTblRlDataDocument, setNewTblRlDataDocument] = useState<TblRlDataDocument>();
  const [runningLog, setRunningLog] = useState<any>();
    
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const setInitialValue = () => {
    if (isNil(initialValue)) return reset(filedefaultValue);

    const payload = {
      PKey: initialValue.PKey,
      fldApproxLocation: initialValue.fldApproxLocation,
      fldTimeZone: getTimezoneID(initialValue.fldTimeZone || ''),
      fldYMSLogDate: convertDateNumberToDateString(initialValue.fldYMSLogDate),
    };

    updateFormData(payload);
  };

  useEffect(() => {
    setInitialValue();
  }, [initialValue, visible]);

  const updateFormData = (payload: any) => {
    if (!isNil(payload)) {
      reset(payload);
    }
  };
    
  const onOkClicked = async (data: any) => {
    try {
      setDayLoading(true);
      const db = await getDatabase();
      const {
        PKey,
        fldYMSLogDate,
        fldTimeZone,
        fldApproxLocation,
      } = data;

      const document = {
        PKey: PKey || uuid(),
        fldYMSLogDate: convertDateToDateNumber(new Date(fldYMSLogDate)),
        fldTimeZone: getFldTimeZone(fldTimeZone),
        fldApproxLocation,
      } as any;

      await db.tblrunninglog.upsert(document);
      onSubmit && onSubmit();
      setDayLoading(false);
      reset(filedefaultValue);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleAddReading = async (data: any) => {
    try {
      setReadingLoading(true);
      const db = await getDatabase();
      const {
        fldYMSLogDate,
        fldTimeZone,
        fldApproxLocation,
      } = data;

      const YMSDateNumber = convertDateToDateNumber(new Date(fldYMSLogDate));

      const tblRunningLogDocument = {
        PKey: uuid(),
        fldYMSLogDate: YMSDateNumber,
        fldTimeZone: getFldTimeZone(fldTimeZone),
        fldApproxLocation,
      } as any;

      const newRunningLogEntry = await db.tblrunninglog.upsert(tblRunningLogDocument);

      const tblRLDataDocument = {
        FKey: tblRunningLogDocument.PKey,
        updatedAt: new Date().toISOString(),
      } as any;

      setIsCreatingReading(true)
      setNewTblRlDataDocument(tblRLDataDocument);
      setRunningLog(newRunningLogEntry)
      onSubmit && onSubmit();
      setReadingLoading(false);
      reset(filedefaultValue);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const onCancelClick = () => {
    reset(filedefaultValue);
    onCancel && onCancel();
  };

  const handleSave = async () => {
    setIsCreatingReading(false);
    setNewTblRlDataDocument(undefined); // it closes the RunningLogCreateForm
    runningLog && onReadingSave && onReadingSave(runningLog); // it returns to the RunningLogDetailPage
    setSnackbar({
      open: true,
      message: 'New Reading has been created.',
      type: 'success',
    });
  };

  const isDuplicatedDateLog = async (fldYMSLogDate: Date, excludeId?: string): Promise<boolean> => {
    const db = await getDatabase();
    const query: any = {
      selector: {
        fldYMSLogDate: convertDateToDateNumber(new Date(fldYMSLogDate))
      }
    };
    if (excludeId) {
      query.selector.PKey = { $ne: excludeId }; // Exclude the current record's ID from the query
    }
  
    const record = await db.tblrunninglog.findOne(query).exec();
    return !!record;
  }

  return (
    <div>
        <Dialog
          open={visible}
          onClose={onCancelClick}
          fullWidth
          maxWidth="sm"
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '16px' }}>
            <span className="ml-2">
              {isCreate ? 'Add new Running Log' : 'Update Running Log'}
            </span>
            {onCancel ? (
              <IconButton
                aria-label="close"
                onClick={onCancelClick}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 14,
                  color: (theme) => theme.palette.grey[400],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>

          <DialogContent dividers>
            <div className="flex flex-row mb-5">
              <div className="w-1/2">
                <DatePicker
                  size="small"
                  name="fldYMSLogDate"
                  control={control}
                  label="Log Date"
                  rules={{
                    validate: async (value) => {
                      // Check if the date format is valid
                      if (!isValidDateFormat(value)) {
                        return 'Please enter a valid date format dd-MMM-yyyy.';
                      }
                      // Check if the running log date is duplicated
                      const isDuplicated = isCreate 
                        ? await isDuplicatedDateLog(value) 
                        : await isDuplicatedDateLog(value, initialValue?.PKey);

                      if (isDuplicated) {
                        return 'This running log date already exists.';
                      }
                  
                      // If all validations pass, return true
                      return true;
                    },
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row mb-5">
              <div className="mr-2 w-3/4">
                <AutocompleteInput
                  displayExpr="fldTimeZone"
                  freeSolo={true}
                  valueExpr="timezoneID"
                  options={timezoneOptions}
                  label="Time Zone"
                  control={control}
                  name="fldTimeZone"
                />
              </div>
            </div>

            <div className="flex flex-row mb-5">
              <div className="mr-2 w-3/4">
                <Input
                  inputProps={{
                    size: 'medium',
                    label: 'Location',
                  }}
                  control={control}
                  name="fldApproxLocation"
                />
              </div>
            </div>
          </DialogContent>

          <DialogActions sx={{ m: 0, p: 3, justifyContent: 'flex-end' }}>
            <Button onClick={onCancelClick} className="w-32">
              Cancel
            </Button>
            <LoadingButton
              onClick={handleSubmit(onOkClicked)}
              loading={dayLoading}
              className="ml-4 mr-3 w-32"
              variant="contained"
            >
              <span>Save</span>
            </LoadingButton>
            {isCreate && 
              <LoadingButton
                onClick={handleSubmit(handleAddReading)}
                loading={readingLoading}
                className="ml-4 mr-3 w-40"
                variant="contained"
                style={{ backgroundColor: 'darkOrange' }}
                startIcon={<AddIcon />}
              >
                <span>New Reading</span>
              </LoadingButton>
            }
          </DialogActions>
        </Dialog>

        <Portal>
          <Snackbar
            open={snackBar.open}
            autoHideDuration={1000}
            onClose={onSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
              {snackBar.message}
            </Alert>
          </Snackbar>
        </Portal>

      <CreateRunningLogDialog
        isCreating={isCreatingReading}
        RLData={newTblRlDataDocument}
        runningLog={runningLog}
        visible={!isNil(newTblRlDataDocument)}
        onSave={handleSave}
        onClose={() => setNewTblRlDataDocument(undefined)}
      />
    </div>
  );
};

export default CreateRunningLogPopup;
  