import { omit } from 'lodash';
import { TblDocumentCrossReference } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
    PKey
    fldFKey
    fldRecordKey
    fldTableName
    fldIsFavorite
    DateEntered
    deletedAt
    deletedBy
    isRecoverable
    updatedAt
    autoId
`;

export const tblDocumentCrossReferenceQuery = (
  doc: TblDocumentCrossReference,
) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      fldFKey: '',
      fldTableName: '',
      tblDocumentation: {
        autoId: 0,
        PKey: '',
        DocTitle: '',
        updatedAt: new Date(0).toISOString(),
      },
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('tblDocumentationCrossReference').info(
    `pull query triggered - tblDocumentationCrossReference:  { ${new Date().toISOString()} - ID: ${doc.autoId} }`,
  );
  return {
    query: `
    query GetLatestTblDocumentCrossReference ($autoId: Int, $filterDate: Date) {
      tblDocumentCrossReference(
        where:{ 
          _and: [
            {
              _or: [
                {
                  isRecoverable: {
                    _is_null: true
                  }
                },
                {
                  isRecoverable: {
                    _eq: true
                  }
                }
              ]
            },
            {
              _or: [
                {
                  updatedAt: {_gt: $filterDate},                
                },
                {
                  autoId: {_gt: $autoId}
                  updatedAt: {_gte: $filterDate},
                }
              ]
            }
          ]                                    
        },
         order_by: [
          {updatedAt: asc},
          {autoId: asc}
        ],
        limit: 1000
        )
        {        
        PKey
        fldFKey
        fldRecordKey
        fldTableName
        fldIsFavorite
        DateEntered
        deletedAt
        updatedAt
        autoId
      }
    }
  `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblDocumentCrossReferenceMutation = (doc: any) => {
  const query = `
        mutation upsertDocumentCrossReference($input: DocumentCrossReferenceInput!) {
          upsertDocumentCrossReference(input: $input) {
            ${fields}
          }
        }
    `;
  const variables = {
    input: omit(doc, ['deletedAt', 'updatedAt', 'tblDocumentation']),
  };

  return {
    query,
    variables,
  };
};

export const tblDocumentCrossReferenceSubscription = `
  subscription onTblDocumentationCrossReferenceChanged {
    tblDocumentCrossReference {
      PKey
      fldFKey
      fldRecordKey
      tblDocumentation {          
        PKey
      }
    }
  }
`;
